import React from 'react'
import PropTypes from 'prop-types'
import {
  AdminListItem,
  AdminListItemSkeleton,
  IFSkeleton,
  IFText,
} from 'Components'
import Styles from './RoleAdminList.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'

const RoleAdminList = ({ role, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.AdminListDiv}>
      <div className={Styles.Container}>
        {isLoading ? (
          <IFSkeleton variant="text" width="150px" />
        ) : (
          <>
            <IFText className={Styles.AdminListTitle}>
              {t('RoleDetails.Admins')}
            </IFText>
            <div
              className={Styles.CountDiv}
              style={{
                backgroundColor: Colors.primaryLight,
              }}
            >
              <IFText className={Styles.CountText}>{role.adminsCount}</IFText>
            </div>
          </>
        )}
      </div>
      <Scrollbars className={Styles.AdminsContainerDiv} style={{ height: 340 }}>
        <div className={Styles.AdminsDetailsDivContainer}>
          {isLoading ? (
            [...Array(5)].map((e, index) => (
              <div key={index} className={Styles.AdminDetailsDiv}>
                <AdminListItemSkeleton />
              </div>
            ))
          ) : role.adminsCount === 0 ? (
            <div className={Styles.NoAdminsContainer}>
              <IFText>{t('RoleDetails.NoAdmins')}</IFText>
            </div>
          ) : (
            role.admins.map((admin) => (
              <div key={admin.email} className={Styles.AdminDetailsDiv}>
                <AdminListItem name={admin.name} email={admin.email} />
              </div>
            ))
          )}
        </div>
      </Scrollbars>
    </div>
  )
}

RoleAdminList.propTypes = {
  role: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default RoleAdminList
