import Styles from './ChangePasswordForm.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  FormGroup,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material'
import { object, string } from 'yup'
import { IFLoadingIndicator, IFButton } from 'Components'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { styled as muiStyled } from '@mui/system'
import AuthSelectors from 'Stores/Auth/Selectors'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import RequestState from 'Enums/RequestState'

const StyledTextField = muiStyled(TextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
})

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const ChangePasswordForm = ({
  onSubmitForm,
  admin,
  ChangingPasswordRequestState,
}) => {
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState(null)
  const [countryInputFiled, setCountryInputFiled] = useState(false)
  const [selected, setSelected] = useState()
  const [showOldPassword, setShowOldPassword] = useState(false)
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
  const handleMouseDownOldPassword = (event) => {
    event.preventDefault()
  }
  const handleMouseUpOldPassword = (event) => {
    event.preventDefault()
  }
  const [showNewPassword, setShowNewPassword] = useState(false)
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault()
  }
  const handleMouseUpNewPassword = (event) => {
    event.preventDefault()
  }
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show)
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault()
  }
  const handleMouseUpConfirmPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div>
        <Formik
          validationSchema={Yup.object({
            oldPassword: Yup.string().required(t('VouchersPage.Required')),
            newPassword: Yup.string()
              .required(t('AdminProfile.Required'))
              .min(8, t('AdminProfile.MinLength')),
            confirmPassword: Yup.string()
              .required(t('AdminProfile.Required'))
              .min(8, t('AdminProfile.MinLength'))
              .oneOf(
                [Yup.ref('newPassword'), null],
                t('AdminProfile.PasswordsMustMatch'),
              ),
          })}
          initialValues={{
            ...initialValues,
          }}
          onSubmit={(values) => {
            onSubmitForm(values)
          }}
        >
          {({ values, errors }) => (
            <Form>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="oldPassword">
                  {t('AdminProfile.CurrentPassword')}
                </label>
                <Field
                  className={Styles.Input}
                  size="small"
                  name="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  as={StyledTextField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          aria-label={
                            showOldPassword
                              ? 'hide the password'
                              : 'display the password'
                          }
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownOldPassword}
                          onMouseUp={handleMouseUpOldPassword}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="oldPassword" />
                </ErrorContainer>
              </FormGroup>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="newPassword">
                  {t('AdminProfile.NewPassword')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  size="small"
                  variant="outlined"
                  id="outlined-start-adornment"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          aria-label={
                            showNewPassword
                              ? 'hide the password'
                              : 'display the password'
                          }
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          onMouseUp={handleMouseUpNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="newPassword" />
                </ErrorContainer>
              </FormGroup>
              <FormGroup className={Styles.InputWrappingDiv}>
                <label htmlFor="confirmPassword">
                  {t('AdminProfile.ConfirmNewPassword')}
                </label>
                <Field
                  as={StyledTextField}
                  className={Styles.Input}
                  name="confirmPassword"
                  size="small"
                  variant="outlined"
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          aria-label={
                            showConfirmPassword
                              ? 'hide the password'
                              : 'display the password'
                          }
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          onMouseUp={handleMouseUpConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="confirmPassword" />
                </ErrorContainer>
              </FormGroup>
              <div className={Styles.ButtonDiv}>
                <IFButton
                  className={Styles.ButtonWrappingDiv}
                  type="submit"
                  color={Colors.primary}
                  isDead={
                    !(
                      values.oldPassword.length > 0 &&
                      values.newPassword.length > 0 &&
                      values.confirmPassword.length > 0
                    ) ||
                    (errors.oldPassword && errors.oldPassword.length > 0) ||
                    (errors.newPassword && errors.newPassword.length > 0) ||
                    (errors.confirmPassword &&
                      errors.confirmPassword.length > 0)
                  }
                  isFill={true}
                  isLoading={
                    ChangingPasswordRequestState === RequestState.LOADING
                  }
                  text={t('AdminProfile.ConfirmChangePassword')}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  ChangingPasswordRequestState:
    AuthSelectors.getChangingPasswordRequestState(state),
  admin: AuthSelectors.getUser(state),
})

export default connect(mapStateToProps)(ChangePasswordForm)
