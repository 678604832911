import SideNavBar from 'Components/SideNavBar/SideNavBar'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import Styles from './DefaultRouter.module.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  AdminPage,
  UserPage,
  PushNotificationsPage,
  TransactionsPage,
  VouchersPage,
  AdminProfile,
} from 'Containers'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import InfinityEnums from 'Enums/InfinityEnums'
import { changeRsuiteColors } from 'Theme'
import { useEffect } from 'react'

const PageContainer = styled.div`
  background-color: ${Colors.UserPageBackgroundColor};
`

const DefaultRouter = ({
  editingAdminRole,
  fetchEditingAdminRole,
  fetchAdminDetails,
}) => {
  useEffect(() => {
    fetchEditingAdminRole()
    fetchAdminDetails()
  }, [])
  changeRsuiteColors(Colors.primary)
  return (
    <PageContainer className={Styles.PageContainer}>
      <SideNavBar />
      <div className={Styles.RightColumnContainer}>
        <div className={Styles.ContentContainer}>
          <Routes>
            <Route path="/login" element={<Navigate to="/" />} />
            <Route
              path="/"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_USERS,
                ) ? (
                  <Navigate to="/user" />
                ) : editingAdminRole?.includes(
                    InfinityEnums.AdminPermissions.CAN_VIEW_ADMINISTRATION,
                  ) ? (
                  <Navigate to="/admin" />
                ) : editingAdminRole?.includes(
                    InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
                  ) ? (
                  <Navigate to="/pushNotifications" />
                ) : editingAdminRole?.includes(
                    InfinityEnums.AdminPermissions.CAN_VIEW_TRANSACTIONS,
                  ) ? (
                  <Navigate to="/transactions" />
                ) : editingAdminRole?.includes(
                    InfinityEnums.AdminPermissions.CAN_VIEW_VOUCHERS,
                  ) ? (
                  <Navigate to="/vouchers" />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/admin"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_ADMINISTRATION,
                ) && <AdminPage />
              }
            />
            <Route
              path="/user/:id"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_USERS,
                ) && <UserPage />
              }
            />
            <Route
              path="/user/"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_USERS,
                ) && <UserPage />
              }
            />
            <Route
              path="/pushNotifications"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
                ) && <PushNotificationsPage />
              }
            />
            <Route
              path="/transactions"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_TRANSACTIONS,
                ) && <TransactionsPage />
              }
            />
            <Route
              path="/vouchers"
              element={
                editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_VIEW_VOUCHERS,
                ) && <VouchersPage />
              }
            />
            <Route path="/adminProfile" element={<AdminProfile />} />
          </Routes>
        </div>
      </div>
    </PageContainer>
  )
}

const mapStateToProps = (state) => ({
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(AuthActions.logout()),
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
    fetchAdminDetails: () => dispatch(AuthActions.fetchAdminDetails()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultRouter)
