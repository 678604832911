import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, AvatarGroup } from '@mui/material'
import styled from 'styled-components'
import { strToRGB } from '../../Utils/Calculations'
import { getInitials } from '../../Utils/StringFunctions'
import IFText from 'Components/IFText/IFText'
import { useTranslation } from 'react-i18next'

const StyledAvatar = styled(Avatar)`
  background-color: #${(props) => strToRGB(props.name)} !important;
`
const StyledAvatarGroup = styled(AvatarGroup)`
  .MuiAvatar-root {
    border: none !important;
  }
`
const IFAvatarList = ({ admins, avatarSize = 50, maxDisplayed = 3 }) => {
  const { t } = useTranslation()
  if (!admins || admins.length === 0) {
    return <IFText> {t('RoleList.EmptyAdmins')} </IFText>
  }
  return (
    <StyledAvatarGroup
      max={maxDisplayed}
      spacing={8}
      sx={{
        '& .MuiAvatar-root': {
          width: avatarSize,
          height: avatarSize,
          fontSize: `${avatarSize * 0.45}px`,
        },
      }}
    >
      {admins.map((admin, index) => (
        <StyledAvatar key={index} alt={admin.name} name={admin.name}>
          {getInitials(admin.name)}
        </StyledAvatar>
      ))}
    </StyledAvatarGroup>
  )
}

IFAvatarList.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.object),
  avatarSize: PropTypes.number,
  maxDisplayed: PropTypes.number,
}

export default IFAvatarList
