const getAdminList = (state) => {
  return state.main.adminState.admins
}

const getFilteredAdminList = (state) => {
  let result = []
  if (state.main.adminState.admins) {
    for (let i = 0; i < state.main.adminState.admins.length; i++) {
      if (
        state.main.adminState.admins[i].name
          .toLowerCase()
          .includes(state.main.adminState.filterText.toLowerCase())
      ) {
        result.push(state.main.adminState.admins[i])
      }
    }
  }
  return result
}
const getAdminsFilters = (state) => {
  return state.main.adminState.adminsFilters
}

const getFilterText = (state) => {
  return state.main.adminState.filterText
}

const getfetchAdminListRequestState = (state) => {
  return state.main.adminState.fetchAdminListRequestState
}

const getupdateAdminRoleRequestState = (state) => {
  return state.main.adminState.updateAdminRoleRequestState
}

const getIsAddAdminLoading = (state) => {
  return state.main.adminState.isAddAdminLoading
}
const getAdminListPaginationOffset = (state) => {
  return state.main.adminState.paginationOffset
}
const getDeleteAdminRequestState = (state) => {
  return state.main.adminState.deleteAdminRequestState
}
const getAdminsCount = (state) => {
  return state.main.adminState.count
}
const getfetchAdminsCountRequestState = (state) => {
  return state.main.adminState.fetchAdminsCountRequestState
}

const AdminSelectors = {
  getFilterText,
  getAdminList,
  getAdminsFilters,
  getFilteredAdminList,
  getfetchAdminListRequestState,
  getIsAddAdminLoading,
  getupdateAdminRoleRequestState,
  getAdminListPaginationOffset,
  getDeleteAdminRequestState,
  getfetchAdminsCountRequestState,
  getAdminsCount,
}

export default AdminSelectors
