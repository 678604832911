import { React, Fragment } from 'react'
import styles from './IFButton.module.css'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SpinnerCircularFixed } from 'spinners-react'
import PropTypes from 'prop-types'
import IFText from 'Components/IFText/IFText'
import { Colors } from 'Theme'
import IFButtonSkeleton from './IFButtonSkeleton'

const AliveButton = styled.button`
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:hover {
    background-color: ${(props) =>
      props.isFill || props.isLoading
        ? `${props.color}${Colors.transparencyOnHoverFill}`
        : `${props.color}${Colors.transparencyOnHoverNotFilled}`} !important;
  }

  &:before {
    background: ${(props) => props.color};
  }

  color: ${(props) =>
    props.isFill || props.isLoading ? Colors.whitesmoke : props.color};
  border: ${(props) =>
    props.isFill || props.isLoading ? '0px' : '1.5px solid ' + props.color};
  background: ${(props) =>
    props.isFill || props.isLoading ? props.color : ''};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background: ${(props) =>
      +props.isFill || props.isLoading
        ? `${props.color}${Colors.transparencyOnHoverFill}`
        : `${props.color}${Colors.transparencyOnHoverNotFilled}`};
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    z-index: -1;
    transition: transform 0.5s, opacity 1s;
  }

  &:active::after {
    transform: translate(-50%, -50%) scale(10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
`

const DeadButton = styled.button``

const IFButton = ({
  color,
  isFill,
  isDead,
  isLoading,
  icon,
  text,
  onClick,
  className,
  loadSkeleton,
  size = 'md',
}) => {
  const Button = isDead ? DeadButton : AliveButton

  return (
    <div className={className}>
      {loadSkeleton ? (
        <IFButtonSkeleton size={size} />
      ) : (
        <Button
          color={color}
          isFill={isFill}
          size={size}
          isLoading={isLoading}
          onClick={isLoading ? () => {} : onClick}
          disabled={isDead ? true : false}
          style={{
            ...(isDead && {
              background: Colors.isDeadColor,
              border: `2px solid ${Colors.isDeadColor}`,
              color: Colors.gray,
            }),
            ...(size === 'xsm' && {
              height: '24px',
              fontSize: '12px',
              padding: '5px 9px 5px 9px',
            }),
            ...(size === 'sm' && {
              height: '32px',
              fontSize: '14px',
              padding: '5px 11px 5px 11px',
            }),
            ...(size === 'md' && {
              height: '40px',
              fontSize: '14px',
              padding: '5px 13px 5px 13px',
            }),
            ...(size !== 'xsm' &&
              size !== 'sm' &&
              size !== 'md' && {
                height: '48px',
                fontSize: '16px',
                padding: '5px 17px 5px 17px',
              }),
          }}
          className={
            isDead
              ? styles.disabled
              : isFill && !isLoading
              ? styles.filled
              : isLoading
              ? null
              : styles.fill
          }
        >
          {isLoading ? (
            <SpinnerCircularFixed
              color={Colors.white}
              secondaryColor={Colors.SpinnerSecondaryColor}
              thickness={200}
              size={25}
            />
          ) : icon ? (
            <Fragment>
              <IFText>
                <FontAwesomeIcon className={styles.FontIcon} icon={icon} />
                {text}
              </IFText>
            </Fragment>
          ) : (
            <IFText>{text}</IFText>
          )}
        </Button>
      )}
    </div>
  )
}

IFButton.propTypes = {
  color: PropTypes.string.isRequired,
  isFill: PropTypes.bool,
  isDead: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
}

export default IFButton
