import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Styles from './RolePermissionList.module.css'
import { IFText, RolesPermissionsListItem } from 'Components'
import { useTranslation } from 'react-i18next'
import InfinityEnums from '../../Enums/InfinityEnums'
import { formatPermissionName } from 'Utils/StringFunctions'
import { Scrollbars } from 'react-custom-scrollbars'

const RolePermissionList = ({
  role,
  isEditing,
  onPermissionsChange,
  resetPermissions,
}) => {
  const { t } = useTranslation()
  const [selectedPermissions, setSelectedPermissions] = useState([])

  useEffect(() => {
    const permissions = Object.entries(InfinityEnums.AdminPermissions).map(
      ([_, value]) => value,
    )
    const initialSelected = role
      ? permissions.filter((permission) =>
          role.permissions.includes(permission),
        )
      : []
    setSelectedPermissions(initialSelected)
  }, [role])

  useEffect(() => {
    onPermissionsChange(selectedPermissions)
  }, [selectedPermissions, onPermissionsChange])

  const togglePermission = (value) => {
    setSelectedPermissions((prev) => {
      const newPermissions = prev.includes(value)
        ? prev.filter((perm) => perm !== value)
        : [...prev, value]
      return newPermissions
    })
  }
  useEffect(() => {
    if (resetPermissions && role) {
      const initialSelected = Object.entries(InfinityEnums.AdminPermissions)
        .filter(([_, value]) => role.permissions.includes(value))
        .map(([_, value]) => value)
      setSelectedPermissions(initialSelected)
    }
  }, [resetPermissions, role])

  return (
    <div>
      <IFText className={Styles.PermissionTitle}>
        {t('RoleDetails.Permissions')}
      </IFText>
      <Scrollbars
        className={Styles.PermissionContainerDiv}
        style={{ height: 462 }}
      >
        <div className={Styles.PermissionContainerDiv}>
          {Object.entries(InfinityEnums.AdminPermissions).map(
            ([permissionName, permissionValue]) => {
              const isChecked = selectedPermissions.includes(permissionValue)
              return (
                <RolesPermissionsListItem
                  key={permissionValue}
                  title={formatPermissionName(permissionName)}
                  isSelected={isChecked}
                  isEditing={isEditing}
                  onToggle={() => togglePermission(permissionValue)}
                />
              )
            },
          )}
        </div>
      </Scrollbars>
    </div>
  )
}

RolePermissionList.propTypes = {
  role: PropTypes.object,
  isEditing: PropTypes.bool,
  onPermissionsChange: PropTypes.func,
  resetPermissions: PropTypes.bool,
}

export default RolePermissionList
