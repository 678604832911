import React from 'react'
import styled from 'styled-components'
import styles from './UserListItem.module.css'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'
import { IFText } from 'Components'
import PropTypes from 'prop-types'
import Colors from 'Theme/Colors'

TimeAgo.addDefaultLocale(en)

const Container = styled.div`
  background-color: ${(props) =>
    props.isSelected
      ? Colors.UserListItemSelectedBackground
      : Colors.UserListItemNotSelectedBackground};
  &:hover {
    background-color: ${Colors.UserListItemHoverBackground};
  }
`

const UserListItem = ({ avatar, isSelected, user, onClick }) => {
  return (
    <Container
      user={user}
      isSelected={isSelected}
      onClick={onClick}
      className={styles.Container}
    >
      <div className={styles.Avatar}>
        {avatar}
        <div
          className={styles.StatusContainer}
          style={{
            backgroundColor: user?.isActive
              ? Colors.UserActive
              : Colors.UserBlocked,

            borderColor: Colors.white,
          }}
        />
      </div>

      <div className={styles.UserDetailsContainer}>
        <IFText className={styles.UserNameText}>{user.name}</IFText>
        <IFText style={{ color: Colors.gray }} className={styles.Phonetext}>
          {user.phone}
        </IFText>
      </div>
      <div className={styles.LastSeenItem}>
        <IFText style={{ color: Colors.gray }} className={styles.LastSeenText}>
          <ReactTimeAgo
            date={Date.parse(user.createdAt)}
            locale="en-US"
            timeStyle="mini"
          />
        </IFText>
      </div>
    </Container>
  )
}

UserListItem.propTypes = {
  avatar: PropTypes.object,
  isSelected: PropTypes.bool,
  user: PropTypes.object,
  onClick: PropTypes.func,
}

export default UserListItem
