import React, { useState } from 'react'
import Styles from './CreateRoleModal.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import IFButton from 'Components/IFButton/IFButton'
import {
  TextField,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import AuthSelectors from 'Stores/Auth/Selectors'
import { connect } from 'react-redux'
import RolePermissionList from 'Containers/RolePermissionList/RolePermissionList'
import { IFText } from 'Components'
import RequestState from 'Enums/RequestState'
import RoleSelectors from 'Stores/Role/Selectors'
import PropTypes from 'prop-types'
const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
  width: '230px',
  '& .MuiInputBase-input': {
    height: '30px',
    padding: '10px',
  },
})
const StyledSelect = styled(Select)`
  && {
    font-family: 'ProximaNova';
    font-size: 14px;
    color: ${Colors.text};
    height: 50px;
    width: 230px;
  }
`
const CreateRoleModal = ({
  onSubmitForm,
  loggedInAdmin,
  createRoleRequestState,
}) => {
  const { t } = useTranslation()
  const [roleName, setRoleName] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value)
  }
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value)
  }
  const handlePermissionsChange = (newPermissions) => {
    setSelectedPermissions(newPermissions)
  }
  const handleSubmit = () => {
    onSubmitForm({
      roleName,
      country: selectedCountry,
      permissions: selectedPermissions,
    })
  }
  const isButtonDisabled =
    !roleName ||
    (loggedInAdmin.registeredCountries.length > 1 && !selectedCountry) ||
    selectedPermissions.length === 0
  return (
    <div className={Styles.CreateRoleContainer}>
      <div className={Styles.FieldsDiv}>
        <FormGroup className={Styles.InputWrappingDiv}>
          <IFText className={Styles.Labels}>{t('RoleDetails.Name')}</IFText>
          <StyledTextField value={roleName} onChange={handleRoleNameChange} />
        </FormGroup>
        {loggedInAdmin.registeredCountries &&
          loggedInAdmin.registeredCountries.length > 1 && (
            <FormGroup className={Styles.InputWrappingDivCountry}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: Colors.primary,
                    },
                    '& fieldset': {
                      borderColor: Colors.grey,
                      borderWidth: 1,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: Colors.primary,
                    },
                  },
                }}
              >
                <IFText className={Styles.Labels}>
                  {t('RoleDetails.Country')}
                </IFText>
                <StyledSelect
                  labelId="role-select-label"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  {loggedInAdmin.registeredCountries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </FormGroup>
          )}
      </div>
      <div className={Styles.CreateRolePermission}>
        <RolePermissionList
          isLoading={false}
          isEditing={true}
          onPermissionsChange={handlePermissionsChange}
        />
      </div>
      <div>
        <IFButton
          size="sm"
          color={Colors.primary}
          isFill={true}
          isLoading={createRoleRequestState === RequestState.LOADING}
          text={t('RoleDetails.Save')}
          onClick={handleSubmit}
          className={Styles.SaveButton}
          isDead={isButtonDisabled}
        />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  loggedInAdmin: AuthSelectors.getUser(state),
  createRoleRequestState: RoleSelectors.getCreateRoleRequestState(state),
})
CreateRoleModal.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  loggedInAdmin: PropTypes.object,
  createRoleRequestState: PropTypes.number,
}
export default connect(mapStateToProps)(CreateRoleModal)
