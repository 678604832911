import moment from 'moment'

export const splitCamelCase = (string) => {
  let newString = string.replace(/([a-z])([A-Z])/g, '$1 $2')
  return newString[0].toUpperCase() + newString.substring(1)
}

export var getInitials = function (name) {
  var names = name.split(' ')
  var initials = names[0].substring(0, 1).toUpperCase()
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}
export const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format('MMM DD, YYYY HH:mm')
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const formatPermissionName = (permissionName) => {
  return permissionName
    .split('_')
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(' ')
}
