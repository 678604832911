import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  fetchAdminListRequestState: RequestState.UNINITIALIZED,
  fetchAdminsCountRequestState: RequestState.UNINITIALIZED,
  updateAdminRoleRequestState: RequestState.UNINITIALIZED,
  deleteAdminRequestState: RequestState.UNINITIALIZED,
  filterText: '',
  paginationOffset: 0,
  admins: [],
  roles: [],
  isAddAdminLoading: false,
  count: 0,
  adminsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Role',
      data: [],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
}
