import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import { IFButton } from 'Components'
import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import Styles from './IFDialog.module.css'

const IFDialog = React.forwardRef(
  (
    {
      style,
      open,
      title,
      bodyText,
      buttonAcceptonClick,
      buttonCancelColor = Colors.primary,
      buttonAcceptColor,
      buttonCancelText,
      buttonAcceptText,
      buttonDeadCondition,
      buttonClassName,
      contentClassName,
      children,
      fullWidth,
      maxWidth,
      dismissOnAccept = true,
      isLoading = false,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    if (buttonCancelText == null)
      buttonCancelText = t('DialogModal.defaultCancelButtonText')

    const [isOpen, setIsOpen] = useState(open)

    const show = () => {
      setIsOpen(true)
    }

    const dismiss = () => {
      setIsOpen(false)
    }

    useImperativeHandle(ref, () => ({
      show,
      dismiss,
    }))

    return (
      <div>
        <Dialog
          sx={{
            '& .MuiPaper-root': {
              overflowY: 'unset',
              borderRadius: '5px',
            },
          }}
          style={style}
          open={isOpen}
          onClose={dismiss}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <div
            style={{ backgroundColor: Colors.UserListTopBarBackground }}
            className={Styles.Title}
          >
            <DialogTitle className={Styles.TitleText}>{title}</DialogTitle>
          </div>
          <DialogContent
            className={contentClassName}
            sx={{ padding: '20px 16px' }}
          >
            <DialogContentText>{bodyText}</DialogContentText>
            {children}
          </DialogContent>
          <DialogActions
            className={Styles.Buttons}
            sx={{ border: `1px solid ${Colors.UserPageBackgroundContainer}` }}
          >
            <IFButton
              onClick={(e) => {
                e.stopPropagation()
                dismiss()
              }}
              color={buttonCancelColor}
              text={buttonCancelText}
              className={buttonClassName}
              isFill={false}
            />

            <IFButton
              onClick={(e) => {
                e.stopPropagation()
                buttonAcceptonClick()
                if (dismissOnAccept) {
                  dismiss()
                }
              }}
              color={buttonAcceptColor}
              isFill={true}
              autoFocus
              isDead={buttonDeadCondition}
              text={buttonAcceptText}
              className={buttonClassName}
              isLoading={isLoading}
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  },
)

IFDialog.propTypes = {
  style: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  buttonAcceptonClick: PropTypes.func,
  buttonCancelColor: PropTypes.string,
  buttonAcceptColor: PropTypes.string,
  buttonCancelText: PropTypes.string,
  buttonAcceptText: PropTypes.string,
  buttonClassName: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default IFDialog
