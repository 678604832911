import { INITIAL_STATE } from './initialState'
import { RoleTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const fetchRolesCountLoading = (state) => {
  return {
    ...state,
    fetchRolesCountRequestState: RequestState.LOADING,
  }
}

export const fetchRolesCountSuccess = (state, { count }) => {
  return {
    ...state,
    fetchRolesCountRequestState: RequestState.SUCCEEDED,
    count: count,
  }
}

export const updateRoleLoading = (state) => {
  return {
    ...state,
    updateRoleRequestState: RequestState.LOADING,
  }
}

export const updateRoleSuccess = (state, { role }) => {
  const updatedRoles = state.roles.map((oldRole) => {
    const isMatch = oldRole.id === role.id

    return isMatch
      ? {
          ...oldRole,
          name: role.name,
          permissions: role.permissions,
          updatedAt: role.updatedAt,
        }
      : oldRole
  })

  return {
    ...state,
    roles: updatedRoles,
    updateRoleRequestState: RequestState.SUCCEEDED,
  }
}

export const updateRoleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateRoleRequestState: requestState,
  }
}

export const fetchRolesCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchRolesCountRequestState: requestState,
  }
}

export const fetchRolesLoading = (state) => {
  return {
    ...state,
    fetchRolesRequestState: RequestState.LOADING,
  }
}

export const fetchRolesSuccess = (state, { roles }) => {
  return {
    ...state,
    fetchRolesRequestState: RequestState.SUCCEEDED,
    roles: roles,
  }
}

export const fetchRolesError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchRolesRequestState: requestState,
  }
}
export const createRoleLoading = (state) => {
  return {
    ...state,
    createRoleRequestState: RequestState.LOADING,
  }
}

export const createRoleSuccess = (state, { role }) => {
  return {
    ...state,
    roles: [...state.roles, role],
    createRoleRequestState: RequestState.SUCCEEDED,
  }
}
export const createRoleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createRoleRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [RoleTypes.FETCH_ROLES_COUNT_LOADING]: fetchRolesCountLoading,
  [RoleTypes.FETCH_ROLES_COUNT_SUCCESS]: fetchRolesCountSuccess,
  [RoleTypes.FETCH_ROLES_COUNT_FAIL]: fetchRolesCountFail,
  [RoleTypes.UPDATE_ROLE_LOADING]: updateRoleLoading,
  [RoleTypes.UPDATE_ROLE_SUCCESS]: updateRoleSuccess,
  [RoleTypes.UPDATE_ROLE_FAIL]: updateRoleFail,
  [RoleTypes.FETCH_ROLES_LOADING]: fetchRolesLoading,
  [RoleTypes.FETCH_ROLES_SUCCESS]: fetchRolesSuccess,
  [RoleTypes.FETCH_ROLES_ERROR]: fetchRolesError,
  [RoleTypes.CREATE_ROLE_LOADING]: createRoleLoading,
  [RoleTypes.CREATE_ROLE_SUCCESS]: createRoleSuccess,
  [RoleTypes.CREATE_ROLE_FAIL]: createRoleFail,
})
