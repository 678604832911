import React from 'react'
import styles from './UserListHeader.module.css'
import Colors from 'Theme/Colors'
import { IFText } from 'Components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'

const UserListHeader = ({
  toggleSortingOrder,
  isUserSortAsc,
  usersCount,
  fetchUserListCountRequestState,
  fetchUserListRequestState,
}) => {
  const { t } = useTranslation()
  return (
    <div
      style={{ backgroundColor: Colors.UserListTopBarBackground }}
      className={styles.Container}
    >
      <div className={styles.NumberOfUsersText}>
        <IFText
          style={{ color: Colors.UserListHeaderText }}
          loadSkeleton={
            fetchUserListCountRequestState === RequestState.LOADING ||
            fetchUserListRequestState === RequestState.LOADING
          }
          skeletonWidth="8rem"
        >
          {usersCount} {t('UserListHeader.users')}
        </IFText>
      </div>
      <div
        onClick={() => {
          toggleSortingOrder(!isUserSortAsc)
        }}
      ></div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSortingOrder: (isAscending) =>
      dispatch(UserActions.setUserSortingAsc(isAscending)),
  }
}

const mapStateToProps = (state) => ({
  usersCount: UserSelectors.getUsersCount(state),
  fetchUserListCountRequestState:
    UserSelectors.getFetchUserListCountRequestState(state),
  isUserSortAsc: UserSelectors.getUserSortingAsc(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
})

UserListHeader.propTypes = {
  isUserSortAsc: PropTypes.bool,
  toggleSortingOrder: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(UserListHeader)
