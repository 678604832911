import RequestState from 'Enums/RequestState'
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  fetchRolesCountRequestState: RequestState.UNINITIALIZED,
  updateRoleRequestState: RequestState.UNINITIALIZED,
  fetchRolesRequestState: RequestState.UNINITIALIZED,
  createRoleRequestState: RequestState.UNINITIALIZED,
  count: 0,
  roles: [],
}
