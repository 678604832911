import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setFilterText: ['text'],
  clearAdminsFilter: null,
  setAdminsFilter: ['newFilter'],
  updateAdminsFilterWithRoles: ['roles'],

  fetchAdminList: ['filter', 'offset', 'shouldShowError'],
  fetchAdminListLoading: null,
  fetchAdminListSuccess: ['offset', 'admins', 'nextOffset'],
  fetchAdminListError: ['error'],

  updateAdminRole: ['adminId', 'newRoleValue'],
  updateAdminRoleLoading: ['adminId'],
  updateAdminRoleSuccess: ['admin'],
  updateAdminRoleFail: ['adminId'],

  deleteAdmin: ['adminId'],
  deleteAdminLoading: ['adminId'],
  deleteAdminSuccess: ['adminId'],
  deleteAdminFail: ['adminId'],

  addAdmin: ['admin'],
  addAdminLoading: ['value'],
  addAdminSuccess: ['admin'],
  addAdminFail: null,

  fetchAdminsCount: ['filter'],
  fetchAdminsCountLoading: null,
  fetchAdminsCountSuccess: ['count'],
  fetchAdminsCountFail: ['error'],
})

export const AdminTypes = Types
export default Creators
