import Styles from './PushNotificationsPage.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import {
  IFButton,
  IFText,
  DevicePreview,
  IFLoadingLogo,
  IFToastMessage,
  IFSkeleton,
} from 'Components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useState, useEffect } from 'react'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import RequestState from 'Enums/RequestState'
import { toaster } from 'rsuite'
import { splitCamelCase } from 'Utils/StringFunctions'
import PropTypes from 'prop-types'
import { styled as muiStyled } from '@mui/system'
import LoadingBar from 'react-top-loading-bar'

const MiddlePanel = styled.div`
  background-color: ${Colors.white};
`

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.primary,
  },
}))
const CssTextField = muiStyled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    height: '2rem',
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

const CssTextFieldTall = muiStyled(TextField)(({ theme }) => ({
  textAlign: 'left',
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    height: '5rem',
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

const PushNotificationsPage = ({
  notificationGroups,
  fetchNotificationGroups,
  fetchNotificationGroupsRequestState,
  sendNotification,
  sendNotificationRequestState,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchNotificationGroups()
  }, [])

  useEffect(() => {
    notificationGroups
  }, [notificationGroups])

  useEffect(() => {
    if (sendNotificationRequestState === RequestState.SUCCEEDED) {
      setTimeout(
        () =>
          toaster.push(
            <IFToastMessage
              type="success"
              text={t('PushNotificationsPanel.SuccessMessage')}
            />,
          ),
        0,
      )
      setEnglishTitle('')
      setEnglishBody('')
      setArabicTitle('')
      setArabicBody('')
      setGroup('')
      setRedirectInput('')
    }
  }, [sendNotificationRequestState])

  const [englishTitle, setEnglishTitle] = useState('')
  const [englishTitleError, setEnglishTitleError] = useState('')
  const [englishBody, setEnglishBody] = useState('')
  const [englishBodyError, setEnglishBodyError] = useState('')
  const [arabicTitle, setArabicTitle] = useState('')
  const [arabicTitleError, setArabicTitleError] = useState('')
  const [arabicBody, setArabicBody] = useState('')
  const [arabicBodyError, setArabicBodyError] = useState('')
  const [group, setGroup] = useState('')
  const [groupError, setGroupError] = useState('')
  const [redirectInput, setRedirectInput] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const [progress, setProgress] = useState(0)

  function isUrlValid(string) {
    try {
      new URL(string)
      return true
    } catch (err) {
      return false
    }
  }

  const sendNotificationHandler = () => {
    const notification = {
      notification: {
        title: {
          en: englishTitle,
          ar: arabicTitle,
        },
        message: {
          en: englishBody,
          ar: arabicBody,
        },
      },
      group: group,
      ...(redirectInput
        ? isUrlValid(redirectInput)
          ? { redirectUrl: redirectInput }
          : { stationId: redirectInput }
        : {}),
    }
    sendNotification(notification)
  }
  useEffect(() => {
    if (
      fetchNotificationGroupsRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)

    if (fetchNotificationGroupsRequestState === RequestState.SUCCEEDED)
      setProgress(100)
    if (
      fetchNotificationGroupsRequestState !== RequestState.LOADING &&
      fetchNotificationGroupsRequestState !== RequestState.UNINITIALIZED &&
      fetchNotificationGroupsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchNotificationGroupsRequestState])

  return (
    <div className={Styles.Wrapper}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <MiddlePanel
        className={Styles.MiddlePanel}
        style={{
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.PanelTitleDiv}>
          <IFText
            className={Styles.PanelTitle}
            style={{ color: Colors.text }}
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'12rem'}
            skeletonHeight={'2rem'}
          >
            {t('PushNotificationsPanel.PanelTitle')}
          </IFText>
        </div>

        <div className={Styles.BottomPanel}>
          <div className={Styles.Inputs}>
            <IFText
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
              className={Styles.InputTitle}
            >
              {t('PushNotificationsPanel.EnglishTitle')}
            </IFText>
            {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'30px'} />
              </div>
            ) : (
              <CssTextField
                variant="outlined"
                value={englishTitle}
                inputProps={{
                  maxLength: 65,
                  style: { fontSize: '14px', fontFamily: 'ProximaNova' },
                }}
                onChange={(e) => {
                  setEnglishTitle(e.target.value)

                  e.target.value.length === 0
                    ? setEnglishTitleError(t('PushNotificationsPanel.Required'))
                    : setEnglishTitleError('')

                  const submit =
                    e.target.value.length > 0 &&
                    englishBody.length > 0 &&
                    arabicTitle.length > 0 &&
                    arabicBody.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                onBlur={(e) => {
                  e.target.value.length === 0
                    ? setEnglishTitleError(t('PushNotificationsPanel.Required'))
                    : setEnglishTitleError('')
                  const submit =
                    e.target.value.length > 0 &&
                    englishBody.length > 0 &&
                    arabicTitle.length > 0 &&
                    arabicBody.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
              />
            )}
            <div className={Styles.ErrorContainer}>
              {englishTitleError.length > 0 ? (
                <IFText style={{ color: Colors.red }}>
                  {englishTitleError}
                </IFText>
              ) : null}
            </div>

            <IFText
              className={Styles.InputTitle}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.EnglishBody')}
            </IFText>
            {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'5rem'} />
              </div>
            ) : (
              <CssTextFieldTall
                variant="outlined"
                multiline
                rows={3}
                value={englishBody}
                onChange={(e) => {
                  setEnglishBody(e.target.value)

                  e.target.value.length === 0
                    ? setEnglishBodyError(t('PushNotificationsPanel.Required'))
                    : setEnglishBodyError('')

                  const submit =
                    e.target.value.length > 0 &&
                    englishTitle.length > 0 &&
                    arabicTitle.length > 0 &&
                    arabicBody.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                onBlur={(e) => {
                  e.target.value.length === 0
                    ? setEnglishBodyError(t('PushNotificationsPanel.Required'))
                    : setEnglishBodyError('')
                  const submit =
                    e.target.value.length > 0 &&
                    englishTitle.length > 0 &&
                    arabicTitle.length > 0 &&
                    arabicBody.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                inputProps={{
                  maxLength: 178,
                  style: { fontSize: '14px', fontFamily: 'ProximaNova' },
                }}
              />
            )}
            <div className={Styles.ErrorContainer}>
              {englishBodyError.length > 0 ? (
                <IFText style={{ color: Colors.red }}>
                  {englishBodyError}
                </IFText>
              ) : null}
            </div>

            <IFText
              className={Styles.InputTitle}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.ArabicTitle')}
            </IFText>
            {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'30px'} />
              </div>
            ) : (
              <CssTextField
                variant="outlined"
                value={arabicTitle}
                onChange={(e) => {
                  setArabicTitle(e.target.value)

                  e.target.value.length === 0
                    ? setArabicTitleError(t('PushNotificationsPanel.Required'))
                    : setArabicTitleError('')

                  const submit =
                    e.target.value.length > 0 &&
                    englishTitle.length > 0 &&
                    englishBody.length > 0 &&
                    arabicBody.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                onBlur={(e) => {
                  e.target.value.length === 0
                    ? setArabicTitleError(t('PushNotificationsPanel.Required'))
                    : setArabicTitleError('')
                  const submit =
                    e.target.value.length > 0 &&
                    englishTitle.length > 0 &&
                    englishBody.length > 0 &&
                    arabicBody.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                inputProps={{
                  maxLength: 65,
                  style: { fontSize: '14px', fontFamily: 'ProximaNova' },
                }}
              />
            )}
            <div className={Styles.ErrorContainer}>
              {arabicTitleError.length > 0 ? (
                <IFText style={{ color: Colors.red }}>
                  {arabicTitleError}
                </IFText>
              ) : null}
            </div>

            <IFText
              className={Styles.InputTitle}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.ArabicBody')}
            </IFText>
            {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'5rem'} />
              </div>
            ) : (
              <CssTextFieldTall
                variant="outlined"
                multiline
                rows={3}
                value={arabicBody}
                onChange={(e) => {
                  setArabicBody(e.target.value)
                  e.target.value.length === 0
                    ? setArabicBodyError(t('PushNotificationsPanel.Required'))
                    : setArabicBodyError('')

                  const submit =
                    e.target.value.length > 0 &&
                    englishTitle.length > 0 &&
                    englishBody.length > 0 &&
                    arabicTitle.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                onBlur={(e) => {
                  e.target.value.length === 0
                    ? setArabicBodyError(t('PushNotificationsPanel.Required'))
                    : setArabicBodyError('')
                  const submit =
                    e.target.value.length > 0 &&
                    englishTitle.length > 0 &&
                    englishBody.length > 0 &&
                    arabicTitle.length > 0 &&
                    group.length > 0
                  setCanSubmit(submit)
                }}
                inputProps={{
                  maxLength: 178,
                  style: { fontSize: '14px', fontFamily: 'ProximaNova' },
                }}
              />
            )}
            <div className={Styles.ErrorContainer}>
              {arabicBodyError.length > 0 ? (
                <IFText style={{ color: Colors.red }}>{arabicBodyError}</IFText>
              ) : null}
            </div>

            <IFText
              className={Styles.InputTitle}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.Group')}
            </IFText>
            {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'3.5rem'} />
              </div>
            ) : (
              <Box>
                <FormControl
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: Colors.primary,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: Colors.primary,
                      },
                    },
                  }}
                >
                  <Select
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={group}
                    onChange={(e) => {
                      setGroup(e.target.value)
                      e.target.value.length === 0
                        ? setGroupError(t('PushNotificationsPanel.Required'))
                        : setGroupError('')

                      const submit =
                        e.target.value.length > 0 &&
                        englishTitle.length > 0 &&
                        englishBody.length > 0 &&
                        arabicTitle.length > 0 &&
                        arabicBody.length > 0
                      setCanSubmit(submit)
                    }}
                    onBlur={(e) => {
                      e.target.value.length === 0
                        ? setGroupError(t('PushNotificationsPanel.Required'))
                        : setGroupError('')
                      const submit =
                        e.target.value.length > 0 &&
                        englishTitle.length > 0 &&
                        englishBody.length > 0 &&
                        arabicTitle.length > 0 &&
                        arabicBody.length > 0
                      setCanSubmit(submit)
                    }}
                    input={
                      <StyledOutlinedInput
                        name="group"
                        id="outlined-group-simple"
                      />
                    }
                  >
                    {notificationGroups.map((notifGroup) => (
                      <MenuItem value={notifGroup.name}>
                        <IFText className={Styles.MenuItemText}>
                          {splitCamelCase(notifGroup.name)}
                        </IFText>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <div className={Styles.ErrorContainer}>
              {groupError.length > 0 ? (
                <IFText style={{ color: Colors.red }}>{groupError}</IFText>
              ) : null}
            </div>

            <IFText
              className={Styles.InputTitle}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.StationId')}
            </IFText>
            {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'30px'} />
              </div>
            ) : (
              <CssTextField
                variant="outlined"
                value={redirectInput}
                onChange={(e) => setRedirectInput(e.target.value)}
                inputProps={{
                  style: { fontSize: '14px', fontFamily: 'ProximaNova' },
                }}
              />
            )}
          </div>
          <div className={Styles.DevicePreviewWrapper}>
            <div className={Styles.DevicePreviewTitle}>
              <IFText
                style={{ color: Colors.text }}
                className={Styles.DevicePreviewText}
                loadSkeleton={
                  fetchNotificationGroupsRequestState === RequestState.LOADING
                }
                skeletonWidth={'7rem'}
              >
                {t('PushNotificationsPanel.DevicePreviewTitle')}
              </IFText>
            </div>
            <div className={Styles.DevicePreview}>
              <DevicePreview
                title={englishTitle}
                subtitle={englishBody}
                locale="en"
              />
              <div className={Styles.ArabicDevicePreview}>
                <DevicePreview
                  title={arabicTitle}
                  subtitle={arabicBody}
                  locale="ar"
                />
              </div>
            </div>
            <div className={Styles.ButtonWrapper}>
              <IFButton
                loadSkeleton={
                  fetchNotificationGroupsRequestState === RequestState.LOADING
                }
                text={<IFText>{t('PushNotificationsPanel.sendButton')}</IFText>}
                isDead={
                  !canSubmit ||
                  sendNotificationRequestState === RequestState.LOADING
                }
                color={Colors.primary}
                isFill={true}
                className={Styles.SendNotificationButton}
                onClick={sendNotificationHandler}
                isLoading={
                  sendNotificationRequestState === RequestState.LOADING
                }
              />
            </div>
          </div>
        </div>
      </MiddlePanel>
    </div>
  )
}

PushNotificationsPage.propTypes = {
  notificationGroups: PropTypes.arrayOf(PropTypes.object),
  fetchNotificationGroups: PropTypes.func,
  fetchNotificationGroupsRequestState: PropTypes.number,
  sendNotification: PropTypes.func,
  sendNotificationRequestState: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNotificationGroups: () =>
      dispatch(NotificationActions.fetchNotificationGroups()),
    sendNotification: (notification) =>
      dispatch(NotificationActions.sendNotification(notification)),
  }
}

const mapStateToProps = (state) => ({
  notificationGroups: NotificationSelectors.getNotificationGroups(state),
  fetchNotificationGroupsRequestState:
    NotificationSelectors.getFetchNotificationGroupRequestState(state),
  sendNotificationRequestState:
    NotificationSelectors.getSendNotificationRequestState(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotificationsPage)
