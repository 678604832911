import { IFButton, IFText } from 'Components'
import TextField from '@mui/material/TextField'
import Styles from './AddCardForm.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { styled as muiStyled } from '@mui/system'

const StyledTextField = muiStyled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.primary,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

const AddCardForm = ({ onSubmit, error }) => {
  const { t } = useTranslation()
  const [serialNumber, setSerialNumber] = React.useState('')

  return (
    <div className={Styles.AddCardForm}>
      <IFText style={{ color: Colors.gray }} className={Styles.TextField}>
        {t('UserPage.AddCardText')}
      </IFText>
      <StyledTextField
        sx={{
          '& .MuiInputBase-input': {
            fontFamily: 'ProximaNova',
            fontSize: '14px',
          },
        }}
        label={t('UserPage.AddCardTextFieldLabel')}
        id="standard-multiline-static"
        multiline
        rows={4}
        variant="outlined"
        onChange={(event) => setSerialNumber(event.target.value)}
        InputLabelProps={{
          style: {
            color: Colors.primary,
            fontFamily: 'ProximaNova',
          },
        }}
      />
      {error && (
        <IFText style={{ color: Colors.red }} className={Styles.ErrorMessage}>
          {t('UserPage.AddCardErrorMessage')} {error}
        </IFText>
      )}
      <div className={Styles.InputContainer}>
        <IFButton
          className={Styles.AddCardButton}
          size="md"
          isFill={true}
          isDead={serialNumber === ''}
          color={Colors.primary}
          text={t('UserPage.AddCardButtonText')}
          onClick={() => onSubmit(serialNumber)}
        />
      </div>
    </div>
  )
}
export default AddCardForm
