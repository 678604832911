import React, { useMemo, useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { IFTable, IFText, IFAvatarList, IFsvg, IFModal } from 'Components'
import RoleSelectors from 'Stores/Role/Selectors'
import RoleActions from 'Stores/Role/Actions'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Skeleton from '@mui/material/Skeleton'
import { Colors } from 'Theme'
import Styles from './RoleList.module.css'
import { Menu, MenuItem, Fade, Tooltip, IconButton } from '@mui/material'
import { capitalizeFirstLetter, formatDate } from '../../Utils/StringFunctions'
import RoleDetails from 'Containers/RoleDetails/RoleDetails'
import AuthSelectors from 'Stores/Auth/Selectors'

const RoleList = ({
  roles,
  fetchRolesRequestState,
  updateRoleRequestState,
  fetchRoles,
  loggedInAdmin,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchRoles()
  }, [])

  const modalRef = useRef()

  const [menuState, setMenuState] = useState({ open: false, role: null })
  const [selectedRole, setSelectedRole] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [registeredCountries, setRegisteredCountries] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
  })

  useEffect(() => {
    if (loggedInAdmin?.registeredCountries?.length > 1) {
      setRegisteredCountries(true)
    }
  }, [loggedInAdmin])

  useEffect(() => {
    setColumnVisibility({
      country: registeredCountries,
    })
  }, [registeredCountries])

  const handleClick = (event, role) => {
    setMenuState({ open: true, role: role })
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenuState({ open: false, role: null })
    setAnchorEl(null)
  }

  const handleMenuItemClick = () => {
    if (menuState.role) {
      setSelectedRole(menuState.role)
      modalRef.current.show()
      handleClose()
    }
  }
  useEffect(() => {
    if (updateRoleRequestState === RequestState.SUCCEEDED) {
      if (modalRef.current) {
        modalRef.current.dismiss()
      }
    }
  }, [updateRoleRequestState])

  const customDialogContentStyle = {
    height: '600px',
    width: '1150px',
  }

  const roleTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header:
          fetchRolesRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.Name')
          ),
        Cell: ({ row }) => (
          <IFText>{capitalizeFirstLetter(row.original.name)}</IFText>
        ),
      },
      {
        accessorKey: 'admins',
        header:
          fetchRolesRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="55px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.Admins')
          ),
        Cell: ({ row }) => (
          <div>
            <IFAvatarList
              admins={row.original.admins}
              avatarSize={27}
              maxDisplayed={3}
            />
          </div>
        ),
      },
      {
        accessorKey: 'permissions',
        header:
          fetchRolesRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="55px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.Permissions')
          ),
        Cell: ({ row }) => <IFText>{row.original.permissions.length}</IFText>,
      },
      {
        accessorKey: 'country',
        header:
          fetchRolesRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('Country')}</IFText>
          ),
        Cell: ({ row }) => <IFText>{row.original.country.name}</IFText>,
      },
      {
        accessorKey: 'updatedOn',
        header:
          fetchRolesRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.UpdatedOn')
          ),
        Cell: ({ row, index }) => (
          <div className={Styles.UpdateOnRoleColumn}>
            <div className={Styles.UpdateOnRoleTable}>
              <IFText>{formatDate(row.original.updatedAt)}</IFText>
            </div>

            <div>
              <Tooltip title={t('RoleList.MoreInfo')}>
                <IconButton
                  onClick={(event) => handleClick(event, row.original)}
                >
                  <IFsvg.MoreInfo
                    className={Styles.MoreInfo}
                    height={24}
                    width={24}
                    fill={Colors.primary}
                  />
                </IconButton>
              </Tooltip>
              {menuState.open && menuState.role === row.original && (
                <Menu
                  id={`simple-menu-${index}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={menuState.open && menuState.role === row.original}
                  onClose={handleClose}
                  elevation={2}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: 'transparent !important',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: `${Colors.HoverDeactivate} !important`,
                      },
                    }}
                    onClick={handleMenuItemClick}
                  >
                    <IFText
                      style={{
                        color: Colors.primary,
                      }}
                    >
                      {t('RoleList.MoreInfo')}
                    </IFText>
                  </MenuItem>
                </Menu>
              )}
            </div>
          </div>
        ),
      },
    ],
    [
      handleClick,
      handleClose,
      handleMenuItemClick,
      t,
      menuState,
      fetchRolesRequestState,
    ],
  )

  return (
    <>
      <IFTable
        columns={roleTableColumns}
        data={roles}
        hideColumnQuery={columnVisibility}
        tableMaxHeight={720}
        showSkeleton={fetchRolesRequestState === RequestState.LOADING}
        tableRequestState={fetchRolesRequestState}
      />

      <IFModal
        ref={modalRef}
        title={t('RoleDetails.RoleDetails')}
        maxWidth={60}
        dialogContentStyle={customDialogContentStyle}
        className={Styles.RoleDetailsModal}
      >
        <RoleDetails
          role={selectedRole}
          isLoading={fetchRolesRequestState === RequestState.LOADING}
        />
      </IFModal>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoles: () => dispatch(RoleActions.fetchRoles()),
  }
}
const mapStateToProps = (state) => ({
  roles: RoleSelectors.getRoles(state),
  fetchRolesRequestState: RoleSelectors.getFetchRolesRequestState(state),
  updateRoleRequestState: RoleSelectors.getUpdateRoleRequestState(state),
  loggedInAdmin: AuthSelectors.getUser(state),
})

RoleList.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object),
  fetchRolesRequestState: PropTypes.number,
  updateRoleRequestState: PropTypes.number,
  fetchRoles: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList)
