import Styles from './RolesPermissionsListItem.module.css'
import IFText from 'Components/IFText/IFText'
import IFToggle from 'Components/IFToggle/IFToggle'
import PropTypes from 'prop-types'

const RolesPermissionsListItem = ({
  isSelected,
  title,
  isEditing = false,
  onToggle,
}) => {
  const toggle = () => {
    if (isEditing) {
      onToggle()
    }
  }

  return (
    <div className={Styles.PermessionContainer}>
      <div className={Styles.PermissionsDiv}>
        <IFText className={Styles.PermissionText}>{title}</IFText>
      </div>
      <div className={Styles.IFToggleDiv}>
        <IFToggle
          checked={isSelected}
          readOnly={!isEditing}
          onChange={toggle}
        />
      </div>
    </div>
  )
}
RolesPermissionsListItem.propTypes = {
  isSelected: PropTypes.bool,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  onToggle: PropTypes.func,
}

export default RolesPermissionsListItem
