import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  IFFilter,
  IFText,
  IFButton,
  IFModal,
  IFSkeleton,
  IFTable,
} from 'Components'
import { AddVoucherForm } from 'Containers'
import Colors from 'Theme/Colors'
import Styles from './VouchersPage.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import VoucherActions from 'Stores/Voucher/Actions'
import { Box, IconButton, Skeleton, Tooltip } from '@mui/material'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import LoadingBar from 'react-top-loading-bar'
import AuthSelectors from 'Stores/Auth/Selectors'
import ReportIcon from '@mui/icons-material/Report'

const VouchersPage = ({
  addVoucher,
  setVouchersFilter,
  clearVouchers,
  vouchersFilter,
  fetchVouchersListRequestState,
  fetchVouchers,
  paginationOffset,
  vouchersCount,
  fetchVouchersCountRequestState,
  vouchers,
  updateVoucher,
  updateVoucherRequestState,
  setUpdateVoucherRequestState,
  defaultVouchersFilters,
  admin,
  clearVouchersFilter,
}) => {
  const { t } = useTranslation()
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [table, setTable] = useState()
  const tablesRef = useRef(null)
  const voucherFilterRef = useRef(null)
  const [tablesHeight, setTablesHeight] = React.useState(0)
  const [registeredCountires, setRegisteredCountries] = React.useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
  })
  useEffect(() => {
    return () => {
      clearVouchers()
      clearVouchersFilter()
      setIsFirstLoaded(true)
      voucherFilterRef?.current?.clearFilter()
    }
  }, [])
  useEffect(() => {
    if (admin?.registeredCountries?.length > 1) {
      setRegisteredCountries(true)
      setVouchersFilter(defaultVouchersFilters)
    }
  }, [admin])
  useEffect(() => {
    fetchVouchers(vouchersFilter, 0)
    setIsFirstLoaded(true)
  }, [vouchersFilter])
  useEffect(() => {
    if (fetchVouchersListRequestState === RequestState.SUCCEEDED)
      setIsFirstLoaded(false)
  }, [fetchVouchersListRequestState])
  useEffect(() => {
    setColumnVisibility({
      country: registeredCountires,
    })
  }, [registeredCountires])
  const onSubmitFormHandler = (voucher) => {
    addVoucher(voucher)
    showDialogHandler.current.dismiss()
  }
  const showDialogHandler = useRef()

  const loadMoreData = () => {
    if (
      fetchVouchersListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchVouchers(vouchersFilter, paginationOffset, failedRequest)
  }

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchVouchersListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchVouchersListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchVouchersListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchVouchersListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchVouchersListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchVouchersListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchVouchersListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchVouchersListRequestState])
  const handleRetry = () => {
    fetchVouchers(vouchersFilter, paginationOffset)
  }
  const vouchersTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        muiEditTextFieldProps: {
          type: 'text',
          sx: {
            '& .MuiInputBase-root:hover::before': {
              borderBottom: `1px solid ${Colors.primary} !important`,
            },
            '& .MuiInputBase-root.Mui-focused::after': {
              borderBottom: `2px solid ${Colors.primary} !important`,
            },
          },
        },
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.VouchersName')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText>{row.original.name}</IFText>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'code',
        enableEditing: false,
        size: 240,
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.VouchersCode')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText>{row.original.code}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'points',
        enableEditing: false,
        size: 130,
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.VouchersPoints')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText>{row.original.points}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'RedeemPerUser',
        size: 170,
        enableEditing: false,
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.RedeemPerUser')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText>
              {row.original.maxRedeemCountPerUser
                ? row.original.maxRedeemCountPerUser
                : '\uFF3F'}
            </IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'maxRedeemCount',
        size: 140,
        muiEditTextFieldProps: {
          type: 'number',
          sx: {
            '& .MuiInputBase-root:hover::before': {
              borderBottom: `1px solid ${Colors.primary} !important`,
            },
            '& .MuiInputBase-root.Mui-focused::after': {
              borderBottom: `2px solid ${Colors.primary} !important`,
            },
          },
        },
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.VouchersRedeemed')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText>
              {row.original.redeemCount}/{row.original.maxRedeemCount}
            </IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'Validity',
        enableEditing: false,
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.VouchersValidity')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText> {row.original.pointsExpireAfterDays}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'country',
        enableEditing: false,
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.Country')
          ),
        Cell: ({ row }) => {
          return row.index < vouchers.length ? (
            <IFText>{row.original.country.name}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'expiry',
        muiEditTextFieldProps: ({ row }) => {
          const [dateValue, setDateValue] = useState(
            row.original.expiry
              ? moment(row.original.expiry).format('YYYY-MM-DD')
              : '',
          )

          return {
            type: 'date',
            InputProps: {
              inputProps: {
                min: moment().format('YYYY-MM-DD'),
              },
            },
            sx: {
              '& .MuiInputBase-root:hover::before': {
                borderBottom: `1px solid ${Colors.primary} !important`,
              },
              '& .MuiInputBase-root.Mui-focused::after': {
                borderBottom: `2px solid ${Colors.primary} !important`,
              },
            },
            value: dateValue,
            onChange: (event) => {
              setDateValue(event.target.value)
            },
          }
        },
        header:
          fetchVouchersListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('VouchersPage.VouchersExpiry')
          ),
        Cell: ({ row }) => {
          let expiryDateMoment, expired, expiryText
          if (row.original.expiry) {
            expiryDateMoment = moment(row.original.expiry).format('YYYY-MM-DD')
            expired = moment().isSameOrAfter(moment(row.original.expiry))
              ? true
              : false
            expiryText = expired
              ? t('VouchersPage.ExpiredText')
              : t('VouchersPage.ExpiresOnText') + expiryDateMoment
          } else {
            expiryText = t('VouchersPage.NoExpiryDate')
          }
          return row.index < vouchers.length ? (
            <IFText>{expiryText}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [vouchers, fetchVouchersListRequestState, isFirstLoaded, failedRequest],
  )

  const handleSaveVoucher = ({ values, table, row }) => {
    if (
      values.name === row.original.name &&
      values.maxRedeemCount === row.original.maxRedeemCount &&
      values.expiry &&
      row.original.expiry &&
      values.expiry.toString() === row.original.expiry.toString()
    )
      table?.setEditingRow(null)
    else {
      setTable(table)
      updateVoucher(row.original.id, values, row.index, () => {})
    }
  }
  useEffect(() => {
    if (updateVoucherRequestState === RequestState.SUCCEEDED) {
      table?.setEditingRow(null)
    }
  }, [updateVoucherRequestState])

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (tablesRef.current) {
      resizeObserver.observe(tablesRef.current)
    }

    return () => {
      if (tablesRef.current) {
        resizeObserver.unobserve(tablesRef.current)
      }
    }
  }, [])
  const handleEdit = ({ row, table }) => {
    setUpdateVoucherRequestState(RequestState.UNINITIALIZED)
    table?.setEditingRow(row)
  }

  const renderRowActions = ({ table, row }) => {
    if (Object.keys(row.original).length > 0) {
      return (
        <Box>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEdit({ row, table })}>
              <EditIcon sx={{ color: Colors.gray }} />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={() => {
            handleRetry()
          }}
        />
      )
    }
  }
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    if (isFirstLoaded) {
      if (
        fetchVouchersListRequestState === RequestState.LOADING &&
        progress === 0
      )
        setProgress(progress + 10)

      if (fetchVouchersListRequestState === RequestState.SUCCEEDED)
        setProgress(100)

      if (
        fetchVouchersListRequestState !== RequestState.LOADING &&
        fetchVouchersListRequestState !== RequestState.UNINITIALIZED &&
        fetchVouchersListRequestState !== RequestState.SUCCEEDED
      )
        setProgress(100)
    }
  }, [fetchVouchersListRequestState])

  return (
    <div>
      <div className={Styles.WrapperContainer}>
        <div>
          <LoadingBar
            color={Colors.primary}
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
        </div>
        <div
          className={Styles.TopContainer}
          style={{
            backgroundColor: Colors.white,
            borderColor: Colors.UserPageBackgroundContainer,
          }}
        >
          <div className={Styles.TitleContainer}>
            <div className={Styles.TitleDiv}>
              <IFText
                className={Styles.TitleText}
                loadSkeleton={
                  fetchVouchersListRequestState === RequestState.LOADING &&
                  isFirstLoaded
                }
                skeletonWidth={'5rem'}
              >
                {t('VouchersPage.VouchersTitle')}
              </IFText>
              <div
                className={Styles.VouchersCount}
                style={{
                  backgroundColor:
                    (fetchVouchersListRequestState === RequestState.LOADING ||
                      fetchVouchersCountRequestState ===
                        RequestState.LOADING) &&
                    paginationOffset === 0
                      ? Colors.SkeletonColor
                      : Colors.primaryLight,
                }}
              >
                {(fetchVouchersListRequestState === RequestState.LOADING ||
                  fetchVouchersCountRequestState === RequestState.LOADING) &&
                paginationOffset === 0 ? (
                  <IFSkeleton variant="circular" />
                ) : (
                  <IFText>{vouchersCount}</IFText>
                )}
              </div>
            </div>
            <div>
              <IFButton
                size="sm"
                color={Colors.primary}
                isFill={false}
                isLoading={false}
                text={`+ ${t('VouchersPage.Add')}`}
                className={Styles.CreateButton}
                onClick={() => showDialogHandler.current.show()}
                loadSkeleton={
                  fetchVouchersListRequestState === RequestState.LOADING &&
                  isFirstLoaded
                }
              />
            </div>
          </div>

          <div className={Styles.Filter}>
            <IFFilter
              ref={voucherFilterRef}
              onFilterChange={(newFilter) => {
                clearVouchers()
                setVouchersFilter(newFilter)
              }}
              filters={vouchersFilter}
              textFieldPlaceholder={t('VouchersPage.VouchersFilterPlaceholder')}
            />
          </div>
          <Scrollbars autoHide className={Styles.ScrollParent}>
            <div className={Styles.Tables} ref={tablesRef}>
              <IFTable
                columns={vouchersTableColumns}
                data={vouchers}
                fetchMoreOnBottomReached={fetchMoreOnBottomReached}
                paginationOffset={paginationOffset}
                onSaveAction={handleSaveVoucher}
                enableEditing={true}
                isSaving={
                  updateVoucherRequestState === RequestState.LOADING
                    ? true
                    : false
                }
                filters={vouchersFilter}
                showSkeleton={
                  (fetchVouchersListRequestState === RequestState.LOADING &&
                    paginationOffset === 0) ||
                  (fetchVouchersListRequestState !== RequestState.SUCCEEDED &&
                    !paginationOffset)
                }
                tableMaxHeight={tablesHeight}
                tableSetHeight={'100%'}
                renderRowActions={renderRowActions}
                hideColumnQuery={columnVisibility}
                tableRequestState={fetchVouchersListRequestState}
              />
            </div>
          </Scrollbars>
        </div>
      </div>
      <IFModal
        title={t('VouchersPage.AddNewVoucher')}
        open={false}
        ref={showDialogHandler}
      >
        <AddVoucherForm
          onSubmitForm={(values) => onSubmitFormHandler(values)}
        />
      </IFModal>
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    setVouchersFilter: (newFilter) =>
      dispatch(VoucherActions.setVouchersFilter(newFilter)),
    clearVouchers: () => dispatch(VoucherActions.clearVouchers()),
    clearVouchersFilter: () => dispatch(VoucherActions.clearVouchersFilter()),
    fetchVouchers: (filter, offset, shouldShowError) =>
      dispatch(VoucherActions.fetchVouchers(filter, offset, shouldShowError)),
    addVoucher: (voucher) => dispatch(VoucherActions.addVoucher(voucher)),
    updateVoucher: (voucherId, newVoucher, index, onResponse) =>
      dispatch(
        VoucherActions.updateVoucher(voucherId, newVoucher, index, onResponse),
      ),
    setUpdateVoucherRequestState: (newState) =>
      dispatch(VoucherActions.setUpdateVoucherRequestState(newState)),
  }
}
const mapStateToProps = (state) => ({
  vouchersFilter: VoucherSelectors.getVouchersFilter(state),
  vouchers: VoucherSelectors.getVouchersList(state),
  fetchVouchersListRequestState:
    VoucherSelectors.getFetchVouchersListRequestState(state),
  paginationOffset: VoucherSelectors.getPaginationOffset(state),
  vouchersCount: VoucherSelectors.getVouchersCount(state),
  fetchVouchersCountRequestState:
    VoucherSelectors.getVouchersCountRequestState(state),
  updateVoucherRequestState:
    VoucherSelectors.getUpdateVoucherRequestState(state),
  admin: AuthSelectors.getUser(state),
  defaultVouchersFilters: VoucherSelectors.getDefaultVouchersFilters(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(VouchersPage)
