import { INITIAL_STATE } from './initialState'
import { TransactionTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import subDays from 'date-fns/subDays'
import history from 'history/browser'
var base64 = require('base-64')

export const fetchTransactionsListLoading = (state) => {
  return {
    ...state,
    fetchTransactionsListRequestState: RequestState.LOADING,
  }
}

export const fetchTransactionsListSuccess = (
  state,
  { offset, transactions, nextOffset, filter },
) => {
  let result = []
  for (let j = 0; j < transactions.length; j++) {
    if (
      !state.transactions.some(
        (transaction) => transaction._id === transactions[j]._id,
      )
    ) {
      result.push(transactions[j])
    }
  }
  const onTransactionsPage =
    window.location.pathname.toLocaleLowerCase().indexOf('transaction') ||
    history.location.pathname.indexOf('transaction')
  if (onTransactionsPage !== -1) {
    if (offset === 0) {
      history.push({
        pathname: '/transactions',
        search: `?filter=${base64.encode(JSON.stringify(filter))}`,
      })
      return {
        ...state,
        transactions: [...transactions],
        paginationOffset: nextOffset,
        fetchTransactionsListRequestState: RequestState.SUCCEEDED,
        transactionsFilter: filter,
      }
    } else {
      return {
        ...state,
        transactions: [...state.transactions, ...result],
        paginationOffset: nextOffset,
        fetchTransactionsListRequestState: RequestState.SUCCEEDED,
      }
    }
  } else {
    return {
      ...state,
      fetchTransactionsListRequestState: RequestState.UNINITIALIZED,
    }
  }
}

export const fetchTransactionsListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchTransactionsListRequestState: requestState,
  }
}

export const setTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    transactionsFilter: newFilter,
  }
}

export const clearTransactions = (state) => {
  return {
    ...state,
    transactions: [],
    paginationOffset: 0,
  }
}

export const fetchTransactionsMetricsLoading = (state) => {
  return {
    ...state,
    fetchTransactionsMetricsRequestState: RequestState.LOADING,
  }
}

export const fetchTransactionsMetricsSuccess = (
  state,
  {
    metrics: {
      totalMoneyCollected,
      totalChargingBalance,
      totalEnergyConsumption,
      totalUsers,
      transactionsDuration,
      transactionsCurrency,
    },
  },
) => {
  return {
    ...state,
    metrics: {
      totalMoneyCollected,
      totalChargingBalance,
      totalEnergyConsumption,
      totalUsers,
      transactionsDuration,
      transactionsCurrency,
    },
    fetchTransactionsMetricsRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchTransactionsMetricsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchTransactionsMetricsRequestState: requestState,
  }
}

export const fetchEnergyConsumptionChartLoading = (state) => {
  return {
    ...state,
    fetchEnergyConsumptionChartRequestState: RequestState.LOADING,
  }
}

export const fetchEnergyConsumptionChartSuccess = (
  state,
  { energyConsumption: { intervalsAC, intervalsDC, startDates } },
) => {
  return {
    ...state,
    energyConsumption: {
      intervalsAC,
      intervalsDC,
      startDates,
    },
    fetchEnergyConsumptionChartRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchEnergyConsumptionChartError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchEnergyConsumptionChartRequestState: requestState,
  }
}

export const setDateInitialValue = (state, { admin, filterParam }) => {
  const initialValue = [subDays(new Date(), 30), new Date()]
  const newTransactionsFilterIndex = state.transactionsFilter.findIndex(
    (filter) => filter.type === IFFIlterType.DATE_RANGE,
  )
  let newTransactionsFilter = state.transactionsFilter
  let invalidFilter = false
  if (admin.registeredCountries.length > 1) {
    let initialCountry = admin.registeredCountries[0].name
    let index = state.transactionsFilter.findIndex(
      (filter) => filter.type === IFFIlterType.SELECT,
    )
    if (index === -1)
      newTransactionsFilter.push({
        type: IFFIlterType.SELECT,
        title: 'Country',
        data: ['Egypt', 'Jordan'],
        initialValue: initialCountry,
        value: initialCountry,
        canClear: false,
      })
  }

  if (filterParam !== null) {
    try {
      const decodedString = atob(filterParam)
      let filterObject = JSON.parse(decodedString)
      for (let i = 0; i < filterObject.length; i++) {
        const { type: type1, title: title1, data: data1 } = filterObject[i]
        const {
          type: type2,
          title: title2,
          data: data2,
        } = newTransactionsFilter[i]
        if (
          type1 !== type2 ||
          title1 !== title2 ||
          JSON.stringify(data1) !== JSON.stringify(data2)
        )
          invalidFilter = false
      }
    } catch (error) {
      invalidFilter = true
    }
  }
  if (filterParam === null || invalidFilter) {
    newTransactionsFilter[newTransactionsFilterIndex].value = initialValue
  }

  return {
    ...state,
    transactionsFilter: [...newTransactionsFilter],
  }
}

export const clearTransactionsFilter = (state) => {
  return {
    ...state,
    transactionsFilter: [
      {
        type: IFFIlterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.CHECK,
        title: 'Type',
        data: ['addMoney', 'charge', 'refund'],
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.DATE_RANGE,
        title: 'Date',
        initialValue: [subDays(new Date(), 30), new Date()],
        value: [subDays(new Date(), 30), new Date()],
      },
      {
        type: IFFIlterType.VALUE_RANGE_DECIMAL,
        title: 'Amount',
        initialValue: [],
        value: [],
        field: 'amount',
      },
      {
        type: IFFIlterType.VALUE_RANGE_DECIMAL,
        title: 'Energy Consumption',
        initialValue: [],
        value: [],
        field: 'energyConsumption',
      },
    ],
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [TransactionTypes.FETCH_TRANSACTIONS_LIST_LOADING]:
    fetchTransactionsListLoading,
  [TransactionTypes.FETCH_TRANSACTIONS_LIST_SUCCESS]:
    fetchTransactionsListSuccess,
  [TransactionTypes.FETCH_TRANSACTIONS_LIST_ERROR]: fetchTransactionsListError,
  [TransactionTypes.SET_TRANSACTIONS_FILTER]: setTransactionsFilter,
  [TransactionTypes.CLEAR_TRANSACTIONS]: clearTransactions,
  [TransactionTypes.FETCH_TRANSACTIONS_METRICS_LOADING]:
    fetchTransactionsMetricsLoading,
  [TransactionTypes.FETCH_TRANSACTIONS_METRICS_SUCCESS]:
    fetchTransactionsMetricsSuccess,
  [TransactionTypes.FETCH_TRANSACTIONS_METRICS_ERROR]:
    fetchTransactionsMetricsError,
  [TransactionTypes.FETCH_ENERGY_CONSUMPTION_CHART_LOADING]:
    fetchEnergyConsumptionChartLoading,
  [TransactionTypes.FETCH_ENERGY_CONSUMPTION_CHART_SUCCESS]:
    fetchEnergyConsumptionChartSuccess,
  [TransactionTypes.FETCH_ENERGY_CONSUMPTION_CHART_ERROR]:
    fetchEnergyConsumptionChartError,
  [TransactionTypes.SET_DATE_INITIAL_VALUE]: setDateInitialValue,
  [TransactionTypes.CLEAR_TRANSACTIONS_FILTER]: clearTransactionsFilter,
})
