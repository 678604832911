import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from 'Containers'

const LoginRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </div>
  )
}

export default LoginRouter
