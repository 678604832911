import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import { AuthTypes } from '../Stores/Auth/Actions'
import { AdminTypes } from '../Stores/Admin/Actions'
import { UserTypes } from '../Stores/User/Actions'
import { TransactionTypes } from 'Stores/Transaction/Actions'
import { NotificationTypes } from '../Stores/Notification/Actions'
import { VoucherTypes } from '../Stores/Voucher/Actions'
import { RoleTypes } from '../Stores/Role/Actions'

import {
  login,
  getUser,
  logout,
  fetchEditingAdminRole,
  changePassword,
  fetchAdminDetails,
  setCurrentUserNewName,
} from './AuthSaga'

import {
  fetchAdminList,
  updateAdminRole,
  deleteAdmin,
  addAdmin,
  fetchAdminsCount,
} from './AdminSaga'

import {
  fetchUserList,
  setCurrentUserTransactionRefund,
  setCurrentUserNewBalance,
  setCurrentUserChargingTokenActive,
  addCurrentUserNewCard,
  toggleCurrentUserIsActive,
  fetchUserDetails,
  fetchUserTransactionList,
  setCurrentUserNewPhone,
  reverseUserChargeTransaction,
  fetchUserPoints,
  fetchUserPaymentSessionList,
  deleteCurrentUserPaymentCard,
} from './UserSaga'

import { fetchNotificationGroups, sendNotification } from './NotificationSaga'
import {
  fetchTransactionsList,
  fetchTransactionsMetrics,
  fetchEnergyConsumptionChart,
} from './TransactionSaga'
import { fetchVouchers, addVoucher, updateVoucher } from './VoucherSaga'

import { fetchRolesCount, updateRole, fetchRoles, createRole } from './RoleSaga'
export default function* root() {
  yield all([takeLatest(AuthTypes.LOGIN, login)])
  yield all([takeLatest(AuthTypes.GET_USER, getUser)])
  yield all([takeLatest(AuthTypes.LOGOUT, logout)])
  yield all([
    takeLatest(AuthTypes.FETCH_EDITING_ADMIN_ROLE, fetchEditingAdminRole),
  ])
  yield all([takeLatest(AuthTypes.CHANGE_PASSWORD, changePassword)])
  yield all([takeLatest(AuthTypes.FETCH_ADMIN_DETAILS, fetchAdminDetails)])
  yield all([
    takeLatest(AuthTypes.SET_CURRENT_USER_NEW_NAME, setCurrentUserNewName),
  ])

  yield all([takeLatest(UserTypes.FETCH_USER_LIST, fetchUserList)])
  yield all([takeLatest(UserTypes.FETCH_USER_DETAILS, fetchUserDetails)])

  yield all([
    takeLatest(UserTypes.FETCH_USER_TRANSACTION_LIST, fetchUserTransactionList),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_ACTIVE,
      setCurrentUserChargingTokenActive,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND,
      setCurrentUserTransactionRefund,
    ),
  ])
  yield all([takeLatest(UserTypes.FETCH_USER_POINTS, fetchUserPoints)])

  yield all([takeLatest(AdminTypes.FETCH_ADMIN_LIST, fetchAdminList)])
  yield all([takeLatest(AdminTypes.FETCH_ADMINS_COUNT, fetchAdminsCount)])

  yield all([takeLatest(AdminTypes.UPDATE_ADMIN_ROLE, updateAdminRole)])

  yield all([takeLatest(AdminTypes.DELETE_ADMIN, deleteAdmin)])
  yield all([takeLatest(AdminTypes.ADD_ADMIN, addAdmin)])

  yield all([takeLatest(RoleTypes.FETCH_ROLES_COUNT, fetchRolesCount)])
  yield all([takeLatest(RoleTypes.UPDATE_ROLE, updateRole)])
  yield all([takeLatest(RoleTypes.FETCH_ROLES, fetchRoles)])
  yield all([takeLatest(RoleTypes.CREATE_ROLE, createRole)])

  yield all([
    takeLatest(UserTypes.ADD_CURRENT_USER_NEW_CARD, addCurrentUserNewCard),
  ])

  yield all([
    takeLatest(
      UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE,
      toggleCurrentUserIsActive,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.DELETE_CURRENT_USER_PAYMENT_CARD,
      deleteCurrentUserPaymentCard,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_NEW_BALANCE,
      setCurrentUserNewBalance,
    ),
  ])

  yield all([
    takeLatest(UserTypes.SET_CURRENT_USER_NEW_PHONE, setCurrentUserNewPhone),
  ])

  yield all([
    takeLatest(
      UserTypes.REVERSE_USER_CHARGE_TRANSACTION,
      reverseUserChargeTransaction,
    ),
  ])

  yield all([
    takeLatest(
      NotificationTypes.FETCH_NOTIFICATION_GROUPS,
      fetchNotificationGroups,
    ),
  ])
  yield all([takeLatest(NotificationTypes.SEND_NOTIFICATION, sendNotification)])

  yield all([
    takeLatest(TransactionTypes.FETCH_TRANSACTIONS_LIST, fetchTransactionsList),
  ])

  yield all([
    takeLatest(
      TransactionTypes.FETCH_TRANSACTIONS_METRICS,
      fetchTransactionsMetrics,
    ),
  ])
  yield all([
    takeLatest(
      TransactionTypes.FETCH_ENERGY_CONSUMPTION_CHART,
      fetchEnergyConsumptionChart,
    ),
  ])

  yield all([takeLatest(VoucherTypes.FETCH_VOUCHERS, fetchVouchers)])

  yield all([takeLatest(VoucherTypes.ADD_VOUCHER, addVoucher)])

  yield all([takeEvery(VoucherTypes.UPDATE_VOUCHER, updateVoucher)])

  yield all([
    takeLatest(
      UserTypes.FETCH_USER_PAYMENT_SESSION_LIST,
      fetchUserPaymentSessionList,
    ),
  ])
}
