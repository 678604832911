import DefaultRouter from 'Navigators/DefaultRouter/DefaultRouter'
import LoginRouter from 'Navigators/LoginRouter/LoginRouter'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'

function AppBrowserRouter({ isLoggedIn }) {
  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? (
          <Route path="/*" element={<DefaultRouter />} />
        ) : (
          <Route path="/*" element={<LoginRouter />} />
        )}
      </Routes>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  isLoggedIn: AuthSelectors.isLoggedIn(state),
})

export default connect(mapStateToProps)(AppBrowserRouter)
