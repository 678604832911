import { React } from 'react'
import styles from './IFFilterSkeleton.module.css'
import styled from 'styled-components'
import colors from 'Theme/Colors'
import { IFSkeleton } from 'Components'

const FilterContainer = styled.div`
  border: 1px solid ${colors.skeleton};
  color: ${colors.FilterShadow};
`

const IFFilterSkeleton = () => {
  return (
    <FilterContainer className={styles.container}>
      <IFSkeleton className={styles.Circular} variant="circular" />
      <IFSkeleton className={styles.text} variant="text" />
    </FilterContainer>
  )
}

export default IFFilterSkeleton
