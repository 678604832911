const getRolesCount = (state) => {
  return state.main.roleState.count
}
const getfetchRolesCountRequestState = (state) => {
  return state.main.roleState.fetchRolesCountRequestState
}
const getUpdateRoleRequestState = (state) => {
  return state.main.roleState.updateRoleRequestState
}
const getFetchRolesRequestState = (state) => {
  return state.main.roleState.fetchRolesRequestState
}
const getRoles = (state) => {
  return state.main.roleState.roles
}
const getCreateRoleRequestState = (state) => {
  return state.main.roleState.createRoleRequestState
}
const RoleSelectors = {
  getfetchRolesCountRequestState,
  getRolesCount,
  getUpdateRoleRequestState,
  getFetchRolesRequestState,
  getRoles,
  getCreateRoleRequestState,
}

export default RoleSelectors
