import React, { useState, useRef } from 'react'
import Styles from './ReverseTransactionModal.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField, FormGroup, MenuItem } from '@mui/material'
import { object, string, number } from 'yup'
import InfinityEnums from 'Enums/InfinityEnums'
import { IFButton, IFDialog } from 'Components'

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const ReverseTransactionModal = ({
  onSubmit,
  isReverseTransactionLoading,
  energyConsumption,
  chargingLevel,
}) => {
  const { t } = useTranslation()
  const [energyConsumptionField, setEnergyConsumptionField] =
    useState(energyConsumption)
  const [chargingLevelField, setChargingLevelField] = useState(
    chargingLevel === 'AC'
      ? InfinityEnums.ChargingLevel.LEVEL1
      : InfinityEnums.ChargingLevel.LEVEL2,
  )
  const [reverseType, setReverseType] = useState('')
  const confirmReverseDialog = useRef()

  const initialValues = {
    energyConsumption: '',
    chargingLevel: '',
    reverseType: '',
  }

  const areFieldsUnchanged = () => {
    const formattedChargingLevel =
      chargingLevel === 'AC'
        ? InfinityEnums.ChargingLevel.LEVEL1
        : InfinityEnums.ChargingLevel.LEVEL2
    return (
      (energyConsumption == energyConsumptionField &&
        formattedChargingLevel == chargingLevelField) ||
      reverseType === ''
    )
  }

  const resetValues = () => {
    setEnergyConsumptionField(energyConsumption)
    setChargingLevelField(
      chargingLevel === 'AC'
        ? InfinityEnums.ChargingLevel.LEVEL1
        : InfinityEnums.ChargingLevel.LEVEL2,
    )
  }

  const formatChargingLevel = (chargingLevel) => {
    const lowerCase =
      chargingLevel.charAt(0).toLowerCase() + chargingLevel.slice(1)
    return lowerCase.replace(/\s+/g, '')
  }

  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div className={Styles.FieldsWrapper}>
        <Formik
          validationSchema={object({
            energyConsumption: number(),
            chargingLevel: string(),
          })}
          initialValues={initialValues}
          onSubmit={() => {
            confirmReverseDialog.current.show()
          }}
        >
          {({ values, errors }) => (
            <Form>
              <FormGroup className={Styles.InputWrappingDiv}>
                <Field
                  name="reverseType"
                  className={Styles.Input}
                  variant="outlined"
                  size="small"
                  as={CssTextField}
                  InputLabelProps={{ shrink: true }}
                  value={reverseType}
                  select
                  label={t('ReverseTransactionModal.ReverseTransactionType')}
                  onChange={(data) => {
                    resetValues()
                    setReverseType(data.target.value)
                  }}
                >
                  {Object.values(InfinityEnums.TransactionReverseType).map(
                    (type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ),
                  )}
                </Field>
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="reverseType" />
                </ErrorContainer>
              </FormGroup>
              <div
                className={
                  reverseType === InfinityEnums.TransactionReverseType.CORRECT
                    ? Styles.ShowContainer
                    : Styles.HideContainer
                }
              >
                <FormGroup
                  className={
                    reverseType === InfinityEnums.TransactionReverseType.CORRECT
                      ? Styles.InputWrappingDiv
                      : Styles.InputWrappingDivHidden
                  }
                >
                  <Field
                    className={Styles.Input}
                    size="small"
                    name="energyConsumption"
                    as={CssTextField}
                    type="number"
                    variant="outlined"
                    value={energyConsumptionField}
                    label={t('ReverseTransactionModal.EnergyConsumptionText')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) =>
                      setEnergyConsumptionField(data.target.value)
                    }
                  />
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="energyConsumption" />
                  </ErrorContainer>
                </FormGroup>
                <FormGroup
                  className={
                    reverseType === InfinityEnums.TransactionReverseType.CORRECT
                      ? Styles.InputWrappingDiv
                      : Styles.InputWrappingDivHidden
                  }
                >
                  <Field
                    name="chargingLevel"
                    variant="outlined"
                    size="small"
                    as={CssTextField}
                    InputLabelProps={{ shrink: true }}
                    value={chargingLevelField}
                    select
                    label={t('ReverseTransactionModal.ChargingLevelText')}
                    onChange={(data) => {
                      setChargingLevelField(data.target.value)
                    }}
                  >
                    {Object.values(InfinityEnums.ChargingLevel).map(
                      (level, index) => (
                        <MenuItem key={index} value={level}>
                          {level}
                        </MenuItem>
                      ),
                    )}
                  </Field>
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="chargingLevel" />
                  </ErrorContainer>
                </FormGroup>
              </div>

              <IFButton
                className={Styles.ButtonWrappingDiv}
                type="submit"
                color={Colors.primary}
                isDead={
                  reverseType === InfinityEnums.TransactionReverseType.REVERSE
                    ? false
                    : areFieldsUnchanged() && !isReverseTransactionLoading
                }
                isFill={true}
                isLoading={isReverseTransactionLoading}
                text={t('ReverseTransactionModal.SubmitText')}
              />
            </Form>
          )}
        </Formik>
      </div>
      <IFDialog
        ref={confirmReverseDialog}
        style={{ margin: '15em' }}
        open={false}
        title={
          reverseType === InfinityEnums.TransactionReverseType.CORRECT
            ? t('ReverseTransactionModal.ConfirmDialogTitleCorrect')
            : t('ReverseTransactionModal.ConfirmDialogTitleReverse')
        }
        bodyText={
          reverseType === InfinityEnums.TransactionReverseType.CORRECT
            ? t('ReverseTransactionModal.ConfirmDialogSubtitleCorrect')
            : t('ReverseTransactionModal.ConfirmDialogSubtitleReverse')
        }
        buttonAcceptonClick={() => {
          onSubmit({
            energyConsumption: parseFloat(energyConsumptionField),
            chargingLevel: formatChargingLevel(chargingLevelField),
            reverseType: reverseType,
          })
        }}
        buttonCancelColor={Colors.red}
        buttonAcceptColor={Colors.primary}
        buttonAcceptText={t('ReverseTransactionModal.ConfirmDialogButton')}
        buttonClassName={Styles.Button}
      />
    </FormContainer>
  )
}

export default ReverseTransactionModal
