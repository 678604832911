import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Styles from './IFToggle.module.css'
import styled from 'styled-components'
import IFText from 'Components/IFText/IFText'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { isPropsMatch } from 'Utils/PropsMatch'
import { styled as muiStyled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const getTextWidth = (txt, font) => {
  let element = document.createElement('canvas')
  let context = element.getContext('2d')
  context.font = font
  return context.measureText(txt).width
}

const ratioToggleHeight = 0.85
const ratioTopHeight = 0.075

const CustomLabel = styled.label`
  background: ${(props) => props.backgroundColor + Colors.HexTransparent.T85};
  &:hover {
    background: ${(props) => props.backgroundColor};
  }
  &:after {
    height: ${(props) => props.height}px;
    width: ${(props) => props.height}px;
    top: ${(props) => props.top}px;
    left: ${(props) => props.top}px;
    background: ${Colors.white};
    background-image: ${(props) =>
      props.checkedText
        ? props.isChecked
          ? `url('data:image/svg+xml,${props.encodedCheckedIcon}')`
          : `url('data:image/svg+xml,${props.encodedUncheckedIcon}')`
        : null};
    background-repeat: no-repeat;
    background-position: center;
  }
  &:active:after {
    width: ${(props) => props.toggleActive}px;
  }
`

const CustomInput = styled.input`
  &:checked + label:after {
    left: calc(100% - ${(props) => props.margin}px);
  }
`

const IFToggle = React.forwardRef(
  (
    {
      fontWeight = 'normal',
      height = 20,
      fontSize = 12,
      defaultChecked = false,
      readOnly = false,
      CheckedIcon,
      UncheckedIcon,
      checkedText = '',
      uncheckedText = '',
      checkedTextColor = Colors.white,
      uncheckedTextColor = Colors.white,
      checkedColor = Colors.primary,
      uncheckedColor = Colors.ToggleDisabled,
      marginLeftRight = 6,
      checked,
      onChange = () => {},
    },
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(defaultChecked)
    const [checkedIconWidth, setCheckedIconWidth] = useState(0)
    const [uncheckedIconWidth, setUnCheckedIconWidth] = useState(0)
    const [isClicked, setIsClicked] = useState(false)

    const checkedIconRef = useRef()
    const id = 'switch' + uuidv4()
    const uncheckedIconRef = useRef()

    useEffect(() => {
      setIsClicked(false)
    }, [readOnly])

    useEffect(() => {
      if (checkedIconRef && checkedIconRef.current) {
        setCheckedIconWidth(checkedIconRef.current.clientWidth)
      }
    }, [checkedIconRef])
    useEffect(() => {
      if (uncheckedIconRef && uncheckedIconRef.current) {
        setUnCheckedIconWidth(uncheckedIconRef.current.clientWidth)
      }
    }, [uncheckedIconRef])
    useEffect(() => {
      if (checked !== undefined) {
        setIsChecked(checked)
      }
    }, [checked])
    useEffect(() => {
      onChange(isChecked)
    }, [isChecked])

    const getValue = () => isChecked
    const toggleValue = () => setIsChecked(!isChecked)
    const setValue = (value) => setIsChecked(value)
    useImperativeHandle(ref, () => ({
      getValue,
      toggleValue,
      setValue,
    }))

    return (
      <Box
        className={
          isClicked
            ? `${Styles.Container} ${Styles.transitionWidth}`
            : Styles.Container
        }
        sx={{
          width:
            height * ratioTopHeight +
            height * ratioToggleHeight +
            marginLeftRight +
            (isChecked
              ? checkedText
                ? getTextWidth(
                    checkedText,
                    `${fontWeight} ${fontSize}px ProximaNova`,
                  )
                : checkedIconWidth
              : uncheckedText
              ? getTextWidth(
                  uncheckedText,
                  `${fontWeight} ${fontSize}px ProximaNova`,
                )
              : uncheckedIconWidth) +
            marginLeftRight,
        }}
      >
        <div
          className={
            isClicked
              ? isChecked
                ? `${Styles.checkAnimation} ${Styles.IconTextContainer}`
                : `${Styles.checkAnimationReverse} ${Styles.IconTextContainer}`
              : isChecked
              ? `${Styles.initialCheckedVisible} ${Styles.IconTextContainer}`
              : Styles.IconTextContainer
          }
          sx={{
            right: marginLeftRight,
          }}
        >
          {checkedText ? (
            <IFText
              style={{
                color: checkedTextColor,
                fontSize: fontSize,
                fontWeight: fontWeight,
              }}
            >
              {checkedText}
            </IFText>
          ) : (
            <div ref={checkedIconRef} className={Styles.IconContainer}>
              {CheckedIcon}
            </div>
          )}
        </div>
        <div
          className={
            isClicked
              ? isChecked
                ? `${Styles.uncheckAnimation} ${Styles.IconTextContainer}`
                : `${Styles.uncheckAnimationReverse} ${Styles.IconTextContainer}`
              : isChecked
              ? Styles.IconTextContainer
              : `${Styles.initialUncheckedVisible} ${Styles.IconTextContainer}`
          }
          sx={{
            right: marginLeftRight,
          }}
        >
          {uncheckedText ? (
            <IFText
              style={{
                color: uncheckedTextColor,
                fontSize: fontSize,
                fontWeight: fontWeight,
              }}
            >
              {uncheckedText}
            </IFText>
          ) : (
            <div ref={uncheckedIconRef} className={Styles.IconContainer}>
              {UncheckedIcon}
            </div>
          )}
        </div>
        <CustomInput
          className={Styles.Input}
          type="checkbox"
          autoFocus={false}
          id={id}
          checked={isChecked}
          onChange={(e) => {
            setIsClicked(true)
            setIsChecked(e.target.checked)
          }}
          disabled={readOnly}
          margin={height * ratioTopHeight}
        />
        <CustomLabel
          height={height * ratioToggleHeight}
          top={height * ratioTopHeight}
          className={
            isClicked
              ? `${Styles.Label} ${Styles.transitionWidthBackground}`
              : Styles.Label
          }
          labelHeightAfter={height * ratioTopHeight}
          encodedCheckedIcon={CheckedIcon}
          encodedUncheckedIcon={UncheckedIcon}
          isChecked={isChecked}
          checkedText={checkedText}
          toggleActive={height * ratioToggleHeight + height * ratioTopHeight}
          backgroundColor={isChecked ? checkedColor : uncheckedColor}
          style={{
            height: height,
            width:
              height * ratioTopHeight +
              height * ratioToggleHeight +
              marginLeftRight +
              (isChecked
                ? checkedText
                  ? getTextWidth(
                      checkedText,
                      `${fontWeight} ${fontSize}px ProximaNova`,
                    )
                  : checkedIconWidth
                : uncheckedText
                ? getTextWidth(
                    uncheckedText,
                    `${fontWeight} ${fontSize}px ProximaNova`,
                  )
                : uncheckedIconWidth) +
              marginLeftRight,
          }}
          htmlFor={id}
        />
      </Box>
    )
  },
)

IFToggle.propTypes = {
  height: PropTypes.number,
  fontSize: PropTypes.number,
  defaultChecked: PropTypes.bool,
  readOnly: PropTypes.bool,
  CheckedIcon: PropTypes.element,
  UncheckedIcon: PropTypes.element,
  checkedText: PropTypes.string,
  uncheckedText: PropTypes.string,
  marginLeftRight: PropTypes.number,
  checked: PropTypes.bool,
  fontWeight: PropTypes.string,
  checkedTextColor: PropTypes.string,
  uncheckedTextColor: PropTypes.string,
  checkedColor: PropTypes.string,
  uncheckedColor: PropTypes.string,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'height',
    'fontSize',
    'readOnly',
    'CheckedIcon',
    'UncheckedIcon',
    'checkedText',
    'uncheckedText',
    'marginLeftRight',
    'checked',
    'fontWeight',
    'checkedTextColor',
    'uncheckedTextColor',
    'checkedColor',
    'uncheckedColor',
  ])
}
export default React.memo(IFToggle, shouldSkipRender)
