import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { AdminList, RoleList, AdminPageTabs } from 'Containers'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import RoleActions from 'Stores/Role/Actions'
import RequestState from 'Enums/RequestState'
import { IFButton, IFFilter, IFModal, IFSkeleton } from 'Components'
import Styles from './AdminPage.module.css'
import { useTranslation } from 'react-i18next'
import Colors from 'Theme/Colors'
import RoleSelectors from 'Stores/Role/Selectors'
import CreateRole from './CreateRoleModal'
import AuthSelectors from 'Stores/Auth/Selectors'
import AdminPermissions from 'Enums/InfinityEnums'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'

const AdminsContainer = styled.div`
  background-color: ${Colors.white};
`

const AdminPage = ({
  fetchAdminList,
  clearAdminsFilter,
  setAdminsFilter,
  fetchAdminListRequestState,
  adminsFilter,
  updateAdminsFilterWithRoles,
  roles,
  AdminCount,
  RoleCount,
  fetchAdminsCount,
  fetchRoleCount,
  createRole,
  createRoleRequestState,
  loggedInAdmin,
  adminFilters,
  fetchRoles,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const createRoleModalRef = useRef()
  const canEditRoles = loggedInAdmin.role.permissions.includes(
    AdminPermissions.AdminPermissions.CAN_CREATE_ROLES,
  )
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    fetchAdminList(adminFilters, 0)
    fetchRoles()
  }, [])

  useEffect(() => {
    updateAdminsFilterWithRoles(roles)
    fetchAdminsCount(adminsFilter)
    fetchRoleCount()
  }, [roles])

  useEffect(() => {
    if (fetchAdminListRequestState === RequestState.LOADING && progress === 0)
      setProgress(progress + 10)
    if (fetchAdminListRequestState === RequestState.SUCCEEDED) {
      setProgress(100)
    }
    if (
      fetchAdminListRequestState !== RequestState.LOADING &&
      fetchAdminListRequestState !== RequestState.UNINITIALIZED &&
      fetchAdminListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchAdminListRequestState])

  const handleTabChange = (newValue) => {
    setSelectedTabIndex(newValue)
  }

  const handleFilterChange = (newFilter) => {
    setAdminsFilter(newFilter)
    fetchAdminList(newFilter, 0)
    fetchAdminsCount(newFilter)
  }
  const handleCreateNewRoleClick = () => {
    createRoleModalRef.current.show()
  }
  const handleInviteAdminClick = () => {}
  useEffect(() => {
    if (createRoleRequestState === RequestState.SUCCEEDED) {
      if (createRoleModalRef.current) {
        createRoleModalRef.current.dismiss()
      }
    }
  }, [createRoleRequestState])

  const onSubmitCreateRoleHandler = (role) => {
    createRole(role)
  }

  useEffect(() => {
    return () => {
      clearAdminsFilter()
    }
  }, [])
  const { t } = useTranslation()

  return (
    <div className={Styles.Wrapper}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <AdminsContainer className={Styles.AdminsContainer}>
        <div className={Styles.TabButtonDiv}>
          <AdminPageTabs
            onValueChange={handleTabChange}
            initialIndex={0}
            isLoading={fetchAdminListRequestState === RequestState.LOADING}
            adminFilter={adminsFilter}
            AdminCount={AdminCount}
            RoleCount={RoleCount}
          />
          {canEditRoles && (
            <div className={Styles.InviteAdminDiv}>
              {fetchAdminListRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="text" width="100px" />
              ) : (
                <IFButton
                  size="sm"
                  color={Colors.primary}
                  isFill={false}
                  isLoading={false}
                  text={
                    selectedTabIndex === 0
                      ? t('AdminPage.InviteAdmin')
                      : t('AdminPage.NewRole')
                  }
                  className={Styles.InviteAdminButton}
                  onClick={
                    selectedTabIndex === 0
                      ? handleInviteAdminClick
                      : handleCreateNewRoleClick
                  }
                />
              )}
            </div>
          )}
        </div>
        {selectedTabIndex === 0 ? (
          <div className={Styles.AdminTabListContainer}>
            <div className={Styles.AdminFilterDiv}>
              <IFFilter
                onFilterChange={handleFilterChange}
                filters={adminsFilter}
                textFieldPlaceholder={t('AdminPage.AdminFilterPlaceholder')}
              />
            </div>
            <div className={Styles.AdminListTab}>
              <AdminList />
            </div>
          </div>
        ) : (
          <div className={Styles.AdminListTab}>
            <RoleList />
          </div>
        )}

        <IFModal
          title={t('RoleDetails.CreateNewRole')}
          ref={createRoleModalRef}
        >
          <CreateRole
            onSubmitForm={(values) => onSubmitCreateRoleHandler(values)}
          />
        </IFModal>
      </AdminsContainer>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAdminList: (filter, offset) =>
      dispatch(AdminActions.fetchAdminList(filter, offset)),
    setAdminsFilter: (newFilter) =>
      dispatch(AdminActions.setAdminsFilter(newFilter)),
    clearAdminsFilter: () => dispatch(AdminActions.clearAdminsFilter()),
    updateAdminsFilterWithRoles: (roles) =>
      dispatch(AdminActions.updateAdminsFilterWithRoles(roles)),
    fetchAdminsCount: (adminFilter) =>
      dispatch(AdminActions.fetchAdminsCount(adminFilter)),
    fetchRoleCount: () => dispatch(RoleActions.fetchRolesCount()),
    createRole: (role) => dispatch(RoleActions.createRole(role)),
    fetchRoles: () => dispatch(RoleActions.fetchRoles()),
  }
}

const mapStateToProps = (state) => ({
  roles: RoleSelectors.getRoles(state),
  AdminCount: AdminSelectors.getAdminsCount(state),
  RoleCount: RoleSelectors.getRolesCount(state),
  loggedInAdmin: AuthSelectors.getUser(state),
  fetchAdminListRequestState:
    AdminSelectors.getfetchAdminListRequestState(state),
  adminsFilter: AdminSelectors.getAdminsFilters(state),
  createRoleRequestState: RoleSelectors.getCreateRoleRequestState(state),
})
AdminPage.propTypes = {
  fetchAdminList: PropTypes.func,
  clearAdminsFilter: PropTypes.func,
  setAdminsFilter: PropTypes.func,
  fetchAdminListRequestState: PropTypes.number,
  adminsFilter: PropTypes.object,
  updateAdminsFilterWithRoles: PropTypes.func,
  roles: PropTypes.array,
  AdminCount: PropTypes.number,
  RoleCount: PropTypes.number,
  fetchAdminsCount: PropTypes.func,
  fetchRoleCount: PropTypes.func,
  createRole: PropTypes.func,
  createRoleRequestState: PropTypes.number,
  loggedInAdmin: PropTypes.object,
  adminFilters: PropTypes.object,
  fetchRoles: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)
