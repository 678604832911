import { React, useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import styles from './TransactionList.module.css'
import RequestState from 'Enums/RequestState'
import {
  TransactionSessionDetail,
  FundTransactionSessionDetail,
  IFModal,
  IFText,
  IFTable,
  IFButton,
} from 'Components'
import moment from 'moment'
import { ReverseTransactionModal } from 'Containers'
import { useTranslation } from 'react-i18next'
import { Colors } from 'Theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfinityEnums from 'Enums/InfinityEnums'
import { Skeleton } from '@mui/material'
import { formatDate } from '../../Utils/StringFunctions'
import ReportIcon from '@mui/icons-material/Report'

const TransactionList = ({
  userId,
  setCurrentUserTransactionRefund,
  transactions,
  transactionsFilters,
  fetchUserTransactionList,
  transactionListPaginationOffset,
  fetchUserTransactionListRequestState,
  editingAdminRole,
  reverseUserChargeTransaction,
  reverseChargeTransactionRequestState,
  userDetailsRequestState,
  fetchUserListRequestState,
  tablesHeight,
}) => {
  const reverseChargingTransactionRef = useRef()
  const [transactionData, setTransactionData] = useState({})
  const { t } = useTranslation()
  const loadMoreData = () => {
    if (
      fetchUserTransactionListRequestState === RequestState.LOADING ||
      transactionListPaginationOffset === null
    )
      return
    fetchUserTransactionList(
      userId,
      transactionsFilters,
      transactionListPaginationOffset,
      failedRequest,
    )
  }
  useEffect(() => {
    if (reverseChargeTransactionRequestState === RequestState.SUCCEEDED)
      fetchUserTransactionList(userId, transactionsFilters, 0)
  }, [reverseChargeTransactionRequestState])

  const handleReverseChargeTransaction = (values) => {
    reverseUserChargeTransaction(values, transactionData, () => {
      reverseChargingTransactionRef.current?.dismiss()
    })
  }
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchUserTransactionListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchUserTransactionListRequestState ===
        RequestState.ERROR_400_OCCURRED ||
      fetchUserTransactionListRequestState ===
        RequestState.ERROR_401_OCCURRED ||
      fetchUserTransactionListRequestState ===
        RequestState.ERROR_403_OCCURRED ||
      fetchUserTransactionListRequestState ===
        RequestState.ERROR_409_OCCURRED ||
      fetchUserTransactionListRequestState ===
        RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchUserTransactionListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchUserTransactionListRequestState])
  const handleRetry = () => {
    fetchUserTransactionList(
      userId,
      transactionsFilters,
      transactionListPaginationOffset,
    )
  }
  const transactionTableColumns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('UserPage.TransactionTableColumns.type')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <div className={styles.ColumnTitle}>
              <ExpandMoreIcon
                className={styles.ExpandMoreIcon}
                style={{
                  transform: row.getIsExpanded()
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)',
                }}
              />
              <div className={styles.TransactionName}>
                <IFText>
                  {t(
                    `UserPage.TransactionTableColumns.Type.${row.original.type}`,
                  )}
                </IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <div className={styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'name',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('UserPage.TransactionTableColumns.Name')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <IFText>
              {row.original.type === 'charge'
                ? row.original.details.station.address.streetName
                : row.original.paymentIssuer}
            </IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'status',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('UserPage.TransactionTableColumns.Status')
          ),
        Cell: ({ row }) => {
          const status = row.original.status
          let color

          switch (status) {
            case InfinityEnums.TransactionStatus.NORMAL:
              color = Colors.green
              break
            case InfinityEnums.TransactionStatus.REVERSED:
              color = Colors.gray
              break
            case InfinityEnums.TransactionStatus.VOID:
              color = Colors.red
              break
            default:
              color = Colors.gray
          }

          return row.index < transactions.length ? (
            <div
              className={styles.Status}
              style={{
                backgroundColor: Colors.Status,
              }}
            >
              <IFText style={{ color: color }}>{status}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'amountText',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('UserPage.TransactionTableColumns.Amount')
          ),
        Cell: ({ row }) => {
          const amountExists =
            row.original.amount === 0 || row.original.amount === '0'
              ? false
              : true
          const pointsExists =
            row.original.pointsAmount === 0 ||
            row.original.pointsAmount === '0' ||
            row.original.pointsAmount === undefined
              ? false
              : true
          const amountText = row.original.isCredit
            ? `+${row.original.country?.currency?.symbol} ${Number(
                row.original.amount,
              ).toFixed(2)}`
            : `-${row.original.country?.currency?.symbol} ${Number(
                row.original.amount,
              ).toFixed(2)}`
          const pointsAmount = row.original.isCredit
            ? `+PTS ${Intl.NumberFormat('en-US').format(
                row.original.pointsAmount,
              )}`
            : `-PTS ${Intl.NumberFormat('en-US').format(
                row.original.pointsAmount,
              )}`
          let amount = ''
          if (row.original.type === 'charge') {
            amount =
              amountExists && pointsExists
                ? `${amountText}/${pointsAmount}`
                : amountExists
                ? amountText
                : pointsAmount
          } else {
            amount = amountText
          }
          return row.index < transactions.length ? (
            <IFText>{amount}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'startedAt',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('UserPage.TransactionTableColumns.ProcessedOn')
          ),
        Cell: ({ row }) => {
          return row.index < transactions.length ? (
            <IFText>
              {row.original.type === 'charge'
                ? formatDate(row.original.details.startedAt)
                : formatDate(row.original.createdAt)}
            </IFText>
          ) : failedRequest ? (
            <div className={styles.IconDiv}>
              <IFButton
                size="sm"
                color={Colors.red}
                text={t('IFTable.TryAgain')}
                onClick={() => {
                  handleRetry()
                }}
              />
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [
      transactions,
      userDetailsRequestState,
      fetchUserListRequestState,
      failedRequest,
    ],
  )

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const renderDetailPanel = ({ row }) =>
    row.original.type === 'charge' ? (
      <TransactionSessionDetail
        row={row}
        editingAdminRole={editingAdminRole}
        onReverseClick={(id) => {
          setTransactionData({
            id: id,
            index: row.index,
          })
          reverseChargingTransactionRef.current.show()
        }}
      />
    ) : (
      <FundTransactionSessionDetail
        row={row}
        editingAdminRole={editingAdminRole}
        onRefundClick={() => {
          setCurrentUserTransactionRefund(row.original.id, row.index)
        }}
        isRefundDisabled={
          row.original.isRefunded ||
          moment(new Date().setHours(0, 0, 0, 0)).diff(row.original.createdAt) /
            86400000 >
            14
        }
      />
    )
  return (
    <div className={styles.tables}>
      {((fetchUserTransactionListRequestState !== RequestState.SUCCEEDED &&
        !transactionListPaginationOffset) ||
        transactions) && (
        <IFTable
          columns={transactionTableColumns}
          data={transactions}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
          paginationOffset={transactionListPaginationOffset}
          filters={transactionsFilters}
          showSkeleton={
            (fetchUserTransactionListRequestState === RequestState.LOADING &&
              transactionListPaginationOffset === 0) ||
            (fetchUserTransactionListRequestState !== RequestState.SUCCEEDED &&
              !transactionListPaginationOffset)
          }
          renderDetailPanel={renderDetailPanel}
          tableMaxHeight={tablesHeight}
          tableSetHeight={'100%'}
          tableRequestState={fetchUserTransactionListRequestState}
        />
      )}
      <IFModal
        title={t('ReverseTransactionModal.Title')}
        open={false}
        ref={reverseChargingTransactionRef}
        canDismiss={
          !transactions[transactionData.index]
            ?.isReverseChargeTransactionLoading
        }
      >
        <ReverseTransactionModal
          onSubmit={(values) => handleReverseChargeTransaction(values)}
          isReverseTransactionLoading={
            transactions[transactionData.index]
              ?.isReverseChargeTransactionLoading
          }
          energyConsumption={
            transactions[transactionData.index]?.details.energyConsumption
          }
          chargingLevel={
            transactions[transactionData.index]?.details.chargerType
          }
        />
      </IFModal>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentUserTransactionRefund: (id, index) =>
      dispatch(UserActions.setCurrentUserTransactionRefund(id, index)),
    fetchUserTransactionList: (userId, filter, offset, shouldShowError) =>
      dispatch(
        UserActions.fetchUserTransactionList(
          userId,
          filter,
          offset,
          shouldShowError,
        ),
      ),
    reverseUserChargeTransaction: (values, data, onResponse) =>
      dispatch(
        UserActions.reverseUserChargeTransaction(values, data, onResponse),
      ),
  }
}

const mapStateToProps = (state) => ({
  userDetailsRequestState: UserSelectors.getUserDetailsRequestState(state),
  transactions: UserSelectors.getTransactionList(state),
  transactionListPaginationOffset:
    UserSelectors.getTransactionListPaginationOffset(state),
  fetchUserTransactionListRequestState:
    UserSelectors.getFetchUserTransactionListRequestState(state),
  transactionsFilters: UserSelectors.getTransactionsFilters(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  reverseChargeTransactionRequestState:
    UserSelectors.getReverseChargeTransactionRequestState(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList)
