import { LineChart } from '@mui/x-charts/LineChart'
import Styles from './EnergyConsumptionChart.module.css'
import PropTypes from 'prop-types'
import React from 'react'
import dayjs from 'dayjs'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { IFText } from 'Components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from 'Theme'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
`

const BlurOverlayText = styled.div`
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 600;
  background-color: ${Colors.white};
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
}
`

const formatStartDates = (startDates) => {
  if (!Array.isArray(startDates)) {
    return []
  }

  const allSameDay = startDates.every((date) =>
    dayjs(date).isSame(dayjs(startDates[0]), 'day'),
  )

  const minDate = dayjs(startDates[0])
  const maxDate = dayjs(startDates[startDates.length - 1])
  const daysDifference = maxDate.diff(minDate, 'day')

  if (allSameDay) {
    return startDates.map((date) => dayjs(date).format('HH:mm'))
  }

  if (daysDifference < 10) {
    return startDates.map((date) => dayjs(date).format('ddd, HH:mm'))
  }

  const allSameYear = startDates.every(
    (date) => dayjs(date).year() === dayjs(startDates[0]).year(),
  )

  return startDates.map((date) =>
    allSameYear ? dayjs(date).format('DD MMM') : dayjs(date).format('MMM YY'),
  )
}

const EnergyConsumptionChart = ({
  totalAC = [],
  totalDC = [],
  startDates = [],
}) => {
  const { t } = useTranslation()

  const formattedDates = Array.isArray(startDates)
    ? formatStartDates(startDates)
    : []

  const chartSetting = {
    yAxis: [
      {
        label: t('TransactionsPage.ChartYAxis'),
        labelStyle: {
          fontSize: '12px',
        },
      },
    ],
  }

  const isNoData =
    totalAC.every((value) => value === 0) &&
    totalDC.every((value) => value === 0)

  const emptySeries = {
    series: [],
    margin: { top: 10, left: 25, bottom: 25 },
  }

  const fixMargin = true
  const fixLabel = true

  return (
    <Card>
      <div className={Styles.ChartContainer} style={{ position: 'relative' }}>
        {isNoData ? (
          <div>
            <BlurOverlayText>
              <IFText>{t('TransactionsPage.ChartNoData')}</IFText>
            </BlurOverlayText>
            <LineChart
              className={Styles.BlurBackground}
              xAxis={[
                {
                  scaleType: 'point',
                  data: formattedDates || [],
                },
              ]}
              {...emptySeries}
              height={208}
              sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  transform: 'translateX(-40px)',
                },
              }}
            />
          </div>
        ) : (
          <LineChart
            xAxis={[
              {
                scaleType: 'point',
                data: formattedDates || [],
              },
            ]}
            series={[
              {
                id: 'energy-series1',
                data: totalAC || [],
                label: 'AC',
              },
              {
                id: 'energy-series2',
                data: totalDC || [],
                label: 'DC',
              },
            ]}
            {...chartSetting}
            height={208}
            margin={fixMargin ? { top: 10, bottom: 20, left: 100 } : undefined}
            sx={
              fixLabel
                ? {
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: 'translateX(-40px)',
                    },
                  }
                : {}
            }
          />
        )}
      </div>
    </Card>
  )
}
EnergyConsumptionChart.propTypes = {
  totalAC: PropTypes.array,
  totalDC: PropTypes.array,
  startDates: PropTypes.array,
}

export default EnergyConsumptionChart
