import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchRoles: null,
  fetchRolesLoading: null,
  fetchRolesSuccess: ['roles'],
  fetchRolesError: ['error'],

  fetchRolesCount: null,
  fetchRolesCountLoading: null,
  fetchRolesCountSuccess: ['count'],
  fetchRolesCountFail: ['error'],

  updateRole: ['roleId', 'newRoleName', 'newRolePermissions'],
  updateRoleLoading: ['roleId'],
  updateRoleSuccess: ['role'],
  updateRoleFail: ['roleId'],

  createRole: ['role'],
  createRoleLoading: null,
  createRoleSuccess: ['role'],
  createRoleFail: null,
})

export const RoleTypes = Types
export default Creators
