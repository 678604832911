import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import { Colors } from 'Theme'
import { IFButton, IFDialog, IFText, IFTable, IFSkeleton } from 'Components'
import Styles from './PaymentCardList.module.css'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import RequestState from 'Enums/RequestState'
import { formatDate } from '../../Utils/StringFunctions'

const PaymentCardList = ({
  userId,
  currentUserPaymentCards,
  editingAdminRole,
  userDetailsRequestState,
  fetchUserListRequestState,
  admin,
  deleteCurrentUserPaymentCard,
}) => {
  const { t } = useTranslation()
  const [selectedRowCard, setSelectedRowCard] = useState({})
  const [viewCountry, setViewCountry] = useState(false)
  const [editCards, setEditCards] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
    deleteButton: false,
  })
  useEffect(() => {
    setEditCards(
      editingAdminRole.includes(
        InfinityEnums.AdminPermissions.CAN_EDIT_USER_PAYMENT_CARDS,
      ),
    )
  }, [])
  useEffect(() => {
    setColumnVisibility({
      country: viewCountry,
      deleteButton: editCards,
    })
  }, [viewCountry, editCards])

  useEffect(() => {
    if (admin.registeredCountries.length > 1) {
      setViewCountry(true)
    } else {
      setViewCountry(false)
    }
  }, [admin])
  const showDialogHandlerCard = useRef()

  const paymentCardsTableColumns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('PaymentCardList.type')
          ),
        Cell: ({ row }) => <IFText>{row.original.type}</IFText>,
      },
      {
        accessorKey: 'cardNumber',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('PaymentCardList.cardNumber')
          ),
        Cell: ({ row }) => <IFText> {row.original.number}</IFText>,
      },
      {
        accessorKey: 'uid',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('PaymentCardList.uid')
          ),
        Cell: ({ row }) => <IFText> {row.original.uid}</IFText>,
      },
      {
        accessorKey: 'createdAt',
        header:
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('PaymentCardList.createdAt')
          ),
        Cell: ({ row }) => (
          <IFText> {formatDate(row.original.createdAt)}</IFText>
        ),
      },
      {
        accessorKey: 'country',
        size: 140,
        header:
          userDetailsRequestState === RequestState.UNINITIALIZED ||
          userDetailsRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('PaymentCardList.country')
          ),
        Cell: ({ row }) => <IFText> {row.original.country?.name}</IFText>,
      },
      {
        accessorKey: 'deleteButton',
        size: viewCountry ? 220 : 180,
        header:
          userDetailsRequestState === RequestState.UNINITIALIZED ||
          userDetailsRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="text" width="110px" height={20} />
          ) : (
            t('PaymentCardList.deleteButtonAction')
          ),
        Cell: ({ row }) => (
          <div className={Styles.deactivateWrapper}>
            <div className={Styles.deactivateContainer}>
              <IFButton
                size="sm"
                isLoading={row.original.isLoading}
                text={t('PaymentCardList.deleteButton')}
                color={Colors.UserPageDeactivateButton}
                onClick={() => {
                  setSelectedRowCard(row)
                  showDialogHandlerCard.current.show()
                }}
              />
            </div>
            <IFDialog
              ref={showDialogHandlerCard}
              open={false}
              maxWidth={'xs'}
              fullWidth={true}
              title={t('PaymentCardList.DeleteTitleModal')}
              bodyText={t('PaymentCardList.DeleteBodyText')}
              buttonAcceptonClick={() => {
                deleteCurrentUserPaymentCard(
                  userId,
                  selectedRowCard.original._id,
                  selectedRowCard.index,
                )
              }}
              buttonCancelColor={Colors.UserPageCancel}
              buttonAcceptColor={Colors.UserPageDeactivateButton}
              buttonAcceptText={t('PaymentCardList.deleteButton')}
            />
          </div>
        ),
      },
    ],
    [
      currentUserPaymentCards,
      fetchUserListRequestState,
      userDetailsRequestState,
      selectedRowCard,
    ],
  )
  const renderToolbar = () => <div className={Styles.TopToolBar}></div>

  return (
    <>
      <IFTable
        columns={paymentCardsTableColumns}
        data={currentUserPaymentCards}
        showSkeleton={
          fetchUserListRequestState === RequestState.LOADING ||
          userDetailsRequestState === RequestState.LOADING
            ? true
            : false
        }
        tableMaxHeight={226}
        enableFullScreen={true}
        enableToolBar={true}
        renderToolbar={renderToolbar}
        hideColumnQuery={columnVisibility}
      />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCurrentUserPaymentCard: (userId, paymentCardId, index) => {
      dispatch(
        UserActions.deleteCurrentUserPaymentCard(userId, paymentCardId, index),
      )
    },
  }
}

const mapStateToProps = (state) => ({
  currentUserPaymentCards: UserSelectors.getCurrentUserPaymentCards(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  userDetailsRequestState: UserSelectors.getUserDetailsRequestState(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  admin: AuthSelectors.getUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCardList)
