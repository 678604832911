import PropTypes from 'prop-types'
import React from 'react'
import Styles from './IFText.module.css'
import { isPropsMatch } from 'Utils/PropsMatch'
import IFTextSkeleton from './IFTextSkeleton'

const IFText = React.forwardRef(
  (
    {
      children,
      className,
      style,
      loadSkeleton,
      skeletonWidth,
      skeletonHeight,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        {loadSkeleton ? (
          <IFTextSkeleton
            skeletonWidth={skeletonWidth}
            skeletonHeight={skeletonHeight}
          />
        ) : (
          <p
            {...props}
            ref={ref}
            style={style}
            className={`${Styles.text} ${className}`}
          >
            {children}
          </p>
        )}
      </>
    )
  },
)

IFText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  skeletonWidth: PropTypes.string,
  skeletonHeight: PropTypes.string,
  loadSkeleton: PropTypes.bool,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'className',
    'style',
    'children',
    'skeletonWidth',
    'skeletonHeight',
    'loadSkeleton',
  ])
}

export default React.memo(IFText, shouldSkipRender)
