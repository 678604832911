import React, { useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@mui/material'
import { Colors } from 'Theme'
import { AdminPageTab } from 'Components'
import AdminPageTabsSkeleton from './AdminPageTabsSkeleton'

const tabTitles = {
  Admins: 'Admins',
  Roles: 'Roles',
}

const AdminPageTabs = React.forwardRef(
  ({ onValueChange, isLoading, initialIndex, AdminCount, RoleCount }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)

    const handleChange = (event, newValue) => {
      setSelectedIndex(newValue)
      onValueChange(newValue)
    }

    const resetIndex = () => {
      setSelectedIndex(0)
    }

    useImperativeHandle(ref, () => ({
      resetIndex,
    }))

    return (
      <div>
        {isLoading ? (
          <AdminPageTabsSkeleton tabsCount={Object.keys(tabTitles).length} />
        ) : (
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {Object.entries(tabTitles).map(([key, title], index) => (
              <Tab
                sx={{
                  padding: '0px',
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                key={index}
                label={
                  <AdminPageTab
                    title={title}
                    isSelected={selectedIndex === index}
                    count={key === 'Admins' ? AdminCount : RoleCount}
                  />
                }
              />
            ))}
          </Tabs>
        )}
      </div>
    )
  },
)

AdminPageTabs.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  initialIndex: PropTypes.number,
  AdminCount: PropTypes.number,
  RoleCount: PropTypes.number,
  isLoading: PropTypes.bool,
}

export default AdminPageTabs
