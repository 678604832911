import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import Styles from './AdminProfile.module.css'
import { Colors } from 'Theme'
import LoadingBar from 'react-top-loading-bar'
import {
  IFAvatar,
  IFButton,
  IFDialog,
  IFModal,
  IFSkeleton,
  IFText,
} from 'Components'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'
import moment from 'moment'
import ChangePasswordForm from './ChangePasswordForm'
import ReactCountryFlag from 'react-country-flag'
import RequestState from 'Enums/RequestState'
import { Edit } from '@mui/icons-material'
import { TextField } from '@mui/material'

const AdminProfile = ({
  editingAdminRole,
  logout,
  user,
  changePassword,
  adminDetailsRequestState,
  ChangingPasswordRequestState,
  setCurrentUserNewName,
  ChangingUserNameRequestState,
}) => {
  useEffect(() => {
    if (ChangingPasswordRequestState === RequestState.SUCCEEDED)
      showDialogHandlerPassword.current.dismiss()
  }, [ChangingPasswordRequestState])
  useEffect(() => {
    if (ChangingUserNameRequestState === RequestState.SUCCEEDED) {
      showDialogHandlerUserName.current.dismiss()
      setUserName('')
      setIsDeadButton(true)
    }
  }, [ChangingUserNameRequestState])
  const { t } = useTranslation()
  const logoutHandler = () => {
    logout()
  }
  const showDialogHandlerPassword = useRef(null)
  const showDialogHandlerUserName = useRef(null)
  const [progress, setProgress] = useState(0)
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [userName, setUserName] = useState()
  const [isDeadButton, setIsDeadButton] = useState(true)
  const onSubmitFormHandler = (passwords) => {
    changePassword(passwords)
  }
  useEffect(() => {
    if (isFirstLoaded) {
      if (adminDetailsRequestState === RequestState.LOADING && progress === 0)
        setProgress(progress + 10)

      if (adminDetailsRequestState === RequestState.SUCCEEDED) {
        setProgress(100)
        setIsFirstLoaded(false)
      }
      if (
        adminDetailsRequestState !== RequestState.LOADING &&
        adminDetailsRequestState !== RequestState.UNINITIALIZED &&
        adminDetailsRequestState !== RequestState.SUCCEEDED
      )
        setProgress(100)
    }
  }, [adminDetailsRequestState, isFirstLoaded])
  const handleOnChange = (event) => {
    setUserName(event.target.value)
    if (event.target.value.length > 0) setIsDeadButton(false)
    else setIsDeadButton(true)
  }
  return (
    <div className={Styles.WrapperContainer}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div
        className={Styles.TopContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.UserDataContainer}>
          <div
            className={Styles.UserContainerDiv}
            style={{
              backgroundColor: Colors.white,
            }}
          >
            <div className={Styles.UserAvatarContainer}>
              <div className={Styles.AvatarContainer}>
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <IFSkeleton variant="circular" width="4rem" height="4rem" />
                ) : (
                  <>
                    <IFAvatar name={user.name} size={64} />
                  </>
                )}
                <IFText
                  loadSkeleton={
                    adminDetailsRequestState === RequestState.LOADING ||
                    adminDetailsRequestState === RequestState.UNINITIALIZED
                  }
                  skeletonWidth="8rem"
                  skeletonHeight="1.75rem"
                  className={Styles.UserName}
                  style={{
                    color: Colors.UserPageTextColor,
                  }}
                >
                  {user.name}
                </IFText>
                <Edit
                  className={Styles.EditIcon}
                  fontSize={'small'}
                  onClick={() => {
                    setUserName()
                    showDialogHandlerUserName.current.show()
                  }}
                  style={{
                    color: Colors.PhoneEditIcon,
                  }}
                />
              </div>

              <div className={Styles.ButtonsDiv}>
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <IFSkeleton
                    variant="rectangular"
                    width="4.5rem"
                    height="2.5rem"
                  />
                ) : (
                  <IFButton
                    color={Colors.primary}
                    isFill={false}
                    isLoading={false}
                    onClick={() => {
                      showDialogHandlerPassword.current.show()
                    }}
                    text={t('AdminProfile.ChangePassword')}
                  />
                )}
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <IFSkeleton
                    variant="rectangular"
                    width="4.5rem"
                    height="2.5rem"
                  />
                ) : (
                  <IFButton
                    color={Colors.red}
                    isFill={false}
                    isLoading={false}
                    onClick={logoutHandler}
                    text={t('AdminProfile.logout')}
                  />
                )}
              </div>
            </div>
          </div>
          <Scrollbars autoHide className={Styles.ScrollParent}>
            <div className={Styles.UserInfoContainer}>
              <div className={Styles.AllInfoContainer}>
                <div className={Styles.UserInfo}>
                  <div className={Styles.InfoText}>
                    <div className={Styles.Item}>
                      <div className={Styles.LabelTitle}>
                        <IFText
                          style={{ color: Colors.gray }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                          skeletonWidth="4rem"
                          skeletonHeight="1.5rem"
                        >
                          {t('AdminProfile.Email')}
                        </IFText>
                      </div>
                      <div className={Styles.LabelValue}>
                        <IFText
                          style={{
                            color: Colors.text,
                          }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                        >
                          {user.email}
                        </IFText>
                      </div>
                    </div>

                    <div className={Styles.Item}>
                      <div className={Styles.LabelTitle}>
                        <IFText
                          style={{ color: Colors.gray }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                          skeletonWidth="4rem"
                          skeletonHeight="1.5rem"
                        >
                          {t('AdminProfile.JoinedOn')}
                        </IFText>
                      </div>
                      <div className={Styles.LabelValue}>
                        <IFText
                          style={{
                            color: Colors.text,
                          }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                        >
                          {moment(user.createdAt).format('MMM DD, YYYY LTS')}
                        </IFText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.CountryListDiv}>
              <IFText
                loadSkeleton={
                  adminDetailsRequestState === RequestState.LOADING ||
                  adminDetailsRequestState === RequestState.UNINITIALIZED
                }
                skeletonWidth="4rem"
                skeletonHeight="1.5rem"
                className={Styles.CountryName}
                style={{
                  color: Colors.text,
                  fontWeight: 600,
                }}
              >
                {t('AdminProfile.Countries')}
              </IFText>
              <div className={Styles.CountryList}>
                {user.registeredCountries.map((item, index) => (
                  <div className={Styles.CountryItem} key={index}>
                    {adminDetailsRequestState === RequestState.LOADING ||
                    adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                      <IFSkeleton
                        variant="rectangular"
                        width="1.25rem"
                        height="1.25rem"
                      />
                    ) : (
                      <ReactCountryFlag
                        className={Styles.FlagItem}
                        countryCode={item.code}
                        svg
                      />
                    )}

                    <IFText
                      style={{
                        color: Colors.text,
                      }}
                      loadSkeleton={
                        adminDetailsRequestState === RequestState.LOADING ||
                        adminDetailsRequestState === RequestState.UNINITIALIZED
                      }
                      skeletonWidth="4rem"
                      skeletonHeight="1.5rem"
                    >
                      {item.name}
                    </IFText>
                  </div>
                ))}
              </div>
            </div>
          </Scrollbars>
        </div>
        <IFModal
          title={t('AdminProfile.ChangePassword')}
          open={false}
          ref={showDialogHandlerPassword}
        >
          <ChangePasswordForm
            onSubmitForm={(values) => onSubmitFormHandler(values)}
          />
        </IFModal>
        <IFDialog
          ref={showDialogHandlerUserName}
          maxWidth={'sm'}
          fullWidth={true}
          open={false}
          dismissOnAccept={false}
          title={t('AdminProfile.EditUserNameTitle')}
          buttonAcceptonClick={() => {
            setCurrentUserNewName(userName)
          }}
          buttonDeadCondition={isDeadButton}
          buttonCancelColor={Colors.UserPagePrimary}
          buttonAcceptColor={Colors.UserPagePrimary}
          buttonAcceptText={t('AdminProfile.EditUserNameConfirm')}
          buttonClassName={Styles.ButtonPhoneEditModal}
          contentClassName={Styles.contentClassName}
        >
          <div className={Styles.UserNameField}>
            <TextField
              defaultValue={user.name}
              id="outlined-basic"
              label={t('AdminProfile.EditUserNameLabel')}
              variant="outlined"
              sx={{
                width: '80%',
                '& label.Mui-focused': {
                  color: Colors.primary,
                },
                '& .MuiInputBase-sizeSmall': {
                  fontSize: '0.875rem',
                  fontFamily: 'ProximaNova',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: Colors.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: Colors.primary,
                  },
                },
              }}
              className={Styles.ActionWidth}
              type="text"
              onChange={(event) => {
                handleOnChange(event)
              }}
            />
          </div>
        </IFDialog>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  user: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  adminDetailsRequestState: AuthSelectors.getAdminDetailsRequestState(state),
  ChangingPasswordRequestState:
    AuthSelectors.getChangingPasswordRequestState(state),
  ChangingUserNameRequestState:
    AuthSelectors.getChangingUserNameRequestState(state),
})
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(AuthActions.logout()),
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
    changePassword: (passwords) =>
      dispatch(AuthActions.changePassword(passwords)),
    setCurrentUserNewName: (userName) =>
      dispatch(AuthActions.setCurrentUserNewName(userName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile)
