import { INITIAL_STATE } from './initialState'
import { AdminTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'

export const setFilterText = (state, { text }) => {
  return {
    ...state,
    filterText: text,
  }
}
export const setAdminsFilter = (state, { newFilter }) => {
  return {
    ...state,
    adminsFilters: newFilter,
  }
}
export const clearAdminsFilter = (state) => {
  return {
    ...state,
    adminsFilters: [
      {
        type: IFFIlterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.CHECK,
        title: 'Role',
        data: [],
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.DATE_RANGE,
        title: 'Date',
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const updateAdminsFilterWithRoles = (state, { roles }) => {
  const updatedFilters = state.adminsFilters.map((filter) => {
    if (filter.type === IFFIlterType.CHECK) {
      return {
        ...filter,
        data: roles.map((role) => role.name),
      }
    }
    return filter
  })
  return {
    ...state,
    adminsFilters: updatedFilters,
  }
}

export const fetchAdminListLoading = (state) => {
  return {
    ...state,
    fetchAdminListRequestState: RequestState.LOADING,
  }
}

export const fetchAdminListSuccess = (
  state,
  { offset, admins, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      admins: admins.map((admin) => ({
        ...admin,
        role: admin.role || [],
      })),
      fetchAdminListRequestState: RequestState.SUCCEEDED,
      paginationOffset: nextOffset,
    }
  } else {
    return {
      ...state,
      admins: [
        ...state.admins,
        ...admins.map((admin) => ({
          ...admin,
          role: admin.role || [],
        })),
      ],
      fetchAdminListRequestState: RequestState.SUCCEEDED,
      paginationOffset: nextOffset,
    }
  }
}
export const fetchAdminListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminListRequestState: requestState,
  }
}

export const updateAdminRoleLoading = (state) => {
  return {
    ...state,
    updateAdminRoleRequestState: RequestState.LOADING,
  }
}

export const updateAdminRoleSuccess = (state, { admin }) => {
  const updatedAdmins = state.admins.map((oldAdmin) => {
    return oldAdmin._id === admin.id
      ? {
          ...oldAdmin,
          role: admin.role,
        }
      : oldAdmin
  })

  return {
    ...state,
    updateAdminRoleRequestState: RequestState.SUCCEEDED,
    admins: updatedAdmins,
  }
}

export const updateAdminRoleFail = (state) => {
  return {
    ...state,
    updateAdminRoleRequestState: RequestState.ERROR_UNKNOWN_OCCURRED,
  }
}

export const deleteAdminLoading = (state) => {
  return {
    ...state,
    deleteAdminRequestState: RequestState.LOADING,
  }
}

export const deleteAdminSuccess = (state, { adminId }) => {
  const updatedAdminList = state.admins.filter((admin) => admin._id !== adminId)
  return {
    ...state,
    deleteAdminRequestState: RequestState.SUCCEEDED,
    admins: updatedAdminList,
    count: state.count - 1 < 0 ? 0 : state.count - 1,
  }
}

export const deleteAdminFail = (state) => {
  return {
    ...state,
    deleteAdminRequestState: RequestState.ERROR_UNKNOWN_OCCURRED,
  }
}

export const addAdminLoading = (state) => {
  return {
    ...state,
    isAddAdminLoading: true,
  }
}

export const addAdminSuccess = (state, { admin }) => {
  return {
    ...state,
    admins: [...state.admins, admin],
    isAddAdminLoading: false,
  }
}

export const addAdminFail = (state) => {
  return {
    ...state,
    isAddAdminLoading: false,
  }
}

export const fetchAdminsCountLoading = (state) => {
  return {
    ...state,
    fetchAdminsCountRequestState: RequestState.LOADING,
  }
}

export const fetchAdminsCountSuccess = (state, { count }) => {
  return {
    ...state,
    fetchAdminsCountRequestState: RequestState.SUCCEEDED,
    count: count,
  }
}

export const fetchAdminsCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminsCountRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AdminTypes.SET_FILTER_TEXT]: setFilterText,

  [AdminTypes.FETCH_ADMIN_LIST_LOADING]: fetchAdminListLoading,
  [AdminTypes.FETCH_ADMIN_LIST_SUCCESS]: fetchAdminListSuccess,
  [AdminTypes.FETCH_ADMIN_LIST_ERROR]: fetchAdminListError,
  [AdminTypes.UPDATE_ADMIN_ROLE_LOADING]: updateAdminRoleLoading,
  [AdminTypes.UPDATE_ADMIN_ROLE_SUCCESS]: updateAdminRoleSuccess,
  [AdminTypes.UPDATE_ADMIN_ROLE_FAIL]: updateAdminRoleFail,
  [AdminTypes.DELETE_ADMIN_LOADING]: deleteAdminLoading,
  [AdminTypes.DELETE_ADMIN_SUCCESS]: deleteAdminSuccess,
  [AdminTypes.DELETE_ADMIN_FAIL]: deleteAdminFail,
  [AdminTypes.ADD_ADMIN_LOADING]: addAdminLoading,
  [AdminTypes.ADD_ADMIN_SUCCESS]: addAdminSuccess,
  [AdminTypes.ADD_ADMIN_FAIL]: addAdminFail,
  [AdminTypes.FETCH_ADMINS_COUNT_LOADING]: fetchAdminsCountLoading,
  [AdminTypes.FETCH_ADMINS_COUNT_SUCCESS]: fetchAdminsCountSuccess,
  [AdminTypes.FETCH_ADMINS_COUNT_FAIL]: fetchAdminsCountFail,
  [AdminTypes.SET_ADMINS_FILTER]: setAdminsFilter,
  [AdminTypes.CLEAR_ADMINS_FILTER]: clearAdminsFilter,
  [AdminTypes.UPDATE_ADMINS_FILTER_WITH_ROLES]: updateAdminsFilterWithRoles,
})
