import i18next from 'i18next'
import { put, call } from 'redux-saga/effects'
import {
  setAuthHeader,
  usersApiService,
  handleError,
  adminsApiService,
  handleSuccess,
} from 'Services/ApiService.js'
import AuthActions from 'Stores/Auth/Actions'
import RootActions from 'Stores/RootActions'

export function* login({ email, password }) {
  yield put(AuthActions.loginLoading())
  try {
    const response = yield call(adminsApiService.post, '/login', {
      email: email,
      password: password,
    })
    setAuthHeader(
      response.headers['x-auth-token'],
      response.headers['x-refresh-token'],
    )

    yield put(
      AuthActions.loginSuccess(
        response.headers['x-auth-token'],
        response.headers['x-refresh-token'],
        response.data,
        response.data.registeredCountries,
      ),
    )

    yield* postLogin()
    yield put(AuthActions.loginResetLoading())
  } catch (e) {
    yield put(AuthActions.loginError())
    handleError(e)
  }
}

export function* postLogin() {
  // window.location = '/user'
}

export function* getUser() {
  try {
    const { data } = yield call(usersApiService.get, '/me')
    yield put(AuthActions.getUserSuccess(data))
  } catch (e) {
    yield put(AuthActions.getUserFail())
    handleError(e)
  }
}

export function* logout() {
  try {
    yield call(adminsApiService.post, '/logout')
    // Navigate to login page
    yield put(RootActions.resetAllStores())
    window.location = '/login'
  } catch (e) {
    handleError(e)
  }
}

export function* fetchEditingAdminRole() {
  yield put(AuthActions.fetchEditingAdminRoleLoading())
  try {
    const { data } = yield call(adminsApiService.get, '/myRole')
    yield put(AuthActions.fetchEditingAdminRoleSuccess(data.permissions))
  } catch (e) {
    yield put(AuthActions.fetchEditingAdminRoleError(e))
    handleError(e)
  }
}
export function* changePassword({ passwords }) {
  yield put(AuthActions.changePasswordLoading())
  try {
    yield call(adminsApiService.put, '/me/password', {
      oldPassword: passwords.oldPassword,
      newPassword: passwords.newPassword,
    })
    yield put(AuthActions.changePasswordSuccess())
    handleSuccess(i18next.t('AdminProfile.ChangePasswordSuccessMessage'))
  } catch (e) {
    yield put(AuthActions.changePasswordFail(e))
    handleError(e)
  }
}

export function* fetchAdminDetails() {
  yield put(AuthActions.fetchAdminDetailsLoading())
  try {
    const { data } = yield call(adminsApiService.get, '/me')
    yield put(AuthActions.fetchAdminDetailsSuccess(data))
  } catch (e) {
    yield put(AuthActions.fetchAdminDetailsFail(e))
    handleError(e)
  }
}
export function* setCurrentUserNewName({ userName }) {
  yield put(AuthActions.setCurrentUserNewNameLoading())
  try {
    yield call(adminsApiService.put, `/me/name`, {
      name: userName,
    })
    yield put(AuthActions.setCurrentUserNewNameSuccess(userName))
    handleSuccess(i18next.t('AdminProfile.ChangeUserNameSuccessMessage'))
  } catch (e) {
    yield put(AuthActions.setCurrentUserNewNameFail(e))
    handleError(e)
  }
}
