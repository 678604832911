import PropTypes from 'prop-types'
import styles from './IFAvatar.module.css'
import IFText from 'Components/IFText/IFText'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { strToRGB } from '../../Utils/Calculations'
import { getInitials } from '../../Utils/StringFunctions'

const Container = styled.div`
  width: ${(props) => (props.size ? props.size + 'px' : '50px')};
  height: ${(props) => (props.size ? props.size + 'px' : '50px')};
  background-color: #${(props) => strToRGB(props.name)};
`

const IFAvatar = ({ name, imageURL, size, isRound = true }) => {
  return (
    <Container size={size} name={name} className={styles.Container}>
      <IFText
        style={{
          color: Colors.white,
          fontSize: size ? size * 0.025 + 'rem' : '1.5rem',
        }}
      >
        {getInitials(name)}
      </IFText>
    </Container>
  )
}

IFAvatar.propTypes = {
  name: PropTypes.string,
  imageURL: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRound: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

export default IFAvatar
