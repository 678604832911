import { React, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import styles from './PointsList.module.css'
import RequestState from 'Enums/RequestState'
import { IFSkeleton, IFTable, IFText } from 'Components'
import { useTranslation } from 'react-i18next'
import Colors from 'Theme/Colors'
import { ProgressBar } from 'Components'

import { useMemo } from 'react'
import moment from 'moment'

const PointsList = ({
  userId,
  pointsListPaginationOffset,
  points,
  fetchUserPoints,
  fetchUsersPointsRequestState,
  clearPoints,
  country,
}) => {
  const loadMoreData = () => {
    if (
      fetchUsersPointsRequestState === RequestState.LOADING ||
      pointsListPaginationOffset === null
    )
      return

    fetchUserPoints(userId, pointsListPaginationOffset, country)
  }
  useEffect(() => {
    fetchUserPoints(userId, pointsListPaginationOffset, country)
    return () => {
      clearPoints()
    }
  }, [])

  const { t } = useTranslation()

  const transformedPoints = useMemo(() => {
    return points.map((point) => {
      let expiryDateMoment = moment(point.expiry).format('DD-MM-YYYY')
      let expired = moment().isSameOrAfter(moment(point.expiry)) ? true : false
      let expiryText = expired
        ? t('PointsItem.ExpiredText')
        : t('PointsItem.ExpiresOnText') + expiryDateMoment
      let percentageComplete =
        (parseInt(point.remaining, 10) / parseInt(point.points, 10)) * 100
      const formattedPoints = `${Intl.NumberFormat('en-US', 2).format(
        point.remaining,
      )}/${Intl.NumberFormat('en-US', 2).format(point.points)}`
      let dateCreated = moment(point.createdAt).format('DD-MM-YYYY')

      return {
        ...point,
        expiryStatus: expiryText,
        dateCreated: dateCreated,
        expired: expired,
        formattedPoints,
        percentageComplete,
      }
    })
  }, [points])

  const pointsTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('PointsItem.pointsTableColumns.Name'),
        Cell: ({ row }) => {
          return row.index < transformedPoints.length ? (
            <IFText>{row.original.name}</IFText>
          ) : (
            <IFSkeleton variant="text" width="80%" height={20} />
          )
        },
      },
      {
        accessorKey: 'expiryStatus',
        header: t('PointsItem.pointsTableColumns.Status'),
        Cell: ({ row }) => {
          return row.index < transformedPoints.length ? (
            <IFText>{row.original.expiryStatus}</IFText>
          ) : (
            <IFSkeleton variant="text" width="80%" height={20} />
          )
        },
        muiTableBodyCellProps: ({ row }) => ({
          style: !row.original.expired
            ? { color: Colors.text }
            : { color: Colors.red },
        }),
      },
      {
        accessorKey: 'dateCreated',
        header: t('PointsItem.pointsTableColumns.CreatedOn'),
        Cell: ({ row }) => {
          return row.index < transformedPoints.length ? (
            <IFText>{row.original.dateCreated}</IFText>
          ) : (
            <IFSkeleton variant="text" width="80%" height={20} />
          )
        },
      },
      {
        accessorKey: 'formattedPoints',
        header: t('PointsItem.pointsTableColumns.Balance'),
        muiTableBodyCellProps: ({ row }) => ({
          style: !row.original.expired
            ? { color: Colors.text }
            : { color: Colors.darkGrey },
        }),
        Cell: ({ row }) => {
          return row.index < transformedPoints.length ? (
            <div>
              <IFText style={{ marginBottom: 4 }}>
                {row.original.formattedPoints}
              </IFText>
              <ProgressBar
                completed={row.original.percentageComplete}
                fillColor={
                  !row.original.expired ? Colors.primary : Colors.darkGrey
                }
                height="0.5rem"
                width="80%"
                backgroundColor={Colors.ProgressBar}
              />
            </div>
          ) : (
            <IFSkeleton variant="text" width="80%" height={20} />
          )
        },
      },
    ],
    [transformedPoints],
  )

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )

  return (
    <div className={styles.Container}>
      <IFTable
        columns={pointsTableColumns}
        data={transformedPoints}
        fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        paginationOffset={pointsListPaginationOffset}
        showSkeleton={
          (fetchUsersPointsRequestState === RequestState.LOADING &&
            pointsListPaginationOffset === 0) ||
          (fetchUsersPointsRequestState !== RequestState.SUCCEEDED &&
            !pointsListPaginationOffset)
        }
        tableMaxHeight={400}
        enableHover={false}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserPoints: (userId, offset, country) =>
      dispatch(UserActions.fetchUserPoints(userId, offset, country)),
    clearPoints: () => dispatch(UserActions.clearPoints()),
  }
}

const mapStateToProps = (state) => ({
  points: UserSelectors.getPointsList(state),
  pointsListPaginationOffset:
    UserSelectors.getPointsListPaginationOffset(state),
  fetchUsersPointsRequestState:
    UserSelectors.getFetchUserPointsRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(PointsList)
