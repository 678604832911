import { put, call } from 'redux-saga/effects'
import { rolesApiService, handleError } from 'Services/ApiService.js'
import roleActions from 'Stores/Role/Actions'

export function* fetchRoles() {
  yield put(roleActions.fetchRolesLoading())
  try {
    const { data } = yield call(rolesApiService.get, '/')
    yield put(roleActions.fetchRolesSuccess(data))
  } catch (e) {
    yield put(roleActions.fetchRolesError(e))
    handleError(e)
  }
}

export function* fetchRolesCount() {
  yield put(roleActions.fetchRolesCountLoading())
  try {
    const { data } = yield call(rolesApiService.get, '/count')
    yield put(roleActions.fetchRolesCountSuccess(data.count))
  } catch (e) {
    yield put(roleActions.fetchRolesCountFail(e))
    handleError(e)
  }
}

export function* updateRole({ roleId, newRoleName, newRolePermissions }) {
  yield put(roleActions.updateRoleLoading(roleId))

  try {
    const { data } = yield call(rolesApiService.patch, `/${roleId}`, {
      name: newRoleName,
      permissions: newRolePermissions,
    })

    yield put(roleActions.updateRoleSuccess(data))
  } catch (e) {
    yield put(roleActions.updateRoleFail(roleId))

    handleError(e)
  }
}
export function* createRole({ role }) {
  yield put(roleActions.createRoleLoading())
  try {
    const { data } = yield call(rolesApiService.post, '/', {
      name: role.roleName,
      country: role.country,
      permissions: role.permissions,
    })
    yield put(roleActions.createRoleSuccess(data))
  } catch (e) {
    yield put(roleActions.createRoleFail())
    handleError(e)
  }
}
