import PropTypes from 'prop-types'
import { IFAvatar, IFText } from 'Components'
import Styles from './AdminListItem.module.css'
import { Colors } from 'Theme'

const AdminListItem = ({ name, email }) => {
  return (
    <div className={Styles.AdminItemDiv}>
      <IFAvatar imageURL={name} name={name} size={30} />

      <div className={Styles.AdminItemDetails}>
        <IFText className={Styles.AdminName}>{name}</IFText>
        <IFText
          className={Styles.AdminEmail}
          style={{
            color: Colors.gray,
          }}
        >
          {email}
        </IFText>
      </div>
    </div>
  )
}

AdminListItem.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
}

export default AdminListItem
