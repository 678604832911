import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import TextField from '@mui/material/TextField'
import {
  IFFilter,
  IFButton,
  IFText,
  IFAvatar,
  IFDialog,
  IFSkeleton,
  IFModal,
  FilterNoMatch,
} from 'Components'
import {
  UserList,
  UserListHeader,
  TransactionList,
  PaymentSessionList,
  UserPageTabs,
  PointsList,
  PaymentCardList,
  ChargingTokenList,
} from 'Containers'
import Colors from 'Theme/Colors'
import Styles from './UserPage.module.css'
import Edit from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import InfinityEnums from 'Enums/InfinityEnums'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/system'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Menu, MenuItem, Select } from '@mui/material'
import { Divider } from 'rsuite'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import styled from 'styled-components'
import history from 'history/browser'
import { useLocation } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import ReactFlagsSelect from 'react-flags-select'

var base64 = require('base-64')

const StyledPhoneInput = styled(PhoneInput)`
  & .form-control {
    font-family: ProximaNova !important;
    height: 45px;
    font-size: 14px !important;
    color: ${Colors.text} !important;

    &:hover {
      border-color: ${Colors.primary} !important;
    }

    &:focus {
      border-color: ${Colors.primary} !important;
      box-shadow: 0 0 0 1px ${Colors.primary} !important;
    }
  }

  & .country-list {
    width: 10rem !important;
    margin: 0 !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    font-family: ProximaNova !important;
  }
  & .special-label {
    font-size: 14px !important;
    color: ${Colors.primary} !important;
    font-family: ProximaNova !important;
  }
`
const tabTitles = {
  Transactions: 'Transactions',
  PaymentSessions: 'Payment Sessions',
}

const UserPage = ({
  admin,
  editingAdminRole,
  user,
  transactionsFilter,
  paymentsFilter,
  usersFilter,
  adjustWallet,
  toggleUserIsActive,
  userDetailsRequestState,
  fetchUserList,
  fetchUserTransactionList,
  clearUsers,
  setUsersFilter,
  fetchUserListRequestState,
  fetchUserDetails,
  clearTransactions,
  setUserTransactionsFilter,
  clearUserTransactionsFilter,
  selectedUserId,
  clearCurrentUser,
  setCurrentUserNewPhone,
  fetchUserPaymentSessionList,
  setUserPaymentsFilter,
  clearUserPaymentsFilter,
  clearPaymentSessions,
  usersCount,
  setUserListSelectedIndex,
  defaultUsersFilters,
  selectedUserIndex,
  userList,
  fetchUserTransactionListRequestState,
  fetchPaymentSessionListRequestState,
  defaultTransactionFilters,
  defaultPaymentsFilters,
  defaultUserFiltersCountry,
  setSelectedCountry,
}) => {
  const tabTitlesCards = {
    ChargingTokens: 'Charging Tokens',
    ...(editingAdminRole?.includes(
      InfinityEnums.AdminPermissions.CAN_VIEW_USER_PAYMENT_CARDS,
    ) && { PaymentCards: 'Payment Cards' }),
  }
  const userPageTabsRef = useRef()
  const userPageTabsCardRef = useRef()
  const [selectedDetails, setSelectedDetails] = useState(0)
  const [selectedDetailsCards, setSelectedDetailsCards] = useState(0)
  const { t } = useTranslation()
  const userFilterRef = useRef(null)
  const transactionsFilterRef = useRef(null)
  const paymentsFilterRef = useRef(null)
  const showDialogHandler = useRef(null)
  const showDialogHandlerPhone = useRef(null)
  const showDialogHandlerPoints = useRef(null)
  const showDialogHandlerWallet = useRef(null)
  const [editPhoneVisible, setEditPhoneVisible] = React.useState(false)
  const [newUserPhone, setNewUserPhone] = React.useState(null)
  const [sign, setSign] = useState(t('UserPage.AdjustAmountButtonTextAdd'))
  const [amount, setAmount] = useState()
  const [isDead, setisDead] = React.useState(true)
  const [tablesHeight, setTablesHeight] = React.useState(0)
  const tablesRef = useRef(null)
  const allowedCountries = InfinityEnums.CountryCodes.map(
    (country) => country.code,
  )
  const [country, setCountry] = useState(allowedCountries[0])
  const userListRef = useRef(null)
  const scrollUserList = (index) => {
    userListRef?.current?.scrollToIndex({
      index: index,
      behavior: 'smooth',
      align: 'start',
    })
  }

  const [progress, setProgress] = useState(0)
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (isFirstLoaded) {
      if (fetchUserListRequestState === RequestState.LOADING && progress === 0)
        setProgress(progress + 10)

      if (fetchUserListRequestState === RequestState.SUCCEEDED)
        if (userList.length === 0) {
          setProgress(100)
        } else {
          setProgress(progress + 40)
        }
      if (
        fetchUserListRequestState !== RequestState.LOADING &&
        fetchUserListRequestState !== RequestState.UNINITIALIZED &&
        fetchUserListRequestState !== RequestState.SUCCEEDED
      )
        setProgress(100)
    }
  }, [fetchUserListRequestState, isFirstLoaded])

  useEffect(() => {
    if (isFirstLoaded) {
      if (
        (fetchUserTransactionListRequestState === RequestState.LOADING ||
          fetchPaymentSessionListRequestState === RequestState.LOADING) &&
        progress === 0
      )
        setProgress(progress + 10)
      if (
        fetchUserTransactionListRequestState === RequestState.SUCCEEDED &&
        fetchPaymentSessionListRequestState === RequestState.SUCCEEDED
      ) {
        setProgress(100)
        setIsFirstLoaded(false)
      }
      if (
        fetchUserTransactionListRequestState !== RequestState.LOADING &&
        fetchUserTransactionListRequestState !== RequestState.UNINITIALIZED &&
        fetchUserTransactionListRequestState !== RequestState.SUCCEEDED
      )
        setProgress(100)
    }
  }, [
    fetchUserTransactionListRequestState,
    fetchPaymentSessionListRequestState,
    isFirstLoaded,
  ])
  useEffect(() => {
    if (admin.registeredCountries.length > 1) {
      setUsersFilter(defaultUserFiltersCountry)
    }
    setError(false)
    return () => {
      setError(false)
      clearUsers()
      clearPaymentSessions()
      clearTransactions()
      clearCurrentUser()
      userFilterRef?.current?.clearFilter()
      if (admin.registeredCountries.length > 1) {
        setUsersFilter(defaultUserFiltersCountry)
      } else {
        setUsersFilter(defaultUsersFilters)
      }
      setIsFirstLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (user.changePhoneRequestState == RequestState.SUCCEEDED)
      showDialogHandlerPhone.current.dismiss()
  }, [user.changePhoneRequestState])

  const handleUserFilter = (newFilter) => {
    clearUsers()
    setUserListSelectedIndex(-1)
    setError(false)
    history.push({
      pathname: '/user',
      search: `?filter=${base64.encode(JSON.stringify(newFilter))}`,
    })
    clearCurrentUser()
    setUsersFilter(newFilter)
    fetchUserList(0, () => {})
  }

  let location = useLocation()
  useEffect(() => {
    if (location.pathname === '/user' || location.pathname === '/user/') {
      if (admin.registeredCountries.length > 1) {
        setUsersFilter(defaultUserFiltersCountry)
      } else {
        setUsersFilter(defaultUsersFilters)
      }
      fetchUserList(0, () => {})
      clearUsers()
      clearCurrentUser()
      setError(false)
      userFilterRef?.current?.clearFilter()
    }
  }, [location])

  useEffect(() => {
    if (selectedUserId) {
      clearUserTransactionsFilter()
      clearUserPaymentsFilter()
      fetchUserDetails(selectedUserId)
      setIsFirstLoaded(true)
      setError(false)
      if (admin.registeredCountries.length > 1) {
        setUserTransactionsFilter(defaultTransactionFilters)
        setUserPaymentsFilter(defaultPaymentsFilters)
      }
    }

    if (userPageTabsRef.current) {
      userPageTabsRef.current.resetIndex()
      setSelectedDetails(0)
    }
    if (userPageTabsCardRef.current) {
      userPageTabsCardRef.current.resetIndex()
      setSelectedDetailsCards(0)
    }
  }, [selectedUserId])

  useEffect(() => {
    if (selectedUserId) {
      fetchUserTransactionList(selectedUserId, transactionsFilter, 0)
      setIsFirstLoaded(true)
    }
  }, [transactionsFilter, selectedUserId])

  useEffect(() => {
    if (selectedUserId) {
      fetchUserPaymentSessionList(selectedUserId, paymentsFilter, 0)
      setIsFirstLoaded(true)
    }
  }, [paymentsFilter])

  useEffect(() => {
    fetchUserList(0, (e) => {
      if (e) {
        setError(true)
        setProgress(100)
      } else {
        setError(false)
      }
    })
    setIsFirstLoaded(true)
  }, [clearUsers])

  const handleOnChange = (value, countryObj, e, formattedValue) => {
    if (country === countryObj?.countryCode) {
      setNewUserPhone(formattedValue)
      setisDead(checkPhoneLength(value, countryObj?.countryCode))
    } else {
      setNewUserPhone('')
      setCountry(countryObj?.countryCode)
      setisDead(true)
    }
  }
  const checkPhoneLength = (value, country) => {
    const currentCountry = InfinityEnums.CountryCodes.find(
      (co) => co.code === country,
    )

    if (!currentCountry) {
      return true
    }
    const phoneCodeLength = currentCountry.phone.length
    const inputWithoutPhoneCode = value?.slice(phoneCodeLength)
    const phoneLength = currentCountry.phoneLength
    if (phoneLength.length === 1) {
      return !(inputWithoutPhoneCode.length === phoneLength[0])
    } else if (phoneLength.length === 2) {
      const [inner, outer] = phoneLength
      return !(
        inputWithoutPhoneCode.length >= inner &&
        inputWithoutPhoneCode.length <= outer
      )
    }
  }

  const handleSelectedTab = (index) => {
    setSelectedDetails(index)
  }
  const handleSelectedTabCard = (index) => {
    setSelectedDetailsCards(index)
  }
  const [anchor, setAnchor] = useState(null)

  const menuClickHandler = (event) => {
    setAnchor(event.currentTarget)
  }

  const menuCloseHandler = () => setAnchor(null)
  const deactivateHandler = () => {
    setAnchor(null)
    showDialogHandler.current.show()
  }
  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (tablesRef.current) {
      resizeObserver.observe(tablesRef.current)
    }

    return () => {
      if (tablesRef.current) {
        resizeObserver.unobserve(tablesRef.current)
      }
    }
  }, [])
  const [selected, setSelected] = useState()
  const [wallet, setWallet] = useState()
  useEffect(() => {
    if (user.country?.code) {
      if (
        user.groupedWallets?.some(
          (wallet) => wallet.code === user.country?.code,
        )
      ) {
        setSelected(user.country?.code)
      } else {
        if (user?.groupedWallets) setSelected(user?.groupedWallets[0].code)
      }
    }
  }, [user.country])

  useEffect(() => {
    setWallet(user?.groupedWallets?.find((item) => item.code === selected))
  }, [selected, user])
  useEffect(() => {
    if (user?.groupedWallets?.length > 1) setSelectedCountry(selected)
  }, [selected])
  return (
    <div className={Styles.Container}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div className={Styles.Filter}>
        <IFFilter
          ref={userFilterRef}
          onFilterChange={(newFilter) => {
            if (!isFirstLoaded) {
              handleUserFilter(newFilter)
            }
          }}
          filters={usersFilter}
          textFieldPlaceholder={t('UserPage.UsersFilterPlaceholder')}
        />
      </div>
      {(fetchUserListRequestState === RequestState.SUCCEEDED &&
        usersCount === 0) ||
      error ? (
        <FilterNoMatch
          title={t('UserPage.EmptyUsers')}
          subtitle={t('UserPage.filterNoUsers')}
        />
      ) : (
        <div className={Styles.UsersWrapper}>
          <div className={Styles.UsersContainer}>
            <div className={Styles.UsersList}>
              <div
                className={Styles.ListWrapper}
                style={{ borderColor: Colors.UserPageBackgroundContainer }}
              >
                <div>
                  <UserListHeader />
                </div>
                <Scrollbars autoHide className={Styles.ScrollParent}>
                  <div className={Styles.List}>
                    <UserList reference={userListRef} />
                  </div>
                </Scrollbars>
              </div>
            </div>

            <div
              style={{
                backgroundColor: Colors.UserPageBackground,
                borderColor: Colors.UserPageBackgroundContainer,
              }}
              className={Styles.UserContainer}
            >
              <div className={Styles.UserDataContainer}>
                <div
                  className={Styles.UserContainerDiv}
                  style={{
                    backgroundColor: Colors.white,
                  }}
                >
                  <div className={Styles.UserAvatarContainer}>
                    <div
                      className={Styles.AvatarContainer}
                      onClick={() => {
                        scrollUserList(selectedUserIndex)
                      }}
                    >
                      {fetchUserListRequestState === RequestState.LOADING ||
                      userDetailsRequestState === RequestState.LOADING ? (
                        <IFSkeleton
                          variant="circular"
                          width="64px"
                          height="64px"
                        />
                      ) : (
                        <>
                          <IFAvatar name={user.name} size={64} />
                          <div
                            className={Styles.StatusContainer}
                            style={{
                              backgroundColor: user?.isActive
                                ? Colors.UserActive
                                : Colors.UserBlocked,

                              borderColor: Colors.white,
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className={Styles.NameContainer}>
                      <div className={Styles.NameDiv}>
                        <div
                          onClick={() => {
                            scrollUserList(selectedUserIndex)
                          }}
                        >
                          <IFText
                            loadSkeleton={
                              fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState === RequestState.LOADING
                            }
                            skeletonWidth="8rem"
                            skeletonHeight="1.75rem"
                            className={Styles.UserName}
                            style={{
                              color: Colors.UserPageTextColor,
                            }}
                          >
                            {user.name}
                          </IFText>
                        </div>
                        {editingAdminRole?.includes(
                          InfinityEnums.AdminPermissions
                            .CAN_TOGGLE_USER_ACTIVATION,
                        ) &&
                          (fetchUserListRequestState === RequestState.LOADING ||
                          userDetailsRequestState === RequestState.LOADING ? (
                            <></>
                          ) : (
                            <MoreHorizIcon
                              className={Styles.MoreIcon}
                              onClick={menuClickHandler}
                            />
                          ))}

                        <Menu
                          id="basic-menu"
                          anchorEl={anchor}
                          keepMounted
                          open={Boolean(anchor)}
                          onClose={menuCloseHandler}
                        >
                          <MenuItem
                            onClick={deactivateHandler}
                            sx={{
                              backgroundColor: 'transparent !important',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: `${Colors.HoverDeactivate} !important`,
                              },
                            }}
                          >
                            <IFText
                              style={{
                                color: user.isActive
                                  ? Colors.red
                                  : Colors.UserPagePrimary,
                              }}
                            >
                              {user.isActive
                                ? t('UserPage.DeactivateButton')
                                : t('UserPage.ActivateButton')}
                            </IFText>
                          </MenuItem>
                        </Menu>
                      </div>
                      <div className={Styles.CountryDiv}>
                        {fetchUserListRequestState === RequestState.LOADING ||
                        userDetailsRequestState === RequestState.LOADING ? (
                          <></>
                        ) : (
                          <LocationOnIcon className={Styles.LocationIcon} />
                        )}

                        <IFText
                          className={Styles.countryName}
                          style={{
                            color: Colors.text,
                          }}
                          loadSkeleton={
                            fetchUserListRequestState ===
                              RequestState.LOADING ||
                            userDetailsRequestState === RequestState.LOADING
                          }
                          skeletonWidth="4rem"
                          skeletonHeight="1.5rem"
                        >
                          {user.country?.name}
                        </IFText>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.UserInfoRightContainer}>
                    {user?.groupedWallets?.length > 1 && (
                      <div className={Styles.WalletContainerTitle}>
                        <div>
                          <IFText
                            className={Styles.WalletContainerTitleText}
                            loadSkeleton={
                              fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState === RequestState.LOADING
                            }
                          >
                            {t('UserPage.Wallet')}
                          </IFText>
                        </div>
                        {fetchUserListRequestState === RequestState.LOADING ||
                        userDetailsRequestState === RequestState.LOADING ? (
                          <> </>
                        ) : (
                          <div>
                            <ReactFlagsSelect
                              countries={['EG', 'JO']}
                              customLabels={{ EG: 'EGP', JO: 'JOD' }}
                              showSelectedLabel={false}
                              selectedSize={14}
                              optionsSize={14}
                              fullWidth={false}
                              selected={selected}
                              onSelect={(code) => setSelected(code)}
                              selectButtonClassName={Styles.ReactFlagsSelect}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className={Styles.WalletContainer}>
                      <div className={Styles.WalletItem}>
                        <div className={Styles.PointsItemGroup}>
                          <IFText
                            className={Styles.Amount}
                            loadSkeleton={
                              fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState === RequestState.LOADING
                            }
                            skeletonWidth="3rem"
                          >
                            {wallet?.currency}
                          </IFText>
                          {fetchUserListRequestState === RequestState.LOADING ||
                          userDetailsRequestState === RequestState.LOADING ? (
                            <> </>
                          ) : (
                            editingAdminRole?.includes(
                              InfinityEnums.AdminPermissions.CAN_ADJUST_BALANCE,
                            ) && (
                              <Edit
                                className={Styles.PhoneIcon}
                                fontSize={'small'}
                                onClick={() => {
                                  setAmount()
                                  setSign(
                                    t('UserPage.AdjustAmountButtonTextAdd'),
                                  )
                                  showDialogHandlerWallet.current.show()
                                }}
                                style={{
                                  color: Colors.PhoneEditIcon,
                                }}
                              />
                            )
                          )}
                        </div>
                        <div>
                          <IFText
                            className={Styles.WalletText}
                            loadSkeleton={
                              fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState === RequestState.LOADING
                            }
                            skeletonWidth="3rem"
                          >
                            {wallet?.balance.toFixed(2)}
                          </IFText>
                        </div>
                      </div>
                      <div className={Styles.Divider} />
                      <div className={Styles.WalletItem}>
                        <div className={Styles.PointsItemGroup}>
                          <IFText
                            className={Styles.Amount}
                            loadSkeleton={
                              fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState === RequestState.LOADING
                            }
                            skeletonWidth="3rem"
                          >
                            {t('UserPage.Points')}
                          </IFText>
                          {fetchUserListRequestState === RequestState.LOADING ||
                          userDetailsRequestState === RequestState.LOADING ? (
                            <> </>
                          ) : (
                            <Box
                              className={Styles.InfoDiv}
                              style={{
                                borderColor: Colors.PhoneEditIcon,
                              }}
                              onClick={() => {
                                showDialogHandlerPoints.current.show()
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faInfo}
                                style={{
                                  color: Colors.PhoneEditIcon,
                                }}
                                className={Styles.Info}
                              />
                            </Box>
                          )}
                        </div>
                        <div>
                          <IFText
                            className={Styles.WalletText}
                            loadSkeleton={
                              fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState === RequestState.LOADING
                            }
                            skeletonWidth="3rem"
                          >
                            {Intl.NumberFormat('en-US', 2).format(
                              wallet?.points,
                            )}
                          </IFText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Scrollbars autoHide className={Styles.ScrollParent}>
                  <div className={Styles.UserInfoContainer}>
                    <div className={Styles.AllInfoContainer}>
                      <div className={Styles.UserInfo}>
                        <div className={Styles.InfoText}>
                          <div className={Styles.Item}>
                            <div className={Styles.LabelTitle}>
                              <IFText
                                style={{ color: Colors.gray }}
                                loadSkeleton={
                                  fetchUserListRequestState ===
                                    RequestState.LOADING ||
                                  userDetailsRequestState ===
                                    RequestState.LOADING
                                }
                                skeletonWidth="4rem"
                                skeletonHeight="1.5rem"
                              >
                                {t('UserPage.Email')}
                              </IFText>
                            </div>
                            <div className={Styles.LabelValue}>
                              <IFText
                                style={{
                                  color: Colors.text,
                                }}
                                loadSkeleton={
                                  fetchUserListRequestState ===
                                    RequestState.LOADING ||
                                  userDetailsRequestState ===
                                    RequestState.LOADING
                                }
                              >
                                {user.email}
                              </IFText>
                            </div>
                          </div>
                          <div
                            className={Styles.Item}
                            onMouseEnter={() => {
                              if (
                                editingAdminRole?.includes(
                                  InfinityEnums.AdminPermissions.CAN_EDIT_USERS,
                                )
                              )
                                setEditPhoneVisible(true)
                            }}
                            onMouseLeave={() => {
                              if (
                                editingAdminRole?.includes(
                                  InfinityEnums.AdminPermissions.CAN_EDIT_USERS,
                                )
                              )
                                setEditPhoneVisible(false)
                            }}
                          >
                            <div className={Styles.LabelTitle}>
                              <IFText
                                style={{ color: Colors.gray }}
                                loadSkeleton={
                                  fetchUserListRequestState ===
                                    RequestState.LOADING ||
                                  userDetailsRequestState ===
                                    RequestState.LOADING
                                }
                                skeletonWidth="4rem"
                                skeletonHeight="1.5rem"
                              >
                                {t('UserPage.Phone')}
                              </IFText>
                            </div>
                            <div className={Styles.LabelValue}>
                              <IFText
                                style={{
                                  color: Colors.text,
                                }}
                                loadSkeleton={
                                  fetchUserListRequestState ===
                                    RequestState.LOADING ||
                                  userDetailsRequestState ===
                                    RequestState.LOADING
                                }
                              >
                                {user.phone}
                              </IFText>
                              {fetchUserListRequestState ===
                                RequestState.LOADING ||
                              userDetailsRequestState ===
                                RequestState.LOADING ? (
                                <></>
                              ) : editPhoneVisible ? (
                                <Edit
                                  className={Styles.PhoneIcon}
                                  fontSize={'small'}
                                  onClick={() => {
                                    showDialogHandlerPhone.current.show()
                                    setNewUserPhone('')
                                    setCountry(allowedCountries[0])
                                    setisDead(true)
                                  }}
                                  style={{
                                    paddingLeft: '0.2em',
                                    color: Colors.PhoneEditIcon,
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className={Styles.Item}>
                            <div className={Styles.LabelTitle}>
                              <IFText
                                style={{ color: Colors.gray }}
                                loadSkeleton={
                                  fetchUserListRequestState ===
                                    RequestState.LOADING ||
                                  userDetailsRequestState ===
                                    RequestState.LOADING
                                }
                                skeletonWidth="4rem"
                                skeletonHeight="1.5rem"
                              >
                                {t('UserPage.JoinedOn')}
                              </IFText>
                            </div>
                            <div className={Styles.LabelValue}>
                              <IFText
                                style={{
                                  color: Colors.text,
                                }}
                                loadSkeleton={
                                  fetchUserListRequestState ===
                                    RequestState.LOADING ||
                                  userDetailsRequestState ===
                                    RequestState.LOADING
                                }
                              >
                                {moment(user.createdAt).format(
                                  'MMM DD, YYYY LTS',
                                )}
                              </IFText>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.PaymentTabs}>
                    <UserPageTabs
                      tabTitles={tabTitlesCards}
                      ref={userPageTabsCardRef}
                      onValueChange={(index) => handleSelectedTabCard(index)}
                      isLoading={
                        userDetailsRequestState === RequestState.LOADING ||
                        fetchUserListRequestState === RequestState.LOADING
                      }
                      initialIndex={0}
                    />
                  </div>
                  <div className={Styles.ChargingTokensContainer}>
                    {selectedDetailsCards == 0 && (
                      <ChargingTokenList userId={user.id} />
                    )}
                    {selectedDetailsCards == 1 && (
                      <PaymentCardList userId={user.id} />
                    )}
                  </div>
                  <div className={Styles.TablesContainer}>
                    <div className={Styles.TransactionsContainer}>
                      <UserPageTabs
                        tabTitles={tabTitles}
                        ref={userPageTabsRef}
                        onValueChange={(index) => handleSelectedTab(index)}
                        isLoading={
                          userDetailsRequestState === RequestState.LOADING ||
                          fetchUserListRequestState === RequestState.LOADING
                        }
                        initialIndex={0}
                      />
                      <div ref={transactionsFilterRef}>
                        {selectedDetails == 0 && (
                          <div ref={transactionsFilterRef}>
                            <IFFilter
                              isLoading={
                                fetchUserListRequestState ===
                                RequestState.LOADING
                              }
                              onFilterChange={(newFilter) => {
                                clearTransactions()
                                setUserTransactionsFilter(newFilter)
                              }}
                              textFieldPlaceholder={t(
                                'UserPage.TransactionsFilterPlaceholder',
                              )}
                              filters={transactionsFilter}
                              disableFutureDates={true}
                            />
                          </div>
                        )}
                        {selectedDetails == 1 && (
                          <div ref={paymentsFilterRef}>
                            <IFFilter
                              onFilterChange={(newFilter) => {
                                clearPaymentSessions()
                                setUserPaymentsFilter(newFilter)
                              }}
                              textFieldPlaceholder={t(
                                'UserPage.PaymentsFilterPlaceholder',
                              )}
                              filters={paymentsFilter}
                              disableFutureDates={true}
                              isLoading={
                                fetchUserListRequestState ===
                                RequestState.LOADING
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={Styles.Tables} ref={tablesRef}>
                      {selectedDetails === 0 && (
                        <div className={Styles.Transactions}>
                          <TransactionList
                            userId={user.id}
                            tablesHeight={tablesHeight - 24}
                          />
                        </div>
                      )}
                      {selectedDetails === 1 && (
                        <div className={Styles.PaymentSessions}>
                          <PaymentSessionList
                            userId={user.id}
                            tablesHeight={tablesHeight - 24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
          <IFDialog
            maxWidth={'xs'}
            fullWidth={true}
            ref={showDialogHandler}
            open={false}
            title={
              user.isActive
                ? t('UserPage.DeactivateTitle')
                : t('UserPage.ActivateTitle')
            }
            bodyText={
              user.isActive
                ? t('UserPage.DeactivateBodyText')
                : t('UserPage.ActivateBodyText')
            }
            buttonAcceptonClick={() =>
              toggleUserIsActive(user.id, user.isActive ? false : true)
            }
            buttonCancelColor={Colors.UserPageCancel}
            buttonAcceptColor={
              user.isActive
                ? Colors.UserPageDeactivateButton
                : Colors.UserPageActivateButton
            }
            buttonAcceptText={
              user.isActive
                ? t('UserPage.DeactivateConfirm')
                : t('UserPage.ActivateConfirm')
            }
          />
          <IFDialog
            ref={showDialogHandlerPhone}
            maxWidth={'sm'}
            fullWidth={true}
            open={false}
            dismissOnAccept={false}
            title={t('UserPage.EditPhoneNumberModalTitle')}
            buttonAcceptonClick={() => {
              setCurrentUserNewPhone(user.id, newUserPhone)
              setNewUserPhone('')
              setCountry(allowedCountries[0])
              setisDead(true)
            }}
            buttonDeadCondition={isDead}
            buttonCancelColor={Colors.UserPagePrimary}
            buttonAcceptColor={Colors.UserPagePrimary}
            buttonAcceptText={t('UserPage.EditPhoneNumberModalAcceptText')}
            buttonClassName={Styles.ButtonPhoneEditModal}
            contentClassName={Styles.contentClassName}
          >
            <div className={Styles.TextPhoneFiled}>
              <StyledPhoneInput
                name="phoneNumber"
                placeholder="Phone number"
                type="text"
                country={country}
                onlyCountries={allowedCountries}
                value={newUserPhone}
                countryCodeEditable={false}
                autoFormat={false}
                onChange={(value, countryObj, e, formattedValue) => {
                  handleOnChange(value, countryObj, e, formattedValue)
                }}
              />
            </div>
          </IFDialog>
          <IFModal
            ref={showDialogHandlerWallet}
            title={t('UserPage.AdjustTitle')}
            dismissOnAccept={false}
            maxWidth="xs"
            dialogContentStyle={{
              maxHeight: '480px',
              minHeight: 'auto',
            }}
            fullWidth={true}
            open={false}
          >
            <div className={Styles.AdjustWallet}>
              <div className={Styles.AdjustWalletItem}>
                <div className={Styles.DetailWalletModified}>
                  <div className={Styles.LabelTitleWallet}>
                    <IFText
                      className={Styles.DetailTextWallet}
                      style={{ color: Colors.DetailPanel }}
                    >
                      {t('UserPage.AmountTitle')}
                    </IFText>
                  </div>
                  <div className={Styles.LabelValueWallet}>
                    <IFText className={Styles.DetailTextWallet}>
                      {wallet?.balance.toFixed(2)} {wallet?.currency}
                    </IFText>
                  </div>
                </div>
                <div className={Styles.DetailWallet}>
                  <div className={Styles.LabelTitleWallet}>
                    <IFText
                      className={Styles.DetailTextWallet}
                      style={{ color: Colors.DetailPanel }}
                    >
                      {t('UserPage.ActionTitle')}
                    </IFText>
                  </div>
                  <div className={Styles.LabelValueWallet}>
                    <Select
                      sx={{
                        '&.MuiInputBase-root': {
                          '&:hover': {
                            borderColor: Colors.primary,
                          },
                          '&.Mui-focused': {
                            borderColor: Colors.primary,
                          },
                        },
                        '&.MuiOutlinedInput-root': {
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.primary,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.primary,
                          },
                        },
                      }}
                      size="small"
                      className={Styles.ActionWidth}
                      variant="outlined"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sign}
                      onChange={(event) => {
                        setSign(event.target.value)
                      }}
                    >
                      <MenuItem value={t('UserPage.AdjustAmountButtonTextAdd')}>
                        <IFText className={Styles.DetailTextWallet}>
                          {t('UserPage.AdjustAmountButtonTextAdd')}
                        </IFText>
                      </MenuItem>
                      <MenuItem
                        value={t('UserPage.AdjustAmountButtonTextSubtract')}
                      >
                        <IFText className={Styles.DetailTextWallet}>
                          {t('UserPage.AdjustAmountButtonTextSubtract')}
                        </IFText>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={Styles.DetailWallet}>
                  <div className={Styles.LabelTitleWallet}>
                    <IFText
                      className={Styles.DetailTextWallet}
                      style={{ color: Colors.DetailPanel }}
                    >
                      {t('UserPage.Amount')}
                    </IFText>
                  </div>
                  <div className={Styles.LabelValueWallet}>
                    <TextField
                      size="small"
                      sx={{
                        '& .MuiInputBase-sizeSmall': {
                          fontSize: '14px',
                          fontFamily: 'ProximaNova',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: Colors.primary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: Colors.primary,
                          },
                        },
                      }}
                      className={Styles.ActionWidth}
                      type="number"
                      placeholder="0.00"
                      onChange={(event) => setAmount(event.target.value)}
                    />
                  </div>
                </div>
                <Divider className={Styles.DividerWallet} />
                <div className={Styles.DetailWallet}>
                  <div className={Styles.LabelTitleWallet}>
                    <IFText
                      className={Styles.DetailTextWallet}
                      style={{ color: Colors.DetailPanel }}
                    >
                      {t('UserPage.AdjustedAmount')}
                    </IFText>
                  </div>
                  <div className={Styles.LabelValueWallet}>
                    <IFText className={Styles.DetailTextWallet}>
                      {sign === t('UserPage.AdjustAmountButtonTextAdd')
                        ? amount
                          ? (
                              parseFloat(wallet?.balance.toFixed(2), 10) +
                              parseFloat(amount, 10)
                            )
                              .toFixed(2)
                              .toLocaleString()
                          : wallet?.balance.toFixed(2)
                        : amount
                        ? (
                            parseFloat(wallet?.balance.toFixed(2), 10) -
                            parseFloat(amount, 10)
                          )
                            .toFixed(2)
                            .toLocaleString()
                        : wallet?.balance.toFixed(2)}{' '}
                      {wallet?.currency}
                    </IFText>
                  </div>
                </div>
              </div>
              <div className={Styles.ActionButton}>
                <IFButton
                  color={Colors.UserPagePrimary}
                  text={t('UserPage.Adjust')}
                  isLoading={false}
                  isDead={amount ? false : true}
                  onClick={() => {
                    setAmount()
                    setSign(t('UserPage.AdjustAmountButtonTextAdd'))
                    showDialogHandlerWallet.current.dismiss()
                    if (sign == t('UserPage.AdjustAmountButtonTextAdd')) {
                      adjustWallet(user.id, amount, wallet?.country)
                    } else {
                      adjustWallet(user.id, -amount, wallet?.country)
                    }
                  }}
                />
              </div>
            </div>
          </IFModal>
          <IFModal
            ref={showDialogHandlerPoints}
            dismissOnAccept={false}
            title={t('UserPage.PointsInfoModalTitle')}
            maxWidth="md"
            dialogContentStyle={{
              maxHeight: '480px',
              minHeight: 'auto',
            }}
            fullWidth={true}
            open={false}
          >
            <Scrollbars
              autoHide
              style={{ width: '100%', height: '100%' }}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={480}
            >
              <PointsList userId={user.id} country={wallet?.country} />
            </Scrollbars>
          </IFModal>
        </div>
      )}
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUserList: (paginationOffset, onError, shouldShowError) =>
      dispatch(
        UserActions.fetchUserList(paginationOffset, onError, shouldShowError),
      ),
    clearUsers: () => dispatch(UserActions.clearUsers()),
    setUsersFilter: (newFilter) =>
      dispatch(UserActions.setUsersFilter(newFilter)),
    adjustWallet: (id, amount, country) =>
      dispatch(UserActions.setCurrentUserNewBalance(id, amount, country)),
    toggleUserIsActive: (id, isActive) =>
      dispatch(UserActions.toggleCurrentUserIsActive(id, isActive)),
    fetchUserDetails: (userId) =>
      dispatch(UserActions.fetchUserDetails(userId)),
    fetchUserTransactionList: (userId, filter, offset, shouldShowError) =>
      dispatch(
        UserActions.fetchUserTransactionList(
          userId,
          filter,
          offset,
          shouldShowError,
        ),
      ),
    clearTransactions: () => dispatch(UserActions.clearTransactions()),
    setUserTransactionsFilter: (newFilter) =>
      dispatch(UserActions.setUserTransactionsFilter(newFilter)),
    clearCurrentUser: () => dispatch(UserActions.clearCurrentUser()),
    clearUserTransactionsFilter: () =>
      dispatch(UserActions.clearUserTransactionsFilter()),
    setCurrentUserNewPhone: (id, newPhone) =>
      dispatch(UserActions.setCurrentUserNewPhone(id, newPhone)),
    fetchUserPaymentSessionList: (userId, filter, offset, shouldShowError) =>
      dispatch(
        UserActions.fetchUserPaymentSessionList(
          userId,
          filter,
          offset,
          shouldShowError,
        ),
      ),
    clearPaymentSessions: () => dispatch(UserActions.clearPaymentSessions()),
    clearPoints: () => dispatch(UserActions.clearPoints()),
    setUserPaymentsFilter: (newFilter) =>
      dispatch(UserActions.setUserPaymentsFilter(newFilter)),
    clearUserPaymentsFilter: () =>
      dispatch(UserActions.clearUserPaymentsFilter()),
    setUserListSelectedIndex: (index) =>
      dispatch(UserActions.setUserListSelectedIndex(index)),
    setSelectedCountry: (newCountry) =>
      dispatch(UserActions.setSelectedCountry(newCountry)),
  }
}

const mapStateToProps = (state) => ({
  transactionsFilter: UserSelectors.getTransactionsFilters(state),
  paymentsFilter: UserSelectors.getPaymentsFilters(state),
  usersFilter: UserSelectors.getUsersFilters(state),
  user: UserSelectors.getCurrentUser(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  userDetailsRequestState: UserSelectors.getUserDetailsRequestState(state),
  selectedUserId: UserSelectors.getSelectedUserId(state),
  admin: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  points: UserSelectors.getPointsList(state),
  usersCount: UserSelectors.getUsersCount(state),
  defaultUsersFilters: UserSelectors.getDefaultUsersFilters(state),
  selectedUserIndex: UserSelectors.getSelectedUserIndex(state),
  userList: UserSelectors.getUserList(state),
  fetchUserTransactionListRequestState:
    UserSelectors.getFetchUserTransactionListRequestState(state),
  fetchPaymentSessionListRequestState:
    UserSelectors.getFetchUserPaymentSessionListRequestState(state),
  defaultTransactionFilters: UserSelectors.getDefaultTransactionsFilters(state),
  defaultPaymentsFilters: UserSelectors.getDefaultPaymentsFilters(state),
  defaultUserFiltersCountry: UserSelectors.getDefaultUserFiltersCountry(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
