import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Styles from './SideNavBar.module.css'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined'
import InfinityEnums from 'Enums/InfinityEnums'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import { useLocation, useNavigate } from 'react-router-dom'
import history from 'history/browser'
import IFAvatar from 'Components/IFAvatar/IFAvatar'
import logo from 'Assets/Images/logoColor.png'
import logoName from 'Assets/Images/logoName.png'
import { alpha } from '@mui/system'
import { Colors } from 'Theme'
import IFText from 'Components/IFText/IFText'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const displayedPages = [
  {
    icon: <PeopleAltOutlinedIcon />,
    text: 'Users',
    endpoint: '/user',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_USERS,
  },
  {
    icon: <LockPersonOutlinedIcon />,
    text: 'Admins',
    endpoint: '/admin',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_ADMINISTRATION,
  },
  {
    icon: <NotificationsNoneOutlinedIcon />,
    text: 'Notifications',
    endpoint: '/pushNotifications',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
  },
  {
    icon: <LocalAtmOutlinedIcon />,
    text: 'Transactions',
    endpoint: '/transactions',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_TRANSACTIONS,
  },
  {
    icon: <CardGiftcardOutlinedIcon />,
    text: 'Vouchers',
    endpoint: '/vouchers',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_VOUCHERS,
  },
]
const drawerWidth = 184

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    paddingBottom: '16px',
    display: 'flex !important',
    flexDirection: 'column  !important',
    justifyContent: 'space-between  !important',
    position: 'static',
    borderColor: Colors.UserPageBackgroundContainer,
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}))

const getSelectedIndex = (pathname, newPages) => {
  const slashArray = pathname.split('/')
  const result = newPages.findIndex(
    (page) => page.endpoint === '/' + slashArray[1],
  )
  return result
}
const SideNav = ({ editingAdminRole, user }) => {
  useSelector((state) => state.secure.authState.user.name)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const handleDrawer = () => {
    setOpen(!open)
  }
  const [newPages, setNewPages] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(
    getSelectedIndex(window.location.pathname, newPages),
  )
  const navigate = useNavigate()
  let location = useLocation()
  useEffect(() => {
    setSelectedIndex(getSelectedIndex(location.pathname, newPages))
  }, [location])
  useEffect(() => {
    if (!editingAdminRole || editingAdminRole.length === 0) return
    else {
      checkPermissions()
    }
  }, [editingAdminRole])

  const checkPermissions = () => {
    setNewPages([])
    let tempPages = []
    for (let page of displayedPages) {
      if (page.role && editingAdminRole.some((role) => role === page.role))
        tempPages.push(page)
    }
    setNewPages(tempPages)
    setSelectedIndex(getSelectedIndex(window.location.pathname, tempPages))
  }
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <div className={Styles.SideNavBarWrappingDiv}>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          <DrawerHeader sx={{ paddingTop: '16px' }}>
            <div className={Styles.Drawerheader}>
              <img className={Styles.IconImg} src={logo} alt="logo" />
              {open && (
                <img className={Styles.NameIconImg} src={logoName} alt="logo" />
              )}
            </div>
          </DrawerHeader>
          <List sx={{ padding: '8px', paddingTop: '16px' }}>
            {newPages.map((page, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: 'block', paddingBottom: '4px' }}
              >
                <ListItemButton
                  disableRipple
                  onClick={() => {
                    setSelectedIndex(index)
                    history.push({ pathname: page['endpoint'] })
                    navigate(0)
                  }}
                  sx={[
                    {
                      minHeight: 48,
                      borderRadius: '5px',
                      backgroundColor:
                        index === selectedIndex ? Colors.primary : Colors.white,

                      '&:hover': {
                        backgroundColor:
                          index === selectedIndex
                            ? Colors.primary
                            : Colors.UserListItemHoverBackground,
                      },
                      '&:active': {
                        backgroundColor: alpha(Colors.primary, 0.3),
                      },
                    },
                    open
                      ? {
                          justifyContent: 'initial',
                        }
                      : {
                          justifyContent: 'center',
                        },
                  ]}
                >
                  <ListItemIcon
                    style={{
                      color:
                        selectedIndex === index
                          ? Colors.white
                          : alpha(Colors.black, 0.5),
                    }}
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: 'center',
                      },
                      open
                        ? {
                            mr: 3,
                          }
                        : {
                            mr: 'auto',
                          },
                    ]}
                  >
                    {page['icon']}
                  </ListItemIcon>
                  {open && (
                    <IFText
                      style={{
                        color:
                          index === selectedIndex ? Colors.white : Colors.text,
                      }}
                    >
                      {page['text']}
                    </IFText>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>

        <DrawerHeader
          className={Styles.DrawerheaderBottom}
          style={{
            backgroundColor:
              selectedIndex === -1 ? Colors.UserListItemHoverBackground : '',
          }}
          onClick={() => {
            setSelectedIndex()
            history.push({ pathname: '/adminProfile' })
            navigate(0)
          }}
        >
          <div className={Styles.UserContainer}>
            <div className={Styles.HeaderBar}>
              <IFAvatar name={user.name} size={40} />
            </div>
            {open && (
              <div className={Styles.UserDiv}>
                <IFText> {t('SideNavBar.AdminProfile')}</IFText>
              </div>
            )}
          </div>
        </DrawerHeader>
      </Drawer>
      <div className={Styles.ArrowDiv}>
        <IconButton
          disableRipple
          onClick={handleDrawer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={Styles.ArrowButton}
          style={{
            visibility: isHovered ? 'visible' : 'hidden',
            opacity: isHovered ? '1' : '0',
            transition: 'visibility 0s, opacity 0.3s ease',
            backgroundColor: Colors.primary,
          }}
        >
          {open ? (
            <ChevronLeftIcon
              fontSize="12px"
              className={Styles.ArrowIcon}
              style={{ color: Colors.white }}
            />
          ) : (
            <ChevronRightIcon
              fontSize="12px"
              className={Styles.ArrowIcon}
              style={{ color: Colors.white }}
            />
          )}
        </IconButton>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})
function mapDispatchToProps(dispatch) {
  return {
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)
