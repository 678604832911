import { React } from 'react'
import PropTypes from 'prop-types'
import IFSkeleton from '../IFSkeleton/IFSkeleton'
import styles from './IFTextSkeleton.module.css'

const IFTextSkeleton = ({
  skeletonWidth = '100%',
  skeletonHeight = '100%',
}) => {
  return (
    <IFSkeleton
      variant="text"
      className={styles.text}
      style={{ width: skeletonWidth, height: skeletonHeight }}
    />
  )
}

IFTextSkeleton.propTypes = {
  skeletonWidth: PropTypes.string,
  skeletonHeight: PropTypes.string,
}

export default IFTextSkeleton
