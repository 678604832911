import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  IFAvatar,
  IFTable,
  IFText,
  IFsvg,
  IFTooltipIconsLoading,
  IFButton,
} from 'Components'
import { Colors } from 'Theme'
import Styles from './AdminList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Skeleton from '@mui/material/Skeleton'
import {
  MenuItem,
  Select,
  FormControl,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material'
import IFDialog from 'Components/IFDialog/IFDialog'
import { capitalizeFirstLetter, formatDate } from '../../Utils/StringFunctions'
import ReportIcon from '@mui/icons-material/Report'
import RoleSelectors from 'Stores/Role/Selectors'
import RoleActions from 'Stores/Role/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import EditIcon from '@mui/icons-material/Edit'
import AdminPermissions from 'Enums/InfinityEnums'

const DeleteIcon = styled.div`
color: ${Colors.AdminListDeleteIcon};
  }
`

const StyledSelect = styled(Select)`
  && {
    font-family: 'ProximaNova';
    font-size: 14px;
    color: ${Colors.textColor};
    height: 30px;
    padding: 8px 8px;
  }
`

const AdminList = ({
  admins,
  adminFilters,
  fetchAdminList,
  paginationOffset,
  fetchAdminListRequestState,
  deleteAdmin,
  roles,
  updateAdminRole,
  updateAdminRoleRequestState,
  deleteAdminRequestState,
  fetchRoles,
  loggedInAdmin,
}) => {
  const { t } = useTranslation()
  const [editingRows, setEditingRows] = useState(
    Array(admins.length).fill(false),
  )
  const [loadingRows, setLoadingRows] = useState(
    Array(admins.length).fill(false),
  )
  const confirmDeleteDialog = useRef()
  const [newRole, setNewRole] = useState('')
  const [deleteAdminId, setDeleteAdminId] = useState(null)
  const [registeredCountries, setRegisteredCountries] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
  })
  const [currentAdminCountries, setCurrentAdminCountries] = useState([])
  const canEditAdmin = loggedInAdmin.role.permissions.includes(
    AdminPermissions.AdminPermissions.CAN_EDIT_ADMINS,
  )

  useEffect(() => {
    if (loggedInAdmin?.registeredCountries?.length > 1) {
      setRegisteredCountries(true)
    }
    fetchRoles()
  }, [loggedInAdmin])

  useEffect(() => {
    setColumnVisibility({
      country: registeredCountries,
    })
  }, [registeredCountries])

  const handleRoleChange = (event) => {
    setNewRole(event.target.value)
  }
  const handleEditClick = (rowIndex) => {
    const roleObj = roles.find(
      (role) => role.name === admins[rowIndex].role.name,
    )
    setEditingRows(editingRows.map((_, index) => index === rowIndex))
    setCurrentAdminCountries(admins[rowIndex].registeredCountries || [])
    setNewRole(roleObj?.id || '')
  }

  const handleSaveRole = (rowIndex) => {
    const adminId = admins[rowIndex]._id
    const adminRole = admins[rowIndex].role.id
    if (adminRole === newRole) {
      setEditingRows(editingRows.map(() => false))
      return
    }
    setLoadingRows(loadingRows.map((_, index) => index === rowIndex))
    updateAdminRole(adminId, newRole)
  }
  const handleSaveRoleRef = useRef(handleSaveRole)
  useEffect(() => {
    handleSaveRoleRef.current = handleSaveRole
  })
  useEffect(() => {
    if (updateAdminRoleRequestState === RequestState.SUCCEEDED) {
      setEditingRows(editingRows.map(() => false))

      setLoadingRows(loadingRows.map(() => false))
    }
    if (updateAdminRoleRequestState === RequestState.ERROR_UNKNOWN_OCCURRED) {
      setLoadingRows(loadingRows.map(() => false))
    }
  }, [updateAdminRoleRequestState])

  useEffect(() => {
    if (deleteAdminRequestState === RequestState.SUCCEEDED) {
      confirmDeleteDialog.current.dismiss()
    }
  }, [deleteAdminRequestState])

  const handleCancelEdit = () => {
    setEditingRows(editingRows.map(() => false))
  }

  const handleDeleteAdmin = () => {
    deleteAdmin(deleteAdminId)

    const adminIndex = admins.findIndex((admin) => admin._id === deleteAdminId)

    if (adminIndex !== -1) {
      setEditingRows((prevState) =>
        prevState.filter((_, index) => index !== adminIndex),
      )
      setLoadingRows((prevState) =>
        prevState.filter((_, index) => index !== adminIndex),
      )
    }

    setDeleteAdminId(null)
  }
  const onDeleteClickHandler = (index) => {
    setDeleteAdminId(admins[index]._id)
    confirmDeleteDialog.current.show()
  }
  useEffect(() => {
    setEditingRows((prevState) => {
      if (prevState.length > admins.length) {
        return prevState.slice(0, admins.length)
      } else {
        return [
          ...prevState,
          ...Array(admins.length - prevState.length).fill(false),
        ]
      }
    })

    setLoadingRows((prevState) => {
      if (prevState.length > admins.length) {
        return prevState.slice(0, admins.length)
      } else {
        return [
          ...prevState,
          ...Array(admins.length - prevState.length).fill(false),
        ]
      }
    })
  }, [admins])

  const loadMoreData = () => {
    if (
      fetchAdminListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchAdminList(adminFilters, paginationOffset, failedRequest)
  }
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchAdminListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchAdminListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchAdminListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchAdminListRequestState])
  const handleRetry = () => {
    fetchAdminList(adminFilters, paginationOffset)
  }
  const adminTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <div className={Styles.SkeletonDiv}>
              <Skeleton
                variant="text"
                width="110px"
                animation="none"
                height={20}
                style={{ Left: '40px' }}
              />
            </div>
          ) : (
            <div className={Styles.HeaderAdminName}>{t('AdminList.Name')}</div>
          ),
        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div className={Styles.AdminNameColumn}>
              <div className={Styles.AdminNameAvatar}>
                <IFAvatar
                  imageURL={row.original.avatarURL}
                  name={row.original.name}
                  size={40}
                />
              </div>
              <div className={Styles.AdminNameString}>
                <IFText>{capitalizeFirstLetter(row.original.name)}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'email',
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Email')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div>
              <IFText>{capitalizeFirstLetter(row.original.email)}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'country',
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('Country')}</IFText>
          ),
        Cell: ({ row }) => {
          if (
            row.index < admins.length &&
            row.original.registeredCountries.length > 0
          ) {
            const countryCodes = row.original.registeredCountries
              .map((country) => country.name)
              .join(', ')
            return (
              <div className={Styles.AdminColumnEmail}>
                <IFText>{countryCodes}</IFText>
              </div>
            )
          } else if (failedRequest) {
            return <></>
          } else {
            return (
              <Skeleton
                variant="text"
                width="60%"
                animation="none"
                height={20}
              />
            )
          }
        },
      },
      {
        accessorKey: 'createdOn',
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <div className={Styles.AdminColumnCreatedOn}>
              {t('AdminList.CreatedOn')}
            </div>
          ),

        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div className={Styles.AdminColumnCreatedOn}>
              <IFText>{formatDate(row.original.createdAt)}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },

      {
        accessorKey: 'role',
        header:
          fetchAdminListRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Role')}</IFText>
          ),
        muiTableBodyCellProps: {
          sx: {
            overflow: 'visible',
          },
        },
        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div>
              {editingRows[row.index] ? (
                <div className={Styles.EditRole}>
                  <FormControl
                    className={Styles.SelectRole}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: Colors.primary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: Colors.primary,
                        },
                      },
                    }}
                    fullWidth
                  >
                    <StyledSelect
                      labelId="role-select-label"
                      value={newRole}
                      onChange={handleRoleChange}
                    >
                      {roles
                        .filter((roleObj) =>
                          currentAdminCountries.some(
                            (country) => country._id === roleObj.country.id,
                          ),
                        )
                        .map((filteredRole) => (
                          <MenuItem
                            key={filteredRole.id}
                            value={filteredRole.id}
                          >
                            {filteredRole.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                </div>
              ) : (
                <IFText>{row.original.role.name}</IFText>
              )}
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [
      admins,
      fetchAdminListRequestState,
      editingRows,
      loadingRows,
      newRole,
      roles,
      updateAdminRoleRequestState,
      failedRequest,
    ],
  )

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const renderRowActions = ({ row }) => {
    if (Object.keys(row.original).length > 0) {
      return (
        <Box>
          {editingRows[row.index] ? (
            <div className={Styles.AdminTableIcons}>
              <IconButton>
                <IFTooltipIconsLoading
                  isLoading={loadingRows[row.index]}
                  Icon={IFsvg.DoneBtn}
                  FilledIcon={IFsvg.DoneBtn}
                  size={26}
                  animationDisabled={false}
                  title={t('AdminList.Save')}
                  onClick={() => handleSaveRoleRef.current(row.index)}
                />
              </IconButton>

              <Tooltip title={t('AdminList.deleteModalCancel')}>
                <IconButton onClick={handleCancelEdit}>
                  <IFsvg.CloseBtn
                    height={24}
                    width={24}
                    fill={Colors.primary}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className={Styles.AdminTableIcons}>
              <Tooltip title={t('AdminList.Edit')}>
                <IconButton onClick={() => handleEditClick(row.index)}>
                  <EditIcon sx={{ color: Colors.gray }} />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('AdminList.deleteModalConfirm')}>
                <IconButton
                  onClick={() => {
                    onDeleteClickHandler(row.index)
                  }}
                >
                  <DeleteIcon className={Styles.DeleteIcon}>
                    <FontAwesomeIcon icon={faTrash} />
                  </DeleteIcon>
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Box>
      )
    }

    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={handleRetry}
        />
      )
    }
  }
  return (
    <div>
      {((fetchAdminListRequestState !== RequestState.SUCCEEDED &&
        !paginationOffset) ||
        admins) && (
        <>
          <IFTable
            columns={adminTableColumns}
            data={admins}
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            paginationOffset={paginationOffset}
            filters={adminFilters}
            tableMaxHeight={720}
            showSkeleton={
              (fetchAdminListRequestState === RequestState.LOADING &&
                paginationOffset === 0) ||
              (fetchAdminListRequestState !== RequestState.SUCCEEDED &&
                !paginationOffset)
            }
            hideColumnQuery={columnVisibility}
            tableRequestState={fetchAdminListRequestState}
            enableEditing={canEditAdmin}
            renderRowActions={canEditAdmin && renderRowActions}
          />
        </>
      )}
      <IFDialog
        ref={confirmDeleteDialog}
        style={{ margin: '15em' }}
        open={false}
        title={t('AdminList.deleteModalTitle')}
        bodyText={t('AdminList.deleteModalText')}
        buttonAcceptonClick={handleDeleteAdmin}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('AdminList.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={deleteAdminRequestState === RequestState.LOADING}
        dismissOnAccept={false}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateAdminRole: (adminId, newRoleValue) =>
      dispatch(AdminActions.updateAdminRole(adminId, newRoleValue)),
    deleteAdmin: (adminId) => dispatch(AdminActions.deleteAdmin(adminId)),
    fetchAdminList: (filter, offset, shouldShowError) =>
      dispatch(AdminActions.fetchAdminList(filter, offset, shouldShowError)),
    fetchRoles: () => dispatch(RoleActions.fetchRoles()),
  }
}
const mapStateToProps = (state) => ({
  roles: RoleSelectors.getRoles(state),
  admins: AdminSelectors.getAdminList(state),
  fetchAdminListRequestState:
    AdminSelectors.getfetchAdminListRequestState(state),
  paginationOffset: AdminSelectors.getAdminListPaginationOffset(state),
  adminFilters: AdminSelectors.getAdminsFilters(state),
  updateAdminRoleRequestState:
    AdminSelectors.getupdateAdminRoleRequestState(state),
  deleteAdminRequestState: AdminSelectors.getDeleteAdminRequestState(state),
  loggedInAdmin: AuthSelectors.getUser(state),
})
AdminList.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  updateAdminRole: PropTypes.func,
  deleteAdmin: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
