import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  login: ['email', 'password'],
  loginLoading: null,
  loginResetLoading: null,
  loginSuccess: ['token', 'refreshToken', 'user'],
  loginError: null,

  getUser: null,
  getUserSuccess: ['user'],
  getUserFail: null,

  setAuthToken: ['token'],
  logout: null,

  fetchEditingAdminRole: null,
  fetchEditingAdminRoleLoading: null,
  fetchEditingAdminRoleSuccess: ['role'],
  fetchEditingAdminRoleError: ['error'],

  changePassword: ['passwords'],
  changePasswordLoading: null,
  changePasswordSuccess: null,
  changePasswordFail: ['error'],

  fetchAdminDetails: null,
  fetchAdminDetailsLoading: null,
  fetchAdminDetailsSuccess: ['admin'],
  fetchAdminDetailsFail: ['error'],

  setCurrentUserNewName: ['userName'],
  setCurrentUserNewNameSuccess: ['userName'],
  setCurrentUserNewNameFail: ['error'],
  setCurrentUserNewNameLoading: null,
})

export const AuthTypes = Types
export default Creators
