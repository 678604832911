import { React } from 'react'
import PropTypes from 'prop-types'
import IFSkeleton from '../IFSkeleton/IFSkeleton'
import styles from './IFButtonSkeleton.module.css'

const IFButtonSkeleton = ({ size }) => {
  return (
    <IFSkeleton
      variant="rectangular"
      className={styles.button}
      style={
        size === 'xsm'
          ? {
              height: '24px',
            }
          : size === 'sm'
          ? {
              height: '32px',
            }
          : size === 'md'
          ? {
              height: '40px',
            }
          : {
              height: '48px',
            }
      }
    />
  )
}

IFButtonSkeleton.propTypes = {
  size: PropTypes.string.isRequired,
}

export default IFButtonSkeleton
