import PropTypes from 'prop-types'
import Styles from './AdminPageTab.module.css'
import { IFText } from 'Components'
import { Colors } from 'Theme'
const AdminPageTab = ({ title, count, isSelected }) => {
  return (
    <div className={Styles.Container}>
      <IFText
        style={{ color: isSelected ? Colors.text : Colors.gray }}
        className={Styles.AdminPageTabTitle}
      >
        {title}
      </IFText>
      <div
        className={Styles.CountDiv}
        style={{
          backgroundColor: isSelected ? Colors.primaryLight : Colors.grey,
        }}
      >
        <IFText
          style={{
            color: isSelected ? Colors.text : Colors.gray,
            fontSize: 12,
          }}
        >
          {count}
        </IFText>
      </div>
    </div>
  )
}
AdminPageTab.propTypes = {
  title: PropTypes.string,
  count: PropTypes.string,
  isSelected: PropTypes.bool,
}
export default AdminPageTab
