import React from 'react'
import styles from './AdminListItemSkeleton.module.css'
import { IFSkeleton } from 'Components'

const AdminListItemSkeleton = () => {
  return (
    <div className={styles.AdminItemDiv}>
      <IFSkeleton variant="circular" width="42px" height="42px" />
      <div className={styles.AdminItemDetails}>
        <IFSkeleton variant="text" width="30%" />
        <IFSkeleton variant="text" width="40%" />
      </div>
    </div>
  )
}

export default AdminListItemSkeleton
