import { put, call } from 'redux-saga/effects'
import { adminsApiService, handleError } from 'Services/ApiService.js'
import AdminActions from 'Stores/Admin/Actions'
import base64 from 'base-64'

export function* fetchAdminList({ filter, offset, shouldShowError }) {
  yield put(AdminActions.fetchAdminListLoading())

  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(adminsApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    yield put(
      AdminActions.fetchAdminListSuccess(offset, data.admins, data.nextOffset),
    )
  } catch (e) {
    yield put(AdminActions.fetchAdminListError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* fetchAdminsCount({ filter }) {
  yield put(AdminActions.fetchAdminsCountLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(adminsApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(AdminActions.fetchAdminsCountSuccess(data.count))
  } catch (e) {
    yield put(AdminActions.fetchAdminsCountFail(e))
    handleError(e)
  }
}

export function* updateAdminRole({ adminId, newRoleValue }) {
  yield put(AdminActions.updateAdminRoleLoading(adminId))

  try {
    const { data } = yield call(adminsApiService.patch, `/${adminId}`, {
      newRoleId: newRoleValue,
    })

    yield put(AdminActions.updateAdminRoleSuccess(data))
  } catch (e) {
    yield put(AdminActions.updateAdminRoleFail(adminId))

    handleError(e)
  }
}

export function* deleteAdmin({ adminId }) {
  yield put(AdminActions.deleteAdminLoading(adminId))

  try {
    yield call(adminsApiService.delete, `/${adminId}`)
    yield put(AdminActions.deleteAdminSuccess(adminId))
  } catch (e) {
    yield put(AdminActions.deleteAdminFail(adminId))
    handleError(e)
  }
}

export function* addAdmin({ admin }) {
  yield put(AdminActions.addAdminLoading(true))
  try {
    const { data } = yield call(adminsApiService.post, '/', {
      name: admin.name,
      email: admin.email,
      role: admin.role,
      password: admin.password,
    })

    yield put(AdminActions.addAdminSuccess(data))
  } catch (e) {
    yield put(AdminActions.addAdminFail())
    handleError(e)
  }
}
