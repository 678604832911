import styled from 'styled-components'
import Colors from 'Theme/Colors'
import styles from './IFFilter.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {
  IFText,
  IFButton,
  IFSelectPicker,
  IFCheckPicker,
  IFDateRangePicker,
  IFsvg,
  IFLoadingIndicator,
  IFRangePicker,
  IFDatePicker,
} from 'Components'
import Chip from '@mui/material/Chip'
import React, {
  createRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Close from '@mui/icons-material/Close'
import { Accordion, AccordionDetails } from '@mui/material'
import IFFilterType from 'Enums/IFFilterType'
import PropTypes from 'prop-types'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import TransactionsDownload from 'Components/TransactionsDownload/TransactionsDownload'
import { styled as muiStyled } from '@mui/system'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import IFFilterSkeleton from './IFFilterSkeleton'

const TextContainer = styled.div`
  color: ${Colors.FilterShadow};
`
const InputContainer = styled.div`
  background-color: ${Colors.filterBackground};
`
const TextField = styled.input`
  border: 0em;
  &:focus ${this} {
    outline: none;
  }
`
const StyledTooltip = muiStyled(Tooltip)(({ theme }) => ({
  backgroundColor: `${Colors.primary}`,
  color: `${Colors.white}`,
  fontSize: '0.875rem',
  fontFamily: 'ProximaNova',
  padding: '0.3rem',
  marginTop: '0.5rem',
}))

const AccordionSummary = muiStyled(MuiAccordionSummary)(({ theme }) => ({
  borderRadius: '5px',
  padding: '0em',
  height: 48,
  backgroundColor: Colors.white + '!important',
  '&.Mui-expanded': {
    minHeight: 48,
  },
  '& .MuiAccordionSummary-content': {
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
}))

const StyledChip = muiStyled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: Colors.filterBackground,
  '&:hover': {
    backgroundColor: Colors.primary,
    color: 'white',
  },
  fontSize: '0.9rem',
}))

const StyledAccordionDetails = muiStyled(AccordionDetails)({
  padding: '0em',
  backgroundColor: Colors.filterBackground,
})

const StyledAccordion = muiStyled(Accordion)(({ theme }) => ({
  boxShadow: `0px 0px 0px 0px ${Colors.ChargingTransactionListItemShadow}`,
  border: `1px solid ${Colors.UserPageBackgroundContainer}`,
}))

const IFFilter = React.forwardRef(
  (
    {
      onFilterChange = () => {},
      filters,
      textFieldPlaceholder,
      downloadTransactions,
      isLoading,
      disableKeyword = false,
      fixedFilters = false,
      showLoadingIndicator = false,
      canSort = false,
      ascending = false,
      onSortChange = () => {},
      disableFutureDates = false,
    },
    ref,
  ) => {
    const filterRef = useRef(null)
    const IFCheckPickerRef = useRef(null)
    const { t } = useTranslation()
    const [filterText, setFilterText] = React.useState('')
    const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false)
    const [chipFilters, setChipFilters] = React.useState(() => {
      let result = []
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].value &&
          !(filters[i].value === '' || filters[i].value.length === 0)
        ) {
          if (filters[i].type === IFFilterType.KEYWORD) {
            for (let j = 0; j < filters[i].value.length; j++) {
              result.push({
                ...filters[i],
                value: filters[i].value[j],
                id: i,
              })
            }
          } else {
            result.push({
              ...filters[i],
              value: filters[i].value,
              id: i,
            })
          }
        }
      }
      return result
    })

    const [currentFilters, setCurrentFilters] = React.useState(() => {
      let result = []
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].type === IFFilterType.CHECK ||
          filters[i].type === IFFilterType.SELECT ||
          filters[i].type === IFFilterType.DATE_RANGE ||
          filters[i].type === IFFilterType.KEYWORD ||
          filters[i].type === IFFilterType.VALUE_RANGE ||
          filters[i].type === IFFilterType.VALUE_RANGE_DECIMAL
        ) {
          result.push({ ...filters[i], value: [], ref: createRef() })
        } else {
          result.push({ ...filters[i], value: '', ref: createRef() })
        }
      }
      return result
    })
    useEffect(() => {
      setChipFilters(() => {
        let result = []
        for (let i = 0; i < filters.length; i++) {
          if (
            filters[i].value &&
            !(filters[i].value === '' || filters[i].value.length === 0)
          ) {
            if (filters[i].type === IFFilterType.KEYWORD) {
              for (let j = 0; j < filters[i].value.length; j++) {
                result.push({
                  ...filters[i],
                  value: filters[i].value[j],
                  id: i,
                })
              }
            } else {
              result.push({
                ...filters[i],
                value: filters[i].value,
                id: i,
              })
            }
          }
        }
        return result
      })
      setCurrentFilters(() => {
        let result = []
        for (let i = 0; i < filters.length; i++) {
          if (
            filters[i].type === IFFilterType.CHECK ||
            filters[i].type === IFFilterType.SELECT ||
            filters[i].type === IFFilterType.DATE_RANGE ||
            filters[i].type === IFFilterType.KEYWORD ||
            filters[i].type === IFFilterType.VALUE_RANGE ||
            filters[i].type === IFFilterType.VALUE_RANGE_DECIMAL ||
            filters[i].type === IFFilterType.DATE
          ) {
            result.push({
              ...filters[i],
              value: filters[i].value,
              ref: createRef(),
            })
          } else {
            result.push({ ...filters[i], value: '', ref: createRef() })
          }
        }
        return result
      })
    }, [filters])

    useImperativeHandle(ref, () => ({
      setIsAccordionExpanded,
      clearFilter: () => {
        for (let i = 0; i < currentFilters.length; i++) {
          if (
            currentFilters[i].type === IFFilterType.CHECK ||
            currentFilters[i].type === IFFilterType.DATE_RANGE ||
            currentFilters[i].type === IFFilterType.SELECT ||
            currentFilters[i].type === IFFilterType.VALUE_RANGE ||
            currentFilters[i].type === IFFilterType.DATE
          ) {
            currentFilters[i]?.ref?.current?.clearValue()
          }
        }
        clearValues()
        setFilterText('')
      },
    }))

    React.useEffect(() => {
      function handleClickOutside(event) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          for (let i = 0; i < currentFilters.length; i++) {
            if (
              currentFilters[i].ref.current &&
              currentFilters[i].ref.current.isOpen
            ) {
              return
            }
          }
          setIsAccordionExpanded(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [filterRef, currentFilters])

    const clearValues = () => {
      var newFilters = [...currentFilters]
      for (let i = 0; i < newFilters.length; i++) {
        if (
          newFilters[i].type === IFFilterType.SELECT &&
          !newFilters[i].canClear
        ) {
          continue
        } else {
          newFilters[i].type === IFFilterType.CHECK ||
          newFilters[i].type === IFFilterType.DATE_RANGE ||
          newFilters[i].type === IFFilterType.KEYWORD ||
          newFilters[i].type === IFFilterType.VALUE_RANGE ||
          newFilters[i].type === IFFilterType.VALUE_RANGE_DECIMAL
            ? (newFilters[i].value = [])
            : (newFilters[i].value = '')
        }
      }

      setChipFilters([])
      setCurrentFilters(newFilters)
      onFilterChange(newFilters)
    }

    const deleteKeyword = (chipIndex) => {
      var newFilters = [...currentFilters]
      for (let i = 0; i < newFilters[0].value.length; i++) {
        if (newFilters[0].value[i] === chipFilters[chipIndex].value) {
          newFilters[0].value.splice(i, 1)
          break
        }
      }
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
    }

    const clearSelect = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = ''
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
      if (currentFilters[chipFilters[chipIndex].id].ref.current)
        currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }
    const deleteDateCheckOrRangeItem = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = []
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
      currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }

    const deleteDateOrCheckItem = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = []
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)

      currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }

    const addKeywordFilter = () => {
      var newFilters = [...currentFilters]
      newFilters[0].value.push(filterText.trim())
      setCurrentFilters(newFilters)
      setChipFilters([
        ...chipFilters,
        { type: IFFilterType.KEYWORD, value: filterText },
      ])
      setFilterText('')
      onFilterChange(newFilters)
    }

    const changeFilterValue = (value, index) => {
      if (value.length === 0) {
        for (let i = 0; i < chipFilters.length; i++) {
          if (chipFilters[i].id === index) {
            let newChips = [...chipFilters]
            newChips.splice(i, 1)
            setChipFilters(newChips)
            break
          }
        }
      }
      let found = false
      var newFilters = [...currentFilters]
      newFilters[index].value = value
      for (let i = 0; i < chipFilters.length; i++) {
        if (chipFilters[i].id === index) {
          chipFilters[i] = { ...newFilters[index], id: index }
          found = true
          break
        }
      }
      if (!found) {
        setChipFilters([...chipFilters, { ...newFilters[index], id: index }])
      }
      setCurrentFilters([...newFilters])
      onFilterChange(newFilters)
    }

    return (
      <Fragment>
        {isLoading ? (
          <IFFilterSkeleton />
        ) : (
          <div ref={filterRef}>
            <StyledAccordion expanded={isAccordionExpanded}>
              <AccordionSummary>
                <TextContainer
                  className={styles.TextContainer}
                  onClick={() => {
                    setIsAccordionExpanded(true)
                  }}
                >
                  {canSort ? (
                    <StyledTooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 60 }}
                      title={
                        ascending
                          ? t('IFFilter.Ascending')
                          : t('IFFilter.Descending')
                      }
                      placement="bottom"
                    >
                      <div
                        className={styles.SortContainer}
                        onClick={(e) => {
                          e.stopPropagation()
                          onSortChange()
                        }}
                      >
                        {ascending ? (
                          <IFsvg.ArrowUpSort
                            height={18}
                            width={18}
                            fill={
                              chipFilters.length === 0
                                ? Colors.filterIconEmpty
                                : Colors.filterIconFilled
                            }
                          />
                        ) : (
                          <IFsvg.ArrowDownSort
                            height={18}
                            width={18}
                            fill={
                              chipFilters.length === 0
                                ? Colors.filterIconEmpty
                                : Colors.filterIconFilled
                            }
                          />
                        )}
                      </div>
                    </StyledTooltip>
                  ) : (
                    <FontAwesomeIcon
                      className={styles.FilterIcon}
                      style={
                        chipFilters.length === 0
                          ? { color: Colors.filterIconEmpty }
                          : { color: Colors.filterIconFilled }
                      }
                      icon={faFilter}
                    />
                  )}
                  <div className={styles.TextInputWrapper}>
                    {chipFilters.map((filter, index) => {
                      if (filter.type === IFFilterType.KEYWORD) {
                        return (
                          <StyledChip
                            key={`chip ${index}`}
                            deleteIcon={
                              !fixedFilters ? (
                                <Close className={styles.CloseIcon} />
                              ) : (
                                <></>
                              )
                            }
                            label={
                              <IFText className={styles.LabelText}>
                                Keyword | {filter.value}
                              </IFText>
                            }
                            size="small"
                            onDelete={() => deleteKeyword(index)}
                          />
                        )
                      } else if (
                        filter.type === IFFilterType.SELECT ||
                        filter.type === IFFilterType.SLIDER
                      ) {
                        return (
                          <StyledChip
                            deleteIcon={
                              !fixedFilters && filter.canClear ? (
                                <Close className={styles.CloseIcon} />
                              ) : (
                                <></>
                              )
                            }
                            size="small"
                            label={
                              <IFText className={styles.LabelText}>
                                {filter.title} | {filter.value}
                              </IFText>
                            }
                            onDelete={() => clearSelect(index)}
                          />
                        )
                      } else if (filter.type === IFFilterType.CHECK) {
                        return (
                          <StyledChip
                            deleteIcon={
                              !fixedFilters ? (
                                <Close className={styles.CloseIcon} />
                              ) : (
                                <></>
                              )
                            }
                            size="small"
                            label={
                              <IFText className={styles.LabelText}>
                                {filter.title} |{' '}
                                {filter.value.map((value, index) =>
                                  index === 0
                                    ? value
                                    : filter.value.length === index + 1
                                    ? t('IFFilter.or') + value
                                    : ', ' + value,
                                )}
                              </IFText>
                            }
                            onDelete={() => deleteDateOrCheckItem(index)}
                          />
                        )
                      } else if (filter.type === IFFilterType.DATE_RANGE) {
                        return (
                          <StyledChip
                            deleteIcon={
                              !fixedFilters ? (
                                <Close className={styles.CloseIcon} />
                              ) : (
                                <></>
                              )
                            }
                            label={
                              <IFText className={styles.LabelText}>
                                {filter.title} |{' '}
                                {moment(filter.value[0]).format('YYYY-MM-DD')}{' '}
                                to{' '}
                                {moment(filter.value[1]).format('YYYY-MM-DD')}
                              </IFText>
                            }
                            size="small"
                            onDelete={() => deleteDateOrCheckItem(index)}
                          />
                        )
                      } else if (
                        filter.type === IFFilterType.VALUE_RANGE ||
                        filter.type === IFFilterType.VALUE_RANGE_DECIMAL
                      ) {
                        return (
                          <StyledChip
                            deleteIcon={
                              !fixedFilters ? (
                                <Close className={styles.CloseIcon} />
                              ) : (
                                <></>
                              )
                            }
                            label={
                              <IFText className={styles.LabelText}>
                                {filter.title} |{' '}
                                {filter.value[0] === filter.value[1]
                                  ? `Exactly ${filter.value[0]}`
                                  : filter.value[0] === -1
                                  ? `Less than ${Number(filter.value[1])}`
                                  : filter.value[1] === -1
                                  ? `More than ${Number(filter.value[0])}`
                                  : `Between ${filter.value[0]} to ${filter.value[1]}`}
                              </IFText>
                            }
                            size="small"
                            onDelete={() => deleteDateCheckOrRangeItem(index)}
                          />
                        )
                      } else if (filter.type === IFFilterType.DATE) {
                        return (
                          <StyledChip
                            deleteIcon={
                              !fixedFilters ? (
                                <Close className={styles.CloseIcon} />
                              ) : (
                                <></>
                              )
                            }
                            label={
                              <IFText className={styles.LabelText}>
                                {filter.title} |{' '}
                                {moment(filter.value).format('YYYY-MM-DD')}
                              </IFText>
                            }
                            size="small"
                            onDelete={() => deleteDateCheckOrRangeItem(index)}
                          />
                        )
                      }
                      return null
                    })}
                    {!disableKeyword ? (
                      <TextField
                        type="search"
                        placeholder={textFieldPlaceholder}
                        value={filterText}
                        onKeyPress={(event) => {
                          if (
                            event.key === 'Enter' ||
                            event.key === 'NumpadEnter'
                          ) {
                            if (filterText.trim()) {
                              addKeywordFilter()
                            }
                          }
                        }}
                        onClick={() => {
                          setIsAccordionExpanded(!isAccordionExpanded)
                        }}
                        className={styles.TextField}
                        onChange={(event) => setFilterText(event.target.value)}
                      />
                    ) : (
                      <IFText className={styles.FilterPlaceHolderText}>
                        {textFieldPlaceholder}
                      </IFText>
                    )}
                  </div>
                  {isAccordionExpanded ? (
                    !fixedFilters ? (
                      <>
                        <IFButton
                          text={t('IFFilter.clearButton')}
                          color={Colors.FilterClearButton}
                          size="sm"
                          onClick={() => {
                            for (let i = 0; i < currentFilters.length; i++) {
                              if (
                                currentFilters[i].type === IFFilterType.CHECK ||
                                currentFilters[i].type ===
                                  IFFilterType.DATE_RANGE ||
                                currentFilters[i].type ===
                                  IFFilterType.SELECT ||
                                currentFilters[i].type ===
                                  IFFilterType.VALUE_RANGE
                              ) {
                                currentFilters[i].ref.current.clearValue()
                                clearValues()
                              }
                            }

                            setFilterText('')
                          }}
                          className={styles.Button}
                        />
                      </>
                    ) : null
                  ) : null}
                  {showLoadingIndicator ? (
                    <IFLoadingIndicator
                      size={'24px'}
                      className={styles.LoadingIndicator}
                      secondaryColor={Colors.white}
                    />
                  ) : null}
                </TextContainer>
                {downloadTransactions && (
                  <div
                    className={styles.download}
                    style={
                      isAccordionExpanded
                        ? {
                            top: '56px',
                          }
                        : {
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }
                    }
                  >
                    <TransactionsDownload />
                  </div>
                )}
              </AccordionSummary>
              <StyledAccordionDetails>
                <InputContainer
                  className={styles.InputContainer}
                  FilterBackground={Colors.filterBackground}
                >
                  {currentFilters.map((filter, index) => {
                    if (filter.type === IFFilterType.SELECT) {
                      return (
                        <IFSelectPicker
                          key={`IfSelectPicker ${index}`}
                          ref={filter.ref}
                          title={filter.title}
                          data={filter.data}
                          onValueChange={(value) => {
                            changeFilterValue(value, index)
                          }}
                          initialValue={filter.value}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.CHECK) {
                      return (
                        <IFCheckPicker
                          key={`IFCheckPicker ${index}`}
                          ref={filter.ref}
                          filterRef={IFCheckPickerRef}
                          title={filter.title}
                          data={filter.data}
                          onValueChange={(value) => {
                            changeFilterValue(value, index)
                          }}
                          initialValue={filter.value}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.DATE_RANGE) {
                      return (
                        <IFDateRangePicker
                          key={`IFDateRangePicker ${index}`}
                          ref={filter.ref}
                          title={filter.title}
                          onDateRangeSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                          initialValue={filter.value}
                          ranges={filter.ranges}
                          disableFutureDates={disableFutureDates}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.VALUE_RANGE) {
                      return (
                        <IFRangePicker
                          key={`IFDateRangePicker valueRange ${index}`}
                          min={0}
                          isDecimal={false}
                          initialValue={filter.value}
                          ref={filter.ref}
                          unit={filter.unit}
                          title={filter.title}
                          onRangeSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.VALUE_RANGE_DECIMAL) {
                      return (
                        <IFRangePicker
                          key={`IFDateRangePicker valueRangeDecimal ${index}`}
                          min={0}
                          isDecimal={true}
                          initialValue={filter.value}
                          unit={filter.unit}
                          ref={filter.ref}
                          title={filter.title}
                          field={filter.field}
                          onRangeSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.SLIDER) {
                      return (
                        <IFSliderPicker
                          minSliderValue={filter.data[0]}
                          maxSliderValue={filter.data[1]}
                          initialValue={filter.value}
                          ref={filter.ref}
                          title={filter.title}
                          onValueSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.DATE) {
                      return (
                        <IFDatePicker
                          key={`IFDatePicker ${index}`}
                          ref={filter.ref}
                          title={filter.title}
                          onDateSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                          initialValue={filter.value}
                        />
                      )
                    }
                    return null
                  })}
                </InputContainer>
              </StyledAccordionDetails>
            </StyledAccordion>
          </div>
        )}
      </Fragment>
    )
  },
)
IFFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func,
  textFieldPlaceholder: PropTypes.string,
  downloadTransactions: PropTypes.bool,
  disableKeyword: PropTypes.bool,
  fixedFilters: PropTypes.bool,
  showLoadingIndicator: PropTypes.bool,
}

export default IFFilter
