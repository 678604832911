import React from 'react'
import styles from './UserListItemSkeleton.module.css'
import { IFSkeleton } from 'Components'

const UserListItemSkeleton = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Avatar}>
        <IFSkeleton variant="circular" width="42px" height="42px" />
      </div>
      <div className={styles.UserDetailsContainer}>
        <IFSkeleton variant="text" width="80%" />
        <IFSkeleton variant="text" width="60%" />
      </div>
      <div className={styles.LastSeenItem}>
        <IFSkeleton variant="text" width="100%" />
      </div>
    </div>
  )
}

export default UserListItemSkeleton
