import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchTransactionsList: ['filter', 'offset', 'shouldShowError'],
  fetchTransactionsListLoading: null,
  fetchTransactionsListSuccess: [
    'offset',
    'transactions',
    'nextOffset',
    'filter',
  ],
  fetchTransactionsListError: ['error'],
  setTransactionsFilter: ['newFilter'],
  clearTransactions: null,

  fetchTransactionsMetrics: ['filter', 'offset'],
  fetchTransactionsMetricsLoading: null,
  fetchTransactionsMetricsSuccess: ['metrics'],
  fetchTransactionsMetricsError: ['error'],

  fetchEnergyConsumptionChart: ['filter', 'offset'],
  fetchEnergyConsumptionChartLoading: null,
  fetchEnergyConsumptionChartSuccess: ['energyConsumption'],
  fetchEnergyConsumptionChartError: ['error'],

  setDateInitialValue: ['admin', 'filterParam'],
  clearTransactionsFilter: null,
})

export const TransactionTypes = Types
export default Creators
