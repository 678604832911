const getUserList = (state) => {
  return state.main.userState.users
}

const getFilteredUserList = (state) => {
  //TODO Apply filter here then return the result
  return state.main.userState.users
}

const getUserListOffset = (state) => {
  return state.main.userState.paginationOffset
}

const getFetchUserListRequestState = (state) => {
  return state.main.userState.fetchUsersListRequestState
}

const getCurrentUser = (state) => {
  return state.main.userState.currentUser
}

const getUserDetailsRequestState = (state) => {
  return state.main.userState.fetchUserDetailsRequestState
}

const getCurrentUserChargingTokens = (state) => {
  return state.main.userState.currentUser.chargingTokens
}
const getCurrentUserPaymentCards = (state) => {
  return state.main.userState.currentUser.paymentCards
}
const getAddCurrentCardRequestState = (state) => {
  return state.main.userState.addCurrentCardRequestState
}

const getUserSortingAsc = (state) => {
  return state.main.userState.userSortingAsc
}

const getUsersCount = (state) => {
  return state.main.userState.usersCount
}
const getFetchUserListCountRequestState = (state) => {
  return state.main.userState.fetchUserListCountRequestState
}

const getSelectedUserIndex = (state) => {
  return state.main.userState.selectedUserIndex
}

const getTransactionList = (state) => {
  return state.main.userState.currentUser.transactions
}

const getUsersFilters = (state) => {
  return state.main.userState.usersFilters
}
const getDefaultUsersFilters = (state) => {
  return state.main.userState.defaultUserFilters
}

const getTransactionsFilters = (state) => {
  return state.main.userState.transactionsFilters
}
const getDefaultTransactionsFilters = (state) => {
  return state.main.userState.defaultTransactionFilters
}
const getDefaultPaymentsFilters = (state) => {
  return state.main.userState.defaultPaymentsFilters
}
const getDefaultUserFiltersCountry = (state) => {
  return state.main.userState.defaultUserFiltersCountry
}

const getTransactionListPaginationOffset = (state) => {
  return state.main.userState.transactionListPaginationOffset
}

const getFetchUserTransactionListRequestState = (state) => {
  return state.main.userState.fetchUserTransactionListRequestState
}

const getPaymentSessionList = (state) => {
  return state.main.userState.currentUser.paymentSessions
}

const getPaymentsFilters = (state) => {
  return state.main.userState.paymentsFilters
}

const getFetchUserPaymentSessionListRequestState = (state) => {
  return state.main.userState.fetchUserPaymentSessionListRequestState
}

const getPaymentSessionListPaginationOffset = (state) => {
  return state.main.userState.paymentSessionListPaginationOffset
}

const getSelectedUserId = (state) => {
  return state.main.userState.users[state.main.userState.selectedUserIndex]
    ? state.main.userState.users[state.main.userState.selectedUserIndex].id
    : null
}

const getReverseChargeTransactionRequestState = (state) => {
  return state.main.userState.reverseChargeTransactionRequestState
}

const getPointsList = (state) => {
  return state.main.userState.points
}

const getPointsListPaginationOffset = (state) => {
  return state.main.userState.pointsListPaginationOffset
}

const getFetchUserPointsRequestState = (state) => {
  return state.main.userState.fetchUserPointsListRequestState
}

const UserSelectors = {
  getUserList,
  getFilteredUserList,
  getUserListOffset,
  getFetchUserListRequestState,
  getCurrentUser,
  getCurrentUserChargingTokens,
  getUserSortingAsc,
  getUsersCount,
  getFetchUserListCountRequestState,
  getSelectedUserIndex,
  getTransactionList,
  getUsersFilters,
  getTransactionsFilters,
  getPaymentSessionList,
  getPaymentsFilters,
  getFetchUserPaymentSessionListRequestState,
  getPaymentSessionListPaginationOffset,
  getUserDetailsRequestState,
  getTransactionListPaginationOffset,
  getFetchUserTransactionListRequestState,
  getSelectedUserId,
  getReverseChargeTransactionRequestState,
  getPointsList,
  getPointsListPaginationOffset,
  getFetchUserPointsRequestState,
  getDefaultUsersFilters,
  getAddCurrentCardRequestState,
  getDefaultTransactionsFilters,
  getDefaultPaymentsFilters,
  getDefaultUserFiltersCountry,
  getCurrentUserPaymentCards,
}

export default UserSelectors
