const getVouchersList = (state) => {
  return state.main.voucherState.vouchers
}

const getFetchVouchersListRequestState = (state) => {
  return state.main.voucherState.fetchVouchersListRequestState
}

const getPaginationOffset = (state) => {
  return state.main.voucherState.paginationOffset
}

const getVouchersFilter = (state) => {
  return state.main.voucherState.vouchersFilter
}

const getIsAddVoucherLoading = (state) => {
  return state.main.voucherState.isAddVoucherLoading
}

const getUpdateVoucherRequestState = (state) => {
  return state.main.voucherState.updateVoucherRequestState
}
const getVouchersCount = (state) => {
  return state.main.voucherState.vouchersCount
}
const getVouchersCountRequestState = (state) => {
  return state.main.voucherState.fetchVouchersCountRequestState
}
const getDefaultVouchersFilters = (state) => {
  return state.main.voucherState.defaultVouchersFilters
}

const VoucherSelectors = {
  getVouchersList,
  getFetchVouchersListRequestState,
  getPaginationOffset,
  getVouchersFilter,
  getIsAddVoucherLoading,
  getUpdateVoucherRequestState,
  getVouchersCount,
  getVouchersCountRequestState,
  getDefaultVouchersFilters,
}

export default VoucherSelectors
