import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  loginLoading: false,
  token: null,
  refreshToken: null,
  user: null,
  editingAdminRole: [],
  fetchEditingAdminRoleRequestState: RequestState.UNINITIALIZED,
  fetchAdminDetailsRequestState: RequestState.UNINITIALIZED,
  ChangingPasswordRequestState: RequestState.UNINITIALIZED,
  ChangingUserNameRequestState: RequestState.UNINITIALIZED,
}
